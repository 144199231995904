import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import AdminWrapper from '../../adminComponents/adminWrapper/AdminWrapper';
import axios from 'axios';
import DashNav from '../../../usersDashboard/userComponents/dashNav/DashNav';
import { Spinner } from 'react-bootstrap';
import '../servicerequests/servicerequest.css';
import { message } from 'antd';
import { Link } from 'react-router-dom';

function Loan() {
	const [data, setData] = useState([]);

	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState('');
	const userData = JSON.parse(localStorage.getItem('userData'));
	const app_token =
		'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';

	// Function To Fetch Loan Requests
	const fetchLoans = () => {
		setLoading(true);
		axios
			.post(
				'https://test.solarcredit.io/v0.1/api/admin/getAllLoan',
				{
					apptoken: app_token,
					usertoken: userData.usertoken,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			)
			.then((res) => {
				setLoading(false);
				if (res.data.success) {
					setData(res.data.data);
				} else {
					message.info(res.data.message);
				}
			})
			.catch((err) => {
				setLoading(false);
				message.error(err.message);
			});
	};

	// useEffect to fetch loans
	useEffect(() => {
		fetchLoans();
	}, []);

	// Column For The Table
	const columns = [
		{
			field: 'fullname',
			headerName: 'Full Name',
			width: 200,
			renderCell: (params) => {
				return <div className='userListUser'>{params.row.fullname}</div>;
			},
		},
		{
			field: 'amountToBorrow',
			headerName: 'Amount to Borrow',
			width: 200,
			renderCell: (params) => {
				return <div className='userListUser'>{params.row.amountToBorrow}</div>;
			},
		},
		{
			field: 'isCompletedStatus',
			headerName: 'Loan Status',
			width: 250,
			renderCell: (params) => {
				return (
					<div className='userListUser'>{params.row.isCompletedStatus}</div>
				);
			},
		},
		{
			field: 'action',
			headerName: 'Action',
			width: 250,
			renderCell: (params) => {
				return (
					<>
						<Link
							to={'/admin/loans/' + params.row.loantoken}
							state={params.row}>
							<button className='adminTableBtn'>View</button>
						</Link>
					</>
				);
			},
		},
	];

	return (
		<div>
			<AdminWrapper>
				<DashNav
					title={'Loan Requests'}
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					placeholder='Search Loan Token'
				/>
				<div className='adminuser-case'>
					{loading ? (
						<Spinner
							animation='border'
							role='status'
							size='lg'
							className='store-spinner'>
							<span className='visually-hidden'>Loading...</span>
						</Spinner>
					) : (
						<DataGrid
							rows={data.filter(
								(item) =>
									item.loantoken && item.loantoken.toString().includes(search)
							)}
							columns={columns}
							disableSelectionOnClick
							pageSize={8}
							rowsPerPageOptions={[5]}
							checkboxSelection
							getRowId={(r) => r.loantoken}
						/>
					)}
				</div>
			</AdminWrapper>
		</div>
	);
}

export default Loan;
