import ReactQuill from 'react-quill';
import EditorToolbar, { modules, formats } from './EditorToolbar';
import 'react-quill/dist/quill.snow.css';
import './TextEditor.css';
import TextEditorStyle from './TextEditorStyle';

const TextEditor = ({ message, setMessage }) => {
	return (
		<TextEditorStyle>
			<EditorToolbar toolbarId={'t1'} />
			<ReactQuill
				theme='snow'
				placeholder={'Type something ...'}
				value={message}
				onChange={(value) => setMessage(value)}
				modules={modules('t1')}
				formats={formats}
			/>
		</TextEditorStyle>
	);
};
export default TextEditor;
