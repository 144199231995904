import React, { useState, useEffect } from 'react'
import DashNav from '../../userComponents/dashNav/DashNav'
import Wrapper from '../../userComponents/userWrapper/Wrapper'
import axios from 'axios'
import { message, Modal } from 'antd'
import './notification.css'
function Notification() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [modalData, setModalData] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false)
    const URL = process.env.REACT_APP_BASE_URL
    const APP_TOKEN = 'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh'

    //Function to get all notifications
    const getNotifications = async () => {
        try {
            const res = await axios.post(`${URL}/fetchNotification`, { apptoken: APP_TOKEN, usertoken: userData.usertoken }, {
                headers: { Authorization: `Bearer 6455ef91d108f` }
            })
            setLoading(false)
            if (res.data.success) {
                setData(res.data.data)
            } else {
                message.info(res.data.message)
            }
        } catch (error) {
            setLoading(false)
            message.error(error.message)
        }
    }
    const viewNotification = async (token) => {
        try {
            const res = await axios.post(`${URL}/viewNotification`, { apptoken: APP_TOKEN, usertoken: userData.usertoken, notifyToken: token }, {
                headers: { Authorization: `Bearer 6455ef91d108f` }
            })
            getNotifications()
        } catch (error) {
            message.error(error.message)
        }
    }


    const handleShow = (item) => {
        setModalData(item)
        setIsModalOpen(true)
        viewNotification(item.notifyToken)
    }
    useEffect(() => {
        getNotifications()
    }, [])


    return (
        <div>
            <Wrapper>
                <DashNav title='Notifications' />
                <div className="notification-wrapper d-flex flex-column align-items-center justify-content-center">
                    {loading ?
                        <h5>Loading...</h5>
                        :
                        <>
                            {data?.map((item) => {
                                return <div className="notification-item" key={item.notifyToken} onClick={() => handleShow(item)}>
                                    <h2 className={item.status === true ? 'inactive' : ''}>{item.abtMessage}</h2>
                                    <p>{item.date}</p>
                                </div>
                            })}
                        </>}
                </div>

                <Modal open={isModalOpen} onOk={() => setIsModalOpen(false)} footer={null} onCancel={() => setIsModalOpen(false)}>
                    <h4>{modalData?.abtMessage}</h4>
                    <p>{modalData?.date}</p>
                </Modal>
            </Wrapper>
        </div>
    )
}

export default Notification