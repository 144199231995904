import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../images/logo.png";
import { message } from "antd";
import axios from "axios";
function ForogtPassword() {
  const [data, setData] = useState(null);
  const [button, setButton] = useState(false);
  const APP_TOKEN =
    "vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh";

  //Function to handle data change
  const handleChange = (e) => {
    const value = e.target.value;
    setData({ ...data, [e.target.name]: value });
  };

  //Function to send reset mail
  const sendResetMail = async (e) => {
    e.preventDefault();
    setButton(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/forgetPword`,
        { ...data, apptoken: APP_TOKEN },
        {
          headers: { Authorization: `Bearer 6455ef91d108f` },
        }
      );
      setButton(false);
      if (res.data.success) {
        message.success(res.data.message);
      } else {
        message.warning(res.data.message);
      }
    } catch (error) {
      message.error(error.message);
      setButton(false);
    }
  };

  return (
    <>
      <div className="login">
        <div className="login-inner">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
          <div className="login-form">
            <h4>Forgot Your Password?</h4>
            <p className="text-white">
              Send a link to your email to reset your password
            </p>
            <form onSubmit={!button && sendResetMail}>
              <div className="login-form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  onChange={handleChange}
                  name="mail"
                  placeholder="Email"
                  required
                />
              </div>

              <button type="submit">{button ? "Loading" : "Send Link"}</button>
              <div className="login-form-group">
                <span className="w-100 d-flex align-items-center justify-content-end">
                  {" "}
                  <Link style={{ color: "black" }} to="/login">
                    Back to Login
                  </Link>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForogtPassword;
