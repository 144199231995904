import React from 'react';
import { useState, useEffect } from 'react';
import { Modal, Result, Button, message } from 'antd';
import DashNav from '../../userComponents/dashNav/DashNav';
import Wrapper from '../../userComponents/userWrapper/Wrapper';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './loan.css';
function Loan() {
	//
	const userData = JSON.parse(localStorage.getItem('userData'));
	const app_token =
		'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';
	const navigate = useNavigate();
	// state for image handling
	const [img, setImg] = useState(null);
	const [imgSrc, setImgSrc] = useState(null);
	const [recipt, setRecipt] = useState(null);
	const [imgLoader, setImgLoader] = useState(false);
	//state for diffrent data
	const [loanData, setLoanData] = useState(null);
	const [nokData, setNokData] = useState(null);
	const [collectData, setCollectData] = useState(null);
	const [gurantor1, setGurantor1] = useState(null);
	const [gurantor2, setGurantor2] = useState(null);
	//state to toggle next of kin form diaplay
	const [nextKin, setNextKin] = useState(userData?.nextOfKin);
	// state to toggle between forms
	const [collect, setCollect] = useState(1);
	const [categories, setCategories] = useState([]);
	//state to handle loan plans
	const [plan, setPlan] = useState('');
	const [providerid, setProviderid] = useState('');
	const [planData, setPlanData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loanProviderData, setLoanProviderData] = useState(null);

	// KYC Modal Display State
	const [kycModal, setKycModal] = useState(
		userData?.kycStatus === false ? true : false
	);

	const [isModalOpen1, setIsModalOpen1] = useState(false);
	// Function to fetch all plans
	const fetchPlan = () => {
		axios
			.post(
				`${process.env.REACT_APP_BASE_URL}/admin/fetchPlan`,
				{
					apptoken: app_token,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			)
			.then((res) => {
				if (res.data.success) {
					setPlanData(res.data.data);
				} else {
					message.info(res.data.message);
				}
			})
			.catch((err) => {
				fetchPlan();
				setPlanData(planData);
			});
	};

	// loan provider
	useEffect(() => {
		const fetchLoanProviderData = async () => {
			try {
				const response = await axios.post(
					`${process.env.REACT_APP_BASE_URL}/admin/getLoanProvider`,
					{
						apptoken: app_token,
					},
					{
						headers: { Authorization: `Bearer 6455ef91d108f` },
					}
				);

				if (response.data.success) {
					setLoanProviderData(response.data.data);
				} else {
					message.info(response.data.message);
				}
			} catch (error) {
				message.error(error.message);
			}
		};

		fetchLoanProviderData();
	}, []);

	console.log(loanProviderData);

	// Function to fetch all categories
	const fetchCategories = () => {
		axios
			.post(
				`${process.env.REACT_APP_BASE_URL}/admin/fetchcategory`,
				{
					apptoken: app_token,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			)
			.then((res) => {
				if (res.data.success) {
					setCategories(res.data.data);
				} else {
					message.info(res.data.message);
				}
			})
			.catch((err) => {
				message.error(err.message);
				setCategories(categories);
			});
	};

	//Function that gets user data
	const getUserData = async () => {
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/getUserdata`,
				{
					apptoken:
						'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh',
					usertoken: userData.usertoken,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);

			if (res.data.success) {
				localStorage.setItem('userData', JSON.stringify(res.data.data));
			} else {
				message.info(res.data.message);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	// Function to handle next of kin data submission
	const handleKinSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/nextOfKin`,
				{
					...nokData,
					photo: imgSrc,
					apptoken: app_token,
					usertoken: userData.usertoken,
					renitoken: userData.renitoken,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
			setLoading(false);
			setImg(null);
			setImgSrc(null);
			if (res.data.success) {
				getUserData();
				setImgSrc(null);
				setImg(null);
				setNextKin(true);
				message.success('Next of Kin Saved Sucessfully !');
			} else {
				message.warning(res.data.message);
			}
		} catch (error) {
			setImg(null);
			setImgSrc(null);
			setLoading(false);
			message.error(error.message);
		}
	};

	// Function to handle Taking Loan
	const handleTakeLoan = async (e) => {
		e.preventDefault();
		setLoading(true);

		if (!imgSrc) {
			setLoading(false);
			message.info('Please select a passport photo!');
			window.scrollTo(0, 0);
		} else {
			if (plan && providerid) {
				try {
					const res = await axios.post(
						`${process.env.REACT_APP_BASE_URL}/getLoan`,
						{
							apptoken: app_token,
							...loanData,
							plan,
							providerid,
							usertoken: userData.usertoken,
							passport: imgSrc,
							guarantor: [gurantor1, gurantor2],
							collecteral: [
								{
									...collectData,
									means_of_proof: 'Image',
									proof_of_ownership: recipt,
								},
							],
						},
						{
							headers: { Authorization: `Bearer 6455ef91d108f` },
						}
					);
					setLoading(false);
					if (res.data.success) {
						setIsModalOpen1(true);
						window.scrollTo(0, 0);
						setCollect(1);
					} else {
						message.warning(res.data.message);
					}
				} catch (error) {
					setLoading(false);
					message.error(error.message);
				}
			} else {
				setLoading(false);
				message.info('Please select a plan!');
				window.scrollTo(0, 0);
			}
		}
	};

	const handleImageUpload = async () => {
		setImgLoader(true);

		try {
			const formData = new FormData();
			formData.append('image', img);

			const response = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/uploadImage`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer 6455ef91d108f`,
					},
				}
			);
			setImgLoader(false);
			if (response.data.success) {
				message.success('Image uploaded successfully');
				setImgSrc(response.data.data.image);
				setRecipt(response.data.data.image);
			} else {
				message.info(response.data.message);
			}
		} catch (error) {
			setImgLoader(false);
			message.error(error.message);
		}
	};

	// function to handle Next of Kin input data change
	const handleKinChange = (e) => {
		const value = e.target.value;
		setNokData({ ...nokData, [e.target.name]: value });
	};

	// function to handle Next of Kin input data change
	const handleCollectChange = (e) => {
		const value = e.target.value;
		setCollectData({ ...collectData, [e.target.name]: value });
	};
	// function to handle Next of Kin input data change
	const handleGuarantor1 = (e) => {
		const value = e.target.value;
		setGurantor1({ ...gurantor1, [e.target.name]: value });
	};
	// function to handle Next of Kin input data change
	const handleGuarantor2 = (e) => {
		const value = e.target.value;
		setGurantor2({ ...gurantor2, [e.target.name]: value });
	};

	// Function to handle next form display
	const handleCollect = (e) => {
		e.preventDefault();
		if (!recipt || recipt === null) {
			message.info('Please select your receipt image!');
			window.scrollTo(0, 0);
		} else {
			setCollect(2);
			window.scrollTo(0, 0);
		}
	};

	//Function to handle next form display
	const handleGuarantor = (e) => {
		e.preventDefault();
		setCollect(3);
		window.scrollTo(0, 0);
	};
	// UseEffects

	useEffect(() => {
		getUserData();
		fetchPlan();
		fetchCategories();
	}, []);

	// useEffect to generate blob once an image is selected
	useEffect(() => {
		if (img) {
			handleImageUpload();
		}
	}, [img]);

	// function to handle input data change
	const handleChange = (e) => {
		const value = e.target.value;
		setLoanData({ ...loanData, [e.target.name]: value });
	};

	return (
		<div className='loan-container-main'>
			<Wrapper>
				<DashNav title='Loan' />

				<div className='loan-case'>
					{planData?.map((item) => {
						return (
							<div className='loan-item' key={item.plan}>
								<div className='load-item-head'>
									<span>{item.plan}</span>
									<div
										onClick={() => setPlan(item.planid)}
										className={`loan-item-tick ${
											item.planid === plan ? 'loan-item-tick-active' : ''
										}`}></div>
								</div>
								<div className='loan-item-main'>
									<h3>{item.plan}</h3>
									<p>Exclusive repayment plan</p>
								</div>
								<div className='loan-item-footer'>
									<div className='loan-item-footer-inner'>
										<div className='loan-item-footer-1'></div>
										<div className='loan-item-footer-2'></div>
									</div>
								</div>
							</div>
						);
					})}
				</div>

				{/* providers */}
				<div className='loan-case'>
					{loanProviderData?.map((provider) => {
						return (
							<div className='loan-item' key={provider.id}>
								<div className='load-item-head'>
									<span>{provider.providername}</span>
									<div
										onClick={() => setProviderid(provider.id)}
										className={`loan-item-tick ${
											provider.id === providerid ? 'loan-item-tick-active' : ''
										}`}></div>
								</div>
								<div className='loan-item-main'>
									<h3>{provider.providername}</h3>
									<div className='p-img'>
										<div className='p-image'>
											<img
												src={`https://test.solarcredit.io/uploads/${provider.providerimage}`}
												alt=''
											/>
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>

				{nextKin ? (
					<>
						{collect === 1 && (
							<div className='loanform-master'>
								<h2>Collateral Details:</h2>
								<div className='loanform-case'>
									<form onSubmit={handleCollect}>
										<div className='loanform-formitem'>
											<label>Collateral name:</label>
											<input
												type='text'
												name='collateral_name'
												placeholder='Collateral Name'
												onChange={handleCollectChange}
												required
											/>
										</div>
										<div className='loanform-formitem'>
											<label>Category:</label>
											<select
												name='category'
												onChange={handleCollectChange}
												required>
												<option>Select Category</option>
												{categories.map((cat) => {
													return (
														<option key={cat.catid} value={cat.catid}>
															{cat.catname}
														</option>
													);
												})}
											</select>
										</div>
										<div className='loanform-formitem'>
											<label>Years of usage:</label>
											<input
												type='text'
												name='years_of_usage'
												placeholder='Years of usage'
												onChange={handleCollectChange}
												required
											/>
										</div>

										<div className='loanform-formitem'>
											<label>Picture of Receipt:</label>
											<label
												className='loanformprofilePhoto'
												htmlFor='product_photo'>
												{imgLoader
													? 'Uploading...'
													: 'Click to Input your receipt’s photograph'}
											</label>
											<input
												onChange={(e) => setImg(e.target.files[0])}
												type='file'
												style={{ display: 'none' }}
												id='product_photo'
												name='product_photo'
												required
											/>
										</div>
										{recipt && (
											<div className='buyback-formitem imageViewBox-main-img d-flex flex-row align-items-center justify-content-between'>
												<div className='imageViewBox'>
													<img
														src={`https://test.solarcredit.io/uploads/${recipt}`}
														alt=''
													/>
												</div>
											</div>
										)}
										<div className='loanform-formitem'>
											<label>Watt:</label>
											<input
												type='text'
												name='watts'
												placeholder='watt'
												onChange={handleCollectChange}
												required
											/>
										</div>
										<div className='loanform-formitem'>
											<label>Price bought:</label>
											<input
												type='text'
												name='price_bought'
												placeholder='price'
												onChange={handleCollectChange}
												required
											/>
										</div>

										<div className='loanform-button'>
											<button>Next</button>
										</div>
									</form>
								</div>
							</div>
						)}

						{collect === 2 && (
							<div className='loanform-master'>
								<h2>Guarantors Details:</h2>
								<div className='loanform-case'>
									<form onSubmit={handleGuarantor}>
										<div className='loanform-formitem'>
											<label>Guarantor 1:</label>
										</div>

										<div className='loanform-formitem'>
											<label>Name:</label>
											<input
												type='text'
												name='name'
												placeholder='Full name'
												onChange={handleGuarantor1}
												required
											/>
										</div>
										<div className='loanform-formitem'>
											<label>Phone number:</label>
											<input
												type='number'
												name='phone'
												placeholder='Phone number'
												onChange={handleGuarantor1}
												required
											/>
										</div>
										<div className='loanform-formitem'>
											<label>Email:</label>
											<input
												type='text'
												name='email'
												placeholder='Email'
												onChange={handleGuarantor1}
												required
											/>
										</div>
										<div className='loanform-formitem'>
											<label>Relationship:</label>
											<input
												type='text'
												name='relationship'
												placeholder='Relationship'
												onChange={handleGuarantor1}
												required
											/>
										</div>
										<div className='loanform-formitem'>
											<label>Guarantor 2:</label>
										</div>
										<div className='loanform-formitem'>
											<label>Name:</label>
											<input
												type='text'
												name='name'
												placeholder='Full name'
												onChange={handleGuarantor2}
												required
											/>
										</div>
										<div className='loanform-formitem'>
											<label>Phone number:</label>
											<input
												type='number'
												name='phone'
												placeholder='Phone number'
												onChange={handleGuarantor2}
												required
											/>
										</div>
										<div className='loanform-formitem'>
											<label>Email:</label>
											<input
												type='text'
												name='email'
												placeholder='Email'
												onChange={handleGuarantor2}
												required
											/>
										</div>

										<div className='loanform-formitem'>
											<label>Relationship:</label>
											<input
												type='text'
												name='relationship'
												placeholder='Relationship'
												onChange={handleGuarantor2}
												required
											/>
										</div>

										<div className='loanform-button'>
											<button>Next</button>
										</div>
									</form>
								</div>
							</div>
						)}

						{collect === 3 && (
							<div className='loanform-master'>
								<h2>
									Hi {userData.fname.split(' ')[1]}, <br />
									Kindly fill these forms for your loan processing.:
								</h2>
								<div className='loanform-case'>
									<form onSubmit={handleTakeLoan}>
										<div className='loanform-formitem'>
											<label>Amount intending to borrow:</label>
											<input
												type='text'
												name='amountToBorrow'
												placeholder='Amount to borrow'
												onChange={handleChange}
												required
											/>
										</div>

										<div className='loanform-formitem'>
											<label>Purpose of loan:</label>
											<input
												name='purpose_of_loan'
												type='text'
												placeholder='Purpose of loan'
												onChange={handleChange}
												required
											/>
										</div>

										<div className='loanform-formitem'>
											<label>Please input a copy of your face passport:</label>
											<label
												className='loanformprofilePhoto'
												htmlFor='product_photo'>
												{imgLoader
													? 'Uploading...'
													: 'Click to add your face photograph'}
											</label>
											<input
												onChange={(e) => setImg(e.target.files[0])}
												type='file'
												style={{ display: 'none' }}
												id='product_photo'
												placeholder='Phone number'
												name='product_photo'
												required
											/>
										</div>
										{imgSrc && (
											<div className='buyback-formitem imageViewBox-main-img d-flex flex-row align-items-center justify-content-between'>
												<div className='imageViewBox'>
													<img
														src={`https://test.solarcredit.io/uploads/${imgSrc}`}
														alt=''
													/>
												</div>
												{/* <span>{img?.name}</span> */}
											</div>
										)}
										<div className='loanform-button'>
											<button>{loading ? 'Loading' : 'Submit'}</button>
										</div>
									</form>
								</div>
							</div>
						)}
					</>
				) : (
					<div className='loanform-master'>
						<h2>Kindly fill your next of Kin Details.</h2>
						<div className='loanform-case'>
							<form onSubmit={handleKinSubmit}>
								<div className='loanform-formitem'>
									<label>Full name:</label>
									<input
										type='text'
										name='fname'
										onChange={handleKinChange}
										placeholder='Full name'
										required
									/>
								</div>
								<div className='loanform-formitem'>
									<label>Occupation:</label>
									<input
										type='text'
										name='occupation'
										onChange={handleKinChange}
										placeholder='Occupation'
										required
									/>
								</div>
								<div className='loanform-formitem'>
									<label>Means of Identification</label>
									<select
										name='means_of_iden'
										onChange={handleKinChange}
										required>
										<option value='NIN'>Click to select</option>
										<option value='NIN'>NIN</option>
										<option value='BVN'>BVN</option>
										<option value='DriverLicense'>Driver's License</option>
									</select>
								</div>
								<div className='loanform-formitem'>
									<label>Identity No:</label>
									<input
										type='number'
										name='identity_num'
										onChange={handleKinChange}
										placeholder='Identity No'
										required
									/>
								</div>
								<div className='loanform-formitem'>
									<label>Address:</label>
									<textarea
										name='address'
										onChange={handleKinChange}
										placeholder='address'
										id=''
										cols='30'
										rows='10'
										required></textarea>
								</div>
								<div className='loanform-formitem'>
									<label> Please input a copy of your face passport:</label>
									<label
										className='loanformprofilePhoto'
										htmlFor='product_photo'>
										{imgLoader
											? 'Uploading...'
											: 'Click to add face photograph'}
									</label>
									<input
										onChange={(e) => setImg(e.target.files[0])}
										type='file'
										style={{ display: 'none' }}
										id='product_photo'
										placeholder='Phone number'
										required
									/>
								</div>
								{imgSrc && (
									<div className='buyback-formitem imageViewBox-main-img d-flex flex-row align-items-center justify-content-between'>
										<div className='imageViewBox'>
											<img
												src={`https://test.solarcredit.io/uploads/${imgSrc}`}
												alt=''
											/>
										</div>
										{/* <span>{img?.name}</span> */}
									</div>
								)}
								<div className='loanform-button'>
									<button>{loading ? 'Loading' : 'Next'}</button>
								</div>
							</form>
						</div>
					</div>
				)}

				<Modal
					open={isModalOpen1}
					onOk={() => setIsModalOpen1(false)}
					footer={null}
					onCancel={() => setIsModalOpen1(false)}
					centered>
					<Result
						status='success'
						title='Request sent. You will be notified upon approval'
						subTitle='Check your mail for loan status'
					/>
				</Modal>

				<div className='kyc-notification-modal'>
					<Modal
						closable={false}
						visible={kycModal}
						onOk={() => setKycModal(false)}
						onCancel={() => setKycModal(false)}
						footer={null}
						wrapClassName='kyc-notification-modal'
						maskClosable={false}>
						<Result
							status='warning'
							title="Can't access this resource without completing KYC Verification."
							extra={[
								<Button
									key='completeKycBtn'
									onClick={() =>
										navigate('/user/kyc', { state: { kyc: true } })
									}
									type='primary'>
									Complete KYC Verification
								</Button>,
								<Button
									key='closeModalBtn'
									onClick={() => setKycModal(false)}
									type='default'
									style={{ position: 'absolute', top: '10px', left: '10px' }}>
									Close
								</Button>,
							]}
						/>
					</Modal>
				</div>
			</Wrapper>
		</div>
	);
}

export default Loan;
