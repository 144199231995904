import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { message, Rate } from 'antd'
import { TbCurrencyNaira } from 'react-icons/tb'
import Spinner from 'react-bootstrap/Spinner';
import './storeitem.css'
import axios from 'axios'
function StoreItem({ data }) {
    const [loader, setLoader] = useState(false)
    const URL = process.env.REACT_APP_BASE_URL
    const APP_TOKEN = 'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh'
    const userData = JSON.parse(localStorage.getItem('userData'))

    //Finction to add product to cart
    const handleAddToCart = async () => {
        setLoader(true)
        try {
            const res = await axios.post(`${URL}/addTocart`, {
                apptoken: APP_TOKEN,
                productToken: data.productToken,
                usertoken: userData.usertoken
            }, {
                headers: { Authorization: `Bearer 6455ef91d108f` }
            })
            if (res.data.success) {
                setLoader(false)
                message.success('Item added to cart')
            } else {
                setLoader(false)
                message.info(res.data.message)
            }
        } catch (error) {
            setLoader(false)
            message.error(error.message)
        }
    }
    return (
        <div className="storeitem-case shadow">

            <div className="storeitem-imgcase d-flex align-items-center justify-content-center">
                <Link to={`/user/solarstore/${data?.productToken}`} state={data} className='d-flex align-items-center justify-content-center'>
                    <img src={data?.productImage} alt="productImage" />
                </Link>
            </div>
            <div className="storeitem-textcase d-flex flex-column justify-content-between">
                <Link to={`/user/solarstore/${data.productToken}`} state={data}>
                    <h1 className="storeitemtitle">{data?.productname}</h1>
                    {/* <p className="storeitemdesc">{data?.productDesc}</p> */}
                    <div className="storeitemprice">
                        <span className='d-flex align-items-center'><TbCurrencyNaira />{data?.productPrice_thousand}</span>
                    </div>
                    <div className="storeitemrating">
                        {/* <Rate value={2} style={{ fontSize: 20 }} /> */}
                    </div>
                </Link>
                <button onClick={() => handleAddToCart()}>{loader ? <Spinner animation="border" size="sm" /> : 'ADD TO CART'}</button>
            </div>
        </div>
    )
}

export default StoreItem