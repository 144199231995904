import React from 'react';
import { Navigate } from 'react-router-dom';
import TawkToWidget from '../../../../util/TawkToWidget';
// import TawkToChat from '../../../../util/TawkToChat'

function ProtectedRoute({ children }) {
	const userData = JSON.parse(localStorage.getItem('userData'));
	if (!userData) {
		return <Navigate to='/login' />;
	}
	return (
		<div>
			{userData && <TawkToWidget />}
			{children}
		</div>
	);
}

export default ProtectedRoute;
