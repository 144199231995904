import React from 'react'
import Features from '../../components/features/Features'
import Footer from '../../components/footer/Footer'
import Hero from '../../components/hero/Hero'
import Navbar from '../../components/navbar/Navbar'
import Showcase from '../../components/showcase/Showcase'
import Testimonial from '../../components/testimonial/Testimonial'

const LandingPage = () => {
 

    // condition to check if user is already logged in 
   
    return (
        <div>
            <Navbar />
            <Showcase />
            <Features />
            <Testimonial />
            <Hero />
            <Footer />
        </div>
    )
}

export default LandingPage