//Imorted Css
import 'react-circular-progressbar/dist/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
// import {TawkTo}  from 'tawkto-react';
import LandingPage from './pages/landingPage/LandingPage';
import Login from './pages/landingPage/login/Login';
import Register from './pages/landingPage/register/Register';
import ForogtPassword from './pages/landingPage/forgetPassword/ForogtPassword';
// import ResetPassword from "./pages/landingPage/resetPassword/ResetPassword";
import { Routes, Route } from 'react-router-dom';

// users Routes

import ProtectedRoute from './pages/usersDashboard/userComponents/protectedRoute/ProtectedRoute';
import Dashboard from './pages/usersDashboard/userDashboardPages/dashboard/Dashboard';
import Payment from './pages/usersDashboard/userDashboardPages/payment/Payment';
import LoadCalculator from './pages/usersDashboard/userDashboardPages/load calculator/LoadCalculator';
import SolarStore from './pages/usersDashboard/userDashboardPages/solar store/SolarStore';
import Installmental from './pages/usersDashboard/userDashboardPages/pay installmentally/Installmental';
import Loan from './pages/usersDashboard/userDashboardPages/loan/Loan';
import Settings from './pages/usersDashboard/userDashboardPages/settings/Settings';
import Notification from './pages/usersDashboard/userDashboardPages/notification/Notification';
import StoreDetails from './pages/usersDashboard/userComponents/storedetails/StoreDetails';
import Cart from './pages/usersDashboard/userDashboardPages/cart/Cart';
import BuyBack from './pages/usersDashboard/userDashboardPages/buyback/BuyBack';
import UserProfile from './pages/usersDashboard/userDashboardPages/profile/UserProfile';
import Kyc from './components/kyc/Kyc';

// Admin Routes

import AdminDashboard from './pages/adminDashboard/adminPages/dashboard/AdminDashboard';
import AdminProtect from './pages/adminDashboard/adminComponents/adminprotect/AdminProtect';
import Users from './pages/adminDashboard/adminPages/users/Users';
import Category from './pages/adminDashboard/adminPages/category/Category';
import ServiceRequest from './pages/adminDashboard/adminPages/servicerequests/ServiceRequest';
import EditUsers from './pages/adminDashboard/adminPages/editusers/EditUsers';
import AdminLoan from './pages/adminDashboard/adminPages/loan/Loan';
import ViewLoan from './pages/adminDashboard/adminPages/loan/ViewLoan';
import Plans from './pages/adminDashboard/adminPages/plans/Plans';
import ScrollToTop from './util/ScrollToTop';
import Order from './pages/usersDashboard/userDashboardPages/order/Order';
import OrderDetails from './pages/usersDashboard/userDashboardPages/orderDetails/OrderDetails';
import WithdrawalRequest from './pages/adminDashboard/adminPages/withdrawalRequest/WithdrawalRequest';
import Products from './pages/adminDashboard/adminPages/products/Products';
import ProductView from './pages/adminDashboard/adminPages/products/ProductView';
import Orders from './pages/adminDashboard/adminPages/orders/Orders';
import StoreSearch from './pages/usersDashboard/userDashboardPages/solar store/StoreSearch';
import NewsLetter from './pages/adminDashboard/adminPages/newsletter/NewsLetter';
import { ThemeProvider, responsiveFontSizes } from '@mui/material';
import { createTheme } from './theme';
import CreateList from './pages/adminDashboard/adminPages/createlist/CreateList';
import ContactList from './pages/adminDashboard/adminPages/contactlist/ContactList';
import Upload from './pages/adminDashboard/adminPages/uploadfile/Upload';
import Createloan from './pages/adminDashboard/adminPages/createloan/createloan';

function App() {
	console.log(process.env.REACT_APP_ENV);
	const theme = responsiveFontSizes(createTheme());
	return (
		<ThemeProvider theme={theme}>
			<div className='App'>
				<ScrollToTop />
				<Routes>
					<Route exact path='/' element={<LandingPage />} />
					<Route exact path='/login' element={<Login />} />
					<Route exact path='/register' element={<Register />} />
					<Route exact path='/forgotpassword' element={<ForogtPassword />} />
					{/* <Route exact path='/reset' element={<ResetPassword />} /> */}

					{/* All URLS FOR LOGGED IN USERS  */}
					<Route
						exact
						path='/user/dashboard'
						element={
							<ProtectedRoute>
								<Dashboard />
							</ProtectedRoute>
						}
					/>
					<Route
						exact
						path='/user/payment'
						element={
							<ProtectedRoute>
								<Payment />
							</ProtectedRoute>
						}
					/>
					<Route
						exact
						path='/user/loadaudit'
						element={
							<ProtectedRoute>
								<LoadCalculator />
							</ProtectedRoute>
						}
					/>
					<Route
						exact
						path='/user/kyc'
						element={
							<ProtectedRoute>
								<Kyc />
							</ProtectedRoute>
						}
					/>
					<Route
						exact
						path='/user/solarstore'
						element={
							<ProtectedRoute>
								<SolarStore />
							</ProtectedRoute>
						}
					/>
					<Route
						exact
						path='/user/solarstore/:product_id'
						element={
							<ProtectedRoute>
								<StoreDetails />
							</ProtectedRoute>
						}
					/>
					<Route
						exact
						path='/user/solarstore/search'
						element={
							<ProtectedRoute>
								<StoreSearch />
							</ProtectedRoute>
						}
					/>
					<Route
						exact
						path='/user/payinstallmentally'
						element={
							<ProtectedRoute>
								<Installmental />
							</ProtectedRoute>
						}
					/>
					<Route
						exact
						path='/user/buyback'
						element={
							<ProtectedRoute>
								<BuyBack />
							</ProtectedRoute>
						}
					/>
					<Route
						exact
						path='/user/loan'
						element={
							<ProtectedRoute>
								<Loan />
							</ProtectedRoute>
						}
					/>
					<Route
						exact
						path='/user/settings'
						element={
							<ProtectedRoute>
								<Settings />
							</ProtectedRoute>
						}
					/>
					<Route
						exact
						path='/user/notifications'
						element={
							<ProtectedRoute>
								<Notification />
							</ProtectedRoute>
						}
					/>
					<Route
						exact
						path='/user/cart'
						element={
							<ProtectedRoute>
								<Cart />
							</ProtectedRoute>
						}
					/>
					<Route
						exact
						path='/user/orders'
						element={
							<ProtectedRoute>
								<Order />
							</ProtectedRoute>
						}
					/>
					<Route
						exact
						path='/user/orders/:id'
						element={
							<ProtectedRoute>
								<OrderDetails />
							</ProtectedRoute>
						}
					/>
					<Route
						exact
						path='/user/profile'
						element={
							<ProtectedRoute>
								<UserProfile />
							</ProtectedRoute>
						}
					/>

					{/* ALL URLS FOR LOGGED IN ADMINS */}
					<Route
						exact
						path='/admin/dashboard'
						element={
							<AdminProtect>
								<AdminDashboard />
							</AdminProtect>
						}
					/>
					<Route
						exact
						path='/admin/users'
						element={
							<AdminProtect>
								<Users />
							</AdminProtect>
						}
					/>
					<Route
						exact
						path='/admin/users/:usertoken'
						element={
							<AdminProtect>
								<EditUsers />
							</AdminProtect>
						}
					/>
					<Route
						exact
						path='/admin/category'
						element={
							<AdminProtect>
								<Category />
							</AdminProtect>
						}
					/>
					<Route
						exact
						path='/admin/plans'
						element={
							<AdminProtect>
								<Plans />
							</AdminProtect>
						}
					/>
					<Route
						exact
						path='/admin/products'
						element={
							<AdminProtect>
								<Products />
							</AdminProtect>
						}
					/>
					<Route
						exact
						path='/admin/products/:id'
						element={
							<AdminProtect>
								<ProductView />
							</AdminProtect>
						}
					/>
					<Route
						exact
						path='/admin/loans'
						element={
							<AdminProtect>
								<AdminLoan />
							</AdminProtect>
						}
					/>
					<Route
						exact
						path='/admin/loans/:id'
						element={
							<AdminProtect>
								<ViewLoan />
							</AdminProtect>
						}
					/>
					<Route
						exact
						path='/admin/servicerequests'
						element={
							<AdminProtect>
								<ServiceRequest />
							</AdminProtect>
						}
					/>
					<Route
						exact
						path='/admin/withdrawalrequests'
						element={
							<AdminProtect>
								<WithdrawalRequest />
							</AdminProtect>
						}
					/>
					<Route
						exact
						path='/admin/ordersrequests'
						element={
							<AdminProtect>
								<Orders />
							</AdminProtect>
						}
					/>
					<Route
						exact
						path='/admin/newsletter'
						element={
							<AdminProtect>
								<NewsLetter />
							</AdminProtect>
						}
					/>
					<Route
						exact
						path='/admin/createlist'
						element={
							<AdminProtect>
								<CreateList />
							</AdminProtect>
						}
					/>
					<Route
						exact
						path='/admin/contactlist'
						element={
							<AdminProtect>
								<ContactList />
							</AdminProtect>
						}
					/>
					<Route
						exact
						path='/admin/upload'
						element={
							<AdminProtect>
								<Upload />
							</AdminProtect>
						}
					/>
					<Route
						exact
						path='/admin/createloan'
						element={
							<AdminProtect>
								<Createloan />
							</AdminProtect>
						}
					/>
				</Routes>
			</div>
		</ThemeProvider>
	);
}

export default App;
