import React, { useEffect } from 'react';

const TawkToWidget = () => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  useEffect(() => {
    const tawkToLoadScripts = async () => {
      var Tawk_API = Tawk_API || {};

      // Tawk_API.visitor = {
      //   name: userData.fname,
      //   email: userData.mail,
      //   hash: userData.usertoken
      // };

      var Tawk_LoadStart = new Date();

      await (function () {
        var s1 = document.createElement('script'),
          s0 = document.getElementsByTagName('script')[0];
        s1.async = true;
        s1.src = `https://embed.tawk.to/63fc77bf31ebfa0fe7ef8a2b/1gq937h8l`;
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
      })();


      const attritbutesToSet = {}
      if (userData) {
        attritbutesToSet.user = userData.fname
        attritbutesToSet.email = userData.mail
        attritbutesToSet.userId = userData.usertoken
      }

      window.Tawk_API.onLoad = function () {
        //console.log("chat loaded");
        window.Tawk_API.setAttributes(
          {
            ...attritbutesToSet
          },
          function (error) {
            //console.log("window.Tawk_API errorr",error)
          }
        );
      };

    }



    return () => {
      tawkToLoadScripts();
    };
  }, []);



  return <></>;
};

export default TawkToWidget;
