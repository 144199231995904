import React, { useState } from 'react';
import { Button, Stack, TextField, Typography } from '@mui/material';
import AdminWrapper from '../../adminComponents/adminWrapper/AdminWrapper';
import DashNav from '../../../usersDashboard/userComponents/dashNav/DashNav';
import { useBreakpoints } from '../../../../theme/breakpoints';
import axios from 'axios';
import { Link } from 'react-router-dom';

const textFieldStyle = {
	'.MuiOutlinedInput-notchedOutline': {
		border: 'unset',
	},
	'& .Mui-focused .MuiOutlinedInput-notchedOutline': {
		border: 'unset',
	},
	'& input': {
		bgcolor: 'white',
		color: '#000',
		p: '5px 15px',
	},
	'& input::placeholder': {
		fontSize: '1rem',
		color: 'black',
	},
	'& .MuiButtonBase-root.MuiChip-root': {
		bgcolor: 'primary.main',
		color: 'neutral.50',
	},
	'& .MuiChip-root .MuiSvgIcon-root': {
		color: 'neutral.50',
	},
	'& .MuiChip-root .MuiSvgIcon-root:hover': {
		color: 'neutral.50',
	},
	'& .MuiFormLabel-root': {
		// color: '#000'
	},
};

const CreateList = () => {
	const { sm, md } = useBreakpoints();
	const app_token =
		'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';

	const [name, setName] = useState('');
	const [details, setDetails] = useState('');

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === 'name') {
			setName(value);
		} else if (name === 'details') {
			setDetails(value);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/admin/createList`,

				{
					name,
					details,
					apptoken: app_token,
					// Include apptoken here
				},

				{
					headers: {
						Authorization: `Bearer 6455ef91d108f`,
					},
				}
			);
			console.log(res);
			// console.log(lists);
		} catch (error) {
			console.log('Error creating list', error);
		}
	};

	return (
		<div>
			<AdminWrapper>
				<DashNav title='CreateList' />
				<div style={{ marginBottom: '3rem' }}>
					<Link
						style={{ color: '#fcbc0a', fontSize: '1rem' }}
						to={'/admin/newsletter'}>
						Go back
					</Link>
				</div>
				<Stack
					sx={{
						boxShadow: '0 0 20px rgb(0,0,0,.125)',
						borderTopLeftRadius: '25px',
						borderTopRightRadius: '25px',
						overflow: 'hidden',
					}}>
					<Stack
						sx={{
							p: '30px 20px',
							bgcolor: '#f7f8ff',
						}}>
						<Typography variant='h5' sx={{ fontWeight: 500 }}>
							Create List
						</Typography>
					</Stack>

					<Stack>
						<TextField
							placeholder='List Name'
							sx={textFieldStyle}
							id='name'
							name='name'
							value={name}
							onChange={handleChange}
						/>
						<TextField
							placeholder='List Details'
							sx={textFieldStyle}
							id='details'
							name='details'
							value={details}
							onChange={handleChange}
						/>
					</Stack>

					<Stack
						sx={{
							p: !md ? '20px 50px' : '20px 10px',
						}}>
						<Button onClick={handleSubmit}>Send</Button>
					</Stack>
				</Stack>
			</AdminWrapper>
		</div>
	);
};

export default CreateList;
