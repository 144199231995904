import React, { useState, useEffect } from 'react';
import AdminWrapper from '../../adminComponents/adminWrapper/AdminWrapper';
import DashNav from '../../../usersDashboard/userComponents/dashNav/DashNav';
import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { message } from 'antd';
import { DataGrid } from '@mui/x-data-grid';
// import './cart.css'

function Orders() {
	const app_token =
		'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';
	const [show, setShow] = useState(false);
	const [modalData, setModalData] = useState({});
	// const [status, setStatus] = useState('');
	const [loader, setLoader] = useState(false);
	const [orderProduct, setOrderProduct] = useState([]);
	const [filter, setFilter] = useState('once');

	const userData = JSON.parse(localStorage.getItem('userData'));

	//Function to fetch cart data
	const fetchOrders = async () => {
		try {
			setLoader(true);
			const response = await axios.post(
				'https://test.solarcredit.io/v0.1/api/admin/getAllGoodsPaidOnce',
				{
					apptoken: app_token,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);

			console.log('API Response:', response.data);

			if (response.data.success) {
				setOrderProduct(response.data.data);
				console.log('Order Product:', response.data.data);
			} else {
				message.info(response.data.message);
			}
		} catch (error) {
			console.error('Error fetching orders:', error);
			message.error('Error fetching orders. Please try again.');
		} finally {
			setLoader(false);
		}
	};

	const updateStatus = async () => {
		setLoader(true);
		try {
			const res = await axios.post(
				`https://test.solarcredit.io/v0.1/api/admin/${
					filter === 'once'
						? 'acknowledPaidOnceOrder'
						: 'acknowledgeProductPaidInstallmentally'
				}`,
				{
					apptoken: app_token,
					usertoken: userData.usertoken,
					orderid: modalData.orderid,
					productToken: modalData.productToken,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
			setLoader(false);
			if (res.data.success) {
				fetchOrders();
				message.success(res.data.message);
				setShow(false);
			} else {
				message.info(res.data.message);
			}
		} catch (error) {
			setLoader(false);
			setShow(false);
			message.error(error.message);
		}
	};

	// Function To Open Modal
	const openModal = (modaldata) => {
		setModalData(modaldata);
		setShow(true);
	};

	// UseEffect to fetch cart when page loads
	useEffect(() => {
		fetchOrders();
	}, [filter]);

	useEffect(() => {
		console.log('Order Product Updated:', orderProduct);
	}, [orderProduct]);

	// Column For The Table
	const columns = [
		{ field: 'orderid', headerName: 'Order ID', width: 150 },
		{
			field: 'productname',
			headerName: 'Product Name',
			width: 300,
			renderCell: (params) => {
				return <div className='userListUser'>{params.row.productname}</div>;
			},
		},
		{ field: 'status', headerName: 'Order Status', width: 100 },

		{
			field: 'action',
			headerName: 'Action',
			width: 100,
			renderCell: (params) => {
				return (
					<>
						<button
							className='adminTableBtn'
							onClick={() => openModal(params.row)}>
							View
						</button>
					</>
				);
			},
		},
	];

	console.log('Order Product Before Rendering:', orderProduct);

	return (
		<div>
			<AdminWrapper>
				<DashNav title={'Orders'} />
				<div className='cartdetailsmain'>
					<div className='cartdetailscase d-flex align-items-start justify-content-between'>
						<div className='cartdetailsleft admin d-flex flex-column  justify-content-start shadow'>
							<div className='cart-header d-flex align-items-center justify-content-between'>
								<h6>
									All orders {`(${orderProduct ? orderProduct?.length : 0})`}
								</h6>
								<div className='d-flex align-items-center order-tab-case'>
									<h6
										className={filter === 'once' ? 'active' : ''}
										onClick={() => setFilter('once')}>
										Paid Once
									</h6>
									<h6
										className={filter === 'installment' ? 'active' : ''}
										onClick={() => setFilter('installment')}>
										Installmentally
									</h6>

									{loader && (
										<Spinner
											animation='border'
											role='status'
											size='sm'
											className='store-spinner'>
											<span className='visually-hidden'>Loading...</span>
										</Spinner>
									)}
								</div>
							</div>

							<div className='adminuser-case'>
								{orderProduct.length > 0 ? (
									<DataGrid
										rows={orderProduct}
										columns={columns}
										disableSelectionOnClick
										pageSize={8}
										rowsPerPageOptions={[5]}
										checkboxSelection
										getRowId={(r) => r.orderid}
									/>
								) : (
									<p>Loading...</p>
								)}
							</div>
						</div>
					</div>
				</div>

				<Modal
					className='categoryModal'
					show={show}
					onHide={() => setShow(false)}
					centered
					keyboard={false}>
					<Modal.Header closeButton>
						<Modal.Title>{'Order Details'}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className='servicemodalcase'>
							<>
								<div className='d-flex flex-column align-items-start'>
									<span className='serviceinfotitle'>
										Name: {modalData?.productname}
									</span>
									<br />
									<span className='serviceinfotitle'>
										{filter === 'once'
											? `Total Price: ${modalData?.TotalPrice}`
											: `Total Amount: ${modalData?.total_amount}`}
									</span>
									<br />
									<span className='serviceinfotitle'>
										Date Purchased: {modalData?.datePurchased}
									</span>
									<br />
									<span className='serviceinfotitle'>
										Mode Of Payment: {modalData?.modeOfPayment}
									</span>
									<br />
									<span className='serviceinfotitle'>
										Order ID: {modalData?.orderid}
									</span>
									<br />
									<span className='serviceinfotitle'>
										Product Type: {modalData?.productType}
									</span>
									<br />
									<span className='serviceinfotitle'>
										Current Order Status: {modalData?.status}
									</span>
									<br />
									<div className='serviceInfoImg d-flex align-items-center justify-content-start'>
										<img src={modalData?.productImage} alt='Product Image' />
									</div>
									<br />
									<span className='serviceinfotitle'>
										Buyer's Name: {modalData?.buyerInfo?.fname}
									</span>
									<br />
									<span className='serviceinfotitle'>
										Buyer's mail: {modalData?.buyerInfo?.mail}
									</span>
									<br />
									<span className='serviceinfotitle'>
										Buyer's usertoken: {modalData?.buyerInfo?.usertoken}
									</span>
									<br />
									<span className='serviceinfotitle'>
										Buyer's Number: {modalData?.buyerInfo?.phone}
									</span>
									<br />
									<span className='serviceinfotitle'>
										Buyer's Pending balance:{' '}
										{modalData?.buyerInfo?.getAllOutstandingFee}
									</span>
									<br />
									<span className='serviceinfotitle'>
										Buyer's Account type: {modalData?.buyerInfo?.userType}
									</span>

									{modalData.MonnthsEstimatedToPayInstallmentally && (
										<>
											<br />
											<span className='serviceinfotitle'>
												Subscribed Plan: {modalData?.subscribedPlan}
											</span>
											<br />
											<span className='serviceinfotitle'>
												Repayment Status: {modalData?.isCompletedStatus}
											</span>
											<br />
											<span className='serviceinfotitle'>
												Estimated Payment Completion Date:{' '}
												{modalData?.dateEstimatedToFinish}
											</span>
											<br />
											<span className='serviceinfotitle'>
												Amount Debited: {modalData?.amountDebitedSoFar}
											</span>
											<br />
											<span className='serviceinfotitle'>
												Amount Remaining:{' '}
												{modalData?.amountRemmainning_thousand}
											</span>
											<br />
											<span className='serviceinfotitle'>
												Monthly Repayment Amount:{' '}
												{modalData?.amountToPayMonthly_thousand}
											</span>

											{modalData.MonnthsEstimatedToPayInstallmentally.map(
												(item, index) => {
													return (
														<div key={index}>
															<br />
															<span className='serviceinfotitle'>
																Month {index + 1} Repayment Details:
															</span>
															<br />
															<span className='serviceinfotitle'>
																Due Date: {item.dueDate}
															</span>
															<br />
															<span className='serviceinfotitle'>
																Payment Status:{item.paymentStatus}
															</span>
															<br />
															<span className='serviceinfotitle'>
																Price Expected To Pay:{item.priceExpectedToPay}
															</span>
														</div>
													);
												}
											)}
										</>
									)}
								</div>

								<div
									className='servicemodalbuttoncase d-flex align-items-center justify-content-end'
									style={{
										marginTop: '40px',
									}}>
									<button onClick={() => updateStatus()}>
										{loader ? (
											<Spinner animation='border' size='sm' />
										) : (
											'Update status'
										)}
									</button>
								</div>
							</>
						</div>
					</Modal.Body>
				</Modal>
			</AdminWrapper>
		</div>
	);
}

export default Orders;
