import React, { useState, useEffect } from 'react';
import coverimg from '../../../../icons/profilebg.jpg';
import profileimg from '../../../../icons/profileimg.jpg';
import addimage from '../../../../icons/addimage.svg';
import DashNav from '../../userComponents/dashNav/DashNav';
import Wrapper from '../../userComponents/userWrapper/Wrapper';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import './userprofile.css';
import axios from 'axios';
import { message } from 'antd';

function UserProfile() {
	const userData = JSON.parse(localStorage.getItem('userData'));
	const [fullname, setFullname] = useState(userData ? userData.fname : '');
	const [email, setEmail] = useState(userData ? userData.mail : '');
	const [phone, setPhone] = useState(userData ? userData.phone : '');
	const [pimage, setPimage] = useState(profileimg);
	const [img, setImg] = useState(null);
	const [loading, setLoading] = useState(false);
	const URL = process.env.REACT_APP_BASE_URL;
	const APP_TOKEN =
		'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';

	//Function to get all store products
	const updateProfile = async () => {
		setLoading(true);
		try {
			const res = await axios.post(
				`${URL}/updateUser`,
				{
					fname: fullname,
					phone,
					mail: email,
					usertoken: userData?.usertoken,
					image: pimage,
					apptoken: APP_TOKEN,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
			setLoading(false);
			if (res.data.success) {
				message.success('Profile updated Sucessfully');
				getUserData();
			} else {
				message.info(res.data.message);
			}
		} catch (error) {
			setLoading(false);
			message.error(error.message);
		}
	};

	const getUserData = async () => {
		try {
			const res = await axios.post(
				'https://test.solarcredit.io/v0.1/api/getUserdata',
				{
					apptoken:
						'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh',
					usertoken: userData.usertoken,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);

			if (res.data.success) {
				localStorage.setItem('userData', JSON.stringify(res.data.data));
			} else {
				message.info(res.data.message);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	// Function that converts image to blob
	const handleBlob = () => {
		const reader = new FileReader();
		reader.addEventListener('load', () => {
			// Base64 Data URL
			setPimage(reader.result);
		});
		reader.readAsDataURL(img);
	};

	useEffect(() => {
		if (img) {
			handleBlob();
		}
	}, [img]);

	return (
		<div>
			<Wrapper>
				<DashNav />
				<div className='profile-container d-flex align-items-start justify-content-between'>
					<div className='profile-container-left'>
						<Link to={-1}>
							<BsFillArrowLeftCircleFill />
						</Link>
					</div>
					<div className='profile-container-right d-flex align-items-start justify-content-start'>
						<div className='profile-case d-flex flex-column justify-content-end'>
							<div className='profile-image-case'>
								<div className='profiletopcase'>
									<div
										className='profile-coverimg'
										style={{
											backgroundImage: `url(${coverimg})`,
										}}>
										<div
											className='profileimg'
											style={{
												backgroundImage: `url(${pimage})`,
											}}>
											<label htmlFor='profileimg'>
												<input
													type='file'
													onChange={(e) => setImg(e.target.files[0])}
													style={{ display: 'none' }}
													name='coverimg'
													id='profileimg'
												/>
												<img className='profileimgadd' src={addimage} alt='' />
											</label>
										</div>
									</div>
									<div className='profile-text-upper d-flex w-100 align-items-center justify-content-end'>
										<div className='profile-text-inner d-flex align-items-center justify-content-between'>
											<div>
												<h1>{userData.fname}</h1>
												<p>Update your photo and personal details</p>
											</div>
											<button onClick={() => updateProfile()}>
												{loading ? 'Saving' : 'Save'}
											</button>
										</div>
									</div>
								</div>
								<div className='profilebottomcase'>
									<div className='profileflexcase'>
										<label htmlFor='fname'>Full Name</label>
										<input
											type='text'
											value={fullname}
											onChange={(e) => setFullname(e.target.value)}
											name='fname'
											id='fname'
											disabled
										/>
									</div>
									<div className='profileflexcase'>
										<label htmlFor='email'>Email</label>
										<input
											type='email'
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											name='email'
											id='email'
											disabled
										/>
									</div>
									<div className='profileflexcase'>
										<label htmlFor='number'>Phone Number</label>
										<input
											type='number'
											value={phone}
											onChange={(e) => setPhone(e.target.value)}
											name='number'
											id='number'
											disabled={userData.phone ? true : false}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Wrapper>
		</div>
	);
}

export default UserProfile;
