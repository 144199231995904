import React, { useState } from 'react'
import logo from '../../../images/logo.png'
import VerifyOtp from '../verifyOtp/VerifyOtp'
import { Link, Navigate } from 'react-router-dom'
import { message } from 'antd'
import axios from 'axios'
import '../login/login.css'

const Register = () => {

  const [data, setData] = useState(null)
  const [button, setButton] = useState('Create Account')
  const [verifyStatus, setVerifyStatus] = useState(false)
  const APP_TOKEN = 'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh'
  const userData = JSON.parse(localStorage.getItem('userData'))

  // function to handle input data change
  const handleChange = (e) => {
    const value = e.target.value
    setData({ ...data, [e.target.name]: value })
  }

  // function to register the user 
  const signUp = async (e) => {
    e.preventDefault()
    setButton('Loading')
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/signUp`, { ...data, apptoken: APP_TOKEN, phone: 234 + data.phone.slice(1), cpword: data.pword }, {
        headers: { Authorization: `Bearer 6455ef91d108f` }
      })
      setButton('Create Account')
      if (res.data.success) {
        setVerifyStatus(true)
      } else {
        message.warning(res.data.message)
      }
    } catch (error) {
      message.error(error.message)
      setButton('Create Account')
    }
  }



  // condition to check if user is already logged in 
  if (userData) {
    return <Navigate to="/user/dashboard" />
  }
  if (verifyStatus) {
    return <VerifyOtp mail={data.mail} setVerifyStatus={setVerifyStatus} login={false} />
  }
  return (
    <div>
      <div className="login">
        <div className="login-inner">
          <Link to='/'>
            <img src={logo} alt="" />
          </Link>
          <div className="login-form">
            <h4 >Create a secure account</h4>
            <form onSubmit={signUp}>

              <div className="login-form-group">
                <label htmlFor="fullname">Full Name</label>
                <input type="text" placeholder='Full Name' name='fname' onChange={handleChange} required />
              </div>
              <div className="login-form-group">
                <label htmlFor="email">Email Address</label>
                <input type="text" placeholder='Email Address' name='mail' onChange={handleChange} required />
              </div>
              <div className="login-form-group">
                <label htmlFor="number">Mobile Number</label>
                <input type="text" placeholder='Mobile Number' name='phone' onChange={handleChange} required />
              </div>
              <div className="login-form-group">
                <label htmlFor="occupation">Occupation</label>
                <input type="text" placeholder='Occupation' name='occupation' onChange={handleChange} required />
              </div>
              <div className="login-form-group">
                <label htmlFor="password">Password</label>
                <input type="password" placeholder='Password' name='pword' onChange={handleChange} required />
              </div>
              <div className="login-form-group">
                <label htmlFor="password">How did you hear about us? (Optional)</label>
                <select name="abtUs" onChange={handleChange} >
                  <option value="facebook">Click to select</option>
                  <option value="facebook">Facebook</option>
                  <option value="instagram">Instagram</option>
                  <option value="twitter">Twitter</option>
                  <option value="friend">Friend</option>
                </select>
              </div>

              <button type="submit">{button}</button>
            </form>
          </div>


          <span className='m-3'>Alerady have an account? <Link to='/login'>Login</Link></span>
        </div>
      </div>
    </div>
  )
}

export default Register

