import React, { useState } from 'react';
import DashNav from '../../userComponents/dashNav/DashNav';
import Wrapper from '../../userComponents/userWrapper/Wrapper';
import Modal from 'react-bootstrap/Modal';
import { BiPlusCircle } from 'react-icons/bi';
import { IoMdRemoveCircleOutline } from 'react-icons/io';
import { nanoid } from 'nanoid';
import axios from 'axios';
import './loadcalculator.css';
import { Spinner } from 'react-bootstrap';
import { message } from 'antd';
function LoadCalculator() {
	// const userData = JSON.parse(localStorage.getItem('userData'))
	const app_token =
		'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';

	const [show, setShow] = useState(false);
	const [name, setName] = useState('');
	const [watt, setWatt] = useState(null);
	const [quantity, setQuantity] = useState(null);
	const [hours_per_day, setHoursperday] = useState(null);
	const [days_per_week, setDaysperweek] = useState(null);
	const [type, setType] = useState(null);
	const [data, setData] = useState([]);
	const [response, setResponse] = useState({});
	const [loader, setLoader] = useState(false);

	const handleClose = () => {
		setShow(false);
	};
	const handleShow = () => {
		setName('');
		setWatt(null);
		setHoursperday(null);
		setDaysperweek(null);
		setQuantity(null);
		setShow(true);
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		setData([
			...data,
			{
				id: nanoid(),
				name,
				watts: watt,
				Qty: quantity,
				hours_per_day,
				days_per_week,
				type,
			},
		]);
		handleSendLoad([
			...data,
			{
				id: nanoid(),
				name,
				watts: watt,
				Qty: quantity,
				hours_per_day,
				days_per_week,
				type,
			},
		]);
		handleClose();
	};
	const handleRemove = (id) => {
		setData(data.filter((item) => item.id !== id));
		handleSendLoad(data.filter((item) => item.id !== id));
	};

	function calculateWattHours(products) {
		let totalWattHours = 0;

		for (let i = 0; i < products.length; i++) {
			let product = products[i];
			let wattHours = product.watts * product.Qty * product.hours_per_day;
			totalWattHours += wattHours;
		}
		return totalWattHours;
	}

	const handleSendLoad = async (data) => {
		setLoader(true);
		try {
			const res = await axios.post(
				'https://test.solarcredit.io/v0.1/api/load/load',
				{
					apptoken: app_token,
					load: data,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
			setLoader(false);
			if (res.data.success) {
				setResponse(res.data.data);
			}
		} catch (error) {
			setLoader(false);
			message.info(error.message);
		}
	};

	console.log(data);

	function calculateSolarSystemParameters(applianceData) {
		// Default parameters
		const sunlightHours = 5; // Assuming 5 hours of sunlight per day
		const autonomyDays = 2; // Assuming 2 days of autonomy
		const batteryEfficiency = 0.8; // 80% battery efficiency
		const inverterEfficiency = 0.9; // 90% inverter efficiency

		// Calculate total weekly load in kWh
		let totalWeeklyLoad = 0;
		applianceData.forEach((appliance) => {
			const watts = parseFloat(appliance.watts);
			const quantity = parseInt(appliance.Qty);
			const hoursPerDay = parseFloat(appliance.hours_per_day);
			const daysPerWeek = parseInt(appliance.no_of_use_per_week);
			totalWeeklyLoad += (watts * quantity * hoursPerDay * daysPerWeek) / 1000; // Convert watts to kilowatts
		});

		// Calculate total energy consumption during autonomy days
		const totalEnergyAutonomy = totalWeeklyLoad * autonomyDays;

		// Calculate battery capacity in kWh
		const batteryCapacity = totalEnergyAutonomy / batteryEfficiency;

		// Calculate daily solar panel generation in kWh
		const dailySolarGeneration =
			sunlightHours * (totalWeeklyLoad + totalEnergyAutonomy);

		// Calculate required solar panel capacity in watts
		const solarPanelCapacity = dailySolarGeneration / sunlightHours;

		// Calculate inverter size based on daily energy consumption
		const inverterSize =
			totalWeeklyLoad / (inverterEfficiency * sunlightHours * 7);

		// Determine recommended operating voltage based on battery capacity
		let recommendedVoltage = 12; // Default to 12V
		if (batteryCapacity > 200) {
			recommendedVoltage = 48;
		} else if (batteryCapacity > 150) {
			recommendedVoltage = 36;
		} else if (batteryCapacity > 100) {
			recommendedVoltage = 24;
		}

		const batteryCapacityAh = (batteryCapacity * 1000) / recommendedVoltage;

		return {
			totalWeeklyLoad,
			solarPanelCapacity,
			inverterSize,
			recommendedVoltage,
			batteryCapacity,
			batteryCapacityAh,
		};
	}

	// Example usage with the given applianceData
	const applianceData = [
		{
			id: 'H3nSHwIyn-fnYffy5bVbh',
			name: 'phone',
			watts: '30',
			Qty: '2',
			hours_per_day: '5',
			no_of_use_per_week: '7',
			loadType: 'AC',
		},
		{
			id: 'c4AhB1imc0qpQdaUUxeCb',
			name: 'laptop',
			watts: '100',
			Qty: '2',
			hours_per_day: '5',
			no_of_use_per_week: '7',
			loadType: 'AC',
		},
	];

	const solarSystemParameters = calculateSolarSystemParameters(applianceData);
	console.log(solarSystemParameters);

	return (
		<div>
			<Wrapper>
				<DashNav title='Load Audit' />
				<div class='loadauditcase'>
					<div class='loadauditdirections d-flex align-items-center justify-content-between'>
						<div class='loadauditdiritem d-flex align-items-center'>
							<div class='loadauditnum'>1</div>
							<span>Define your load appliances</span>
						</div>
						<div class='loadauditdiritem d-flex align-items-center'>
							<div class='loadauditnum'>2</div>
							<span>Input load requirement</span>
						</div>
						<div class='loadauditdiritem d-flex align-items-center'>
							<div class='loadauditnum'>3</div>
							<span>Calculate</span>
						</div>
					</div>
					<div class='loadauditmain mt-3'>
						<h3>Select your appliances</h3>
						<button onClick={() => handleShow()}>
							{' '}
							<BiPlusCircle className='cancel-icon' />
							Add appliance
						</button>
						<div class='loadaudittable'>
							<div class='loadaudittablecol1'>Appliances</div>
							<div class='loadaudittablecol2'>Watt</div>
							<div class='loadaudittablecol3'>Quantity</div>
							<div class='loadaudittablecol3'>{'Usage (hrs)'}</div>
						</div>
						<div class='loadaudittablerowcase'>
							{data?.map((item) => {
								return (
									<div class='loadaudittablerowitem' key={item.id}>
										<div class='loadaudittablerowitem1'>
											{' '}
											<IoMdRemoveCircleOutline
												onClick={() => handleRemove(item.id)}
												className='cancel-icon'
											/>
											{item.name}
										</div>
										<div class='loadaudittablerowitem2'>{item.watts}</div>
										<div class='loadaudittablerowitem3'>{item.Qty}</div>
										<div class='loadaudittablerowitem3'>
											{item.hours_per_day}
										</div>
									</div>
								);
							})}
						</div>

						<div class='loadaudittable mt-3 d-flex justify-content-end'>
							<div class='loadaudittablecol'>
								Total Watt Hours Daily: {calculateWattHours(data)}
							</div>
						</div>
					</div>
					<div class='loadauditfooter'>
						<h3>Total load requirement estimate</h3>

						{loader ? (
							<div
								style={{ padding: '50px 0px' }}
								className='d-flex w-100 align-items-center justify-content-center'>
								<Spinner
									animation='border'
									role='status'
									size='lg'
									className='store-spinner'>
									<span className='visually-hidden'>Loading...</span>
								</Spinner>
							</div>
						) : (
							<div class='loadauditbox d-flex flex-column justify-content-between mt-3'>
								<div class='loadauditboxitem d-flex align-items-center justify-content-between'>
									<div class='d-flex justify-content-between align-items-center'>
										<span>Total weekly load:</span>
										<div class='loadresult'>
											{response.totalWeeklyLoad ? response.totalWeeklyLoad : 0}
											wh
										</div>
									</div>
									<div class='d-flex justify-content-between align-items-center'>
										<span>Battery Capacity:</span>
										<div class='loadresult'>
											{response.totalNumberOfBatteries
												? response.totalNumberOfBatteries
												: 0}
										</div>
									</div>
								</div>
								<div class='loadauditboxitem d-flex align-items-center justify-content-between'>
									<div class='d-flex justify-content-between align-items-center'>
										<span>Panel Size:</span>
										<div class='loadresult'>
											{response.BatterySize ? response.BatterySize : 0}Ah
										</div>
									</div>
									<div class='d-flex justify-content-between align-items-center'>
										<span>Inverter Size:</span>
										<div class='loadresult'>
											{response.inverterSize ? response.inverterSize : 0}w
										</div>
									</div>
								</div>
								<div class='loadauditboxitem d-flex align-items-center justify-content-between'>
									<div class='d-flex justify-content-between align-items-center'>
										<span>Operating System Volage:</span>
										<div class='loadresult'>
											{response.numberOfPvModules
												? response.numberOfPvModules
												: 0}
										</div>
									</div>
									<div class='d-flex justify-content-between align-items-center'>
										<span>Charge Controller Rating:</span>
										<div class='loadresult'>
											{response.numberOfPvModulesSize
												? response.numberOfPvModulesSize
												: 0}
										</div>
									</div>
								</div>
							</div>
						)}
						<div class='loadcalcbuttoncase d-flex align-items-center mt-3'>
							<button onClick={() => handleSendLoad(data)}>Calculate</button>
							<button
								onClick={() => {
									setData([]);
									setResponse({});
								}}>
								Reset
							</button>
						</div>
					</div>
				</div>
				<Modal show={show} onHide={handleClose} centered>
					<Modal.Header closeButton>
						<Modal.Title>Add New Appliance</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form class='modal-form' onSubmit={handleSubmit}>
							<input
								type='text'
								placeholder='Appliance Name'
								required
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
							<input
								type='number'
								placeholder='Watt'
								required
								value={watt}
								onChange={(e) => setWatt(e.target.value)}
							/>
							<input
								type='number'
								placeholder='Quantity'
								required
								value={quantity}
								onChange={(e) => setQuantity(e.target.value)}
							/>
							<input
								type='number'
								placeholder='Hours of Use per day'
								required
								value={hours_per_day}
								onChange={(e) => setHoursperday(e.target.value)}
							/>
							<input
								type='number'
								placeholder='No of use per week (in days)'
								required
								value={days_per_week}
								onChange={(e) => setDaysperweek(e.target.value)}
							/>
							<select
								name='type'
								onChange={(e) => setType(e.target.value)}
								required>
								<option>Select load type</option>
								<option value='AC'>AC</option>
								<option value='DC'>DC</option>
							</select>
							<button>Add</button>
						</form>
					</Modal.Body>
				</Modal>
			</Wrapper>
		</div>
	);
}

export default LoadCalculator;
