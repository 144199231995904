import { createTheme as createMuiTheme } from '@mui/material';
import { createPalette } from './palette';
import { createTypography } from './components/typography';

export const createTheme = () => {
  const palette = createPalette();
  const typography = createTypography()

  return createMuiTheme({
    breakpoints: {
      values: {
        xs: 450,
        sm: 625,
        md: 991,
        lg: 1290,
        xl: 1440
      }
    },
    palette,
    typography
  });

}