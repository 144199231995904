import React from 'react';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import DashNav from '../../userComponents/dashNav/DashNav';
import Wrapper from '../../userComponents/userWrapper/Wrapper';
import './orderdetails.css';
import { useLocation, useNavigate } from 'react-router-dom';

function OrderDetails() {
	const navigate = useNavigate();
	const data = useLocation()?.state;

	console.log(data);
	return (
		<div>
			<Wrapper>
				<DashNav title='Order Details' />

				<div className='back-case d-flex align-items-center justify-content-start'>
					<div style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
						{' '}
						<BsFillArrowLeftCircleFill />
					</div>
				</div>

				<section class='order-details'>
					<div class='order-info'>
						<p>
							<strong>Order Number: </strong>
							{data?.orderid}
						</p>
						<p>
							<strong>Order Date: </strong>
							{data?.datePurchased}
						</p>
						<p>
							<strong>Order Status: </strong>
							{data?.status}
						</p>
						{data.MonnthsEstimatedToPayInstallmentally && (
							<>
								<p>
									<strong>Amount Debited: </strong>
									{data?.amountDebitedSoFar}
								</p>
								<p>
									<strong>Amount Remaining: </strong>
									{data?.amountRemmainning_thousand}
								</p>
								<p>
									<strong>Monthly Repayment Amount: </strong>
									{data?.amountToPayMonthly_thousand}
								</p>
								<p>
									<strong>Estimated Completion Date: </strong>
									{data?.dateEstimatedToFinish}
								</p>
								<p>
									<strong>Payment Status: </strong>
									{data?.isCompletedStatus}
								</p>
								<p>
									<strong>Subscribed Plan: </strong>
									{data?.subscribedPlan}
								</p>
							</>
						)}
					</div>
					<table>
						<thead>
							<tr>
								<th>Product</th>
								<th>Image</th>
								<th>Price</th>
								<th>Quantity</th>
								<th>Subtotal</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{data?.productname}</td>
								<td>
									<img src={data?.productImage} alt='Product-Img' />
								</td>
								<td>#{data?.price}</td>
								<td>{data?.productQuantity}</td>
								<td>#{data?.price}</td>
							</tr>
							<tr>
								<td colspan='4'>Total</td>
								<td>#{data?.price}</td>
							</tr>
						</tbody>
					</table>
					<div class='payment-details'>
						{data?.MonnthsEstimatedToPayInstallmentally?.map((item, index) => {
							return (
								<div key={index}>
									<br />
									<p>
										<strong>Month {index + 1} Repayment Details:</strong>
									</p>
									<p>
										<strong>Date To Pay:</strong> {item.dateToPayId}
									</p>
									<p>
										<strong>Due Date:</strong> {item.dueDate}
									</p>
									<p>
										<strong>Payment Status:</strong> {item.paymentStatus}
									</p>
									<p>
										<strong>Repayment Price:</strong> {item.priceExpectedToPay}
									</p>
								</div>
							);
						})}
						<br />
						<p>
							<strong>Payment Details:</strong>
						</p>
						<p>
							<strong>Payment Method:</strong> {data?.modeOfPayment}
						</p>
						<p>
							<strong>Payment Type:</strong> {data?.productType}
						</p>
						<p>
							<strong>Total Amount:</strong> #{data?.price}
						</p>
						<p>
							<strong>Tax:</strong> #0.00
						</p>
						<p>
							<strong>Shipping Fee:</strong> #0.00
						</p>
						<br />
					</div>
				</section>
			</Wrapper>
		</div>
	);
}

export default OrderDetails;
