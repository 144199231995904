import React from 'react'
import featureimg from '../../images/featureimg.jpg'
import productimg1 from '../../images/product1.png'
import './features.css'

const Features = () => {
    return (
        <div>
            <div className="features">
                <div className="featuresInner">
                    <h1>Our Features:</h1>
                    <div className="features-flex d-flex align-items-center justify-content-center">
                        <div className="features-flex-left">
                            <img src={featureimg} alt="" />
                        </div>
                        <div className="features-flex-right">
                            <div className="features-flex-right-inner shadow p-3">

                                <div className="features-inner-items d-flex  ">
                                    <div className="features-icon-case">
                                        <i className="fa-solid fa-solar-panel"></i>
                                    </div>
                                    <div className="features-text-case">
                                        <h4>Payment Plans</h4>
                                        <p>Take home solar gadgets with just an upfront
                                            payment and start an instalment plan</p>
                                    </div>
                                </div>
                                <div className="features-inner-items d-flex  ">
                                    <div className="features-icon-case">
                                        <i className="fa-solid fa-solar-panel"></i>
                                    </div>
                                    <div className="features-text-case">
                                        <h4> Solar shop</h4>
                                        <p>A top notch add-on store where you can buy and go through your required solar gadgets.</p>
                                    </div>
                                </div>
                                <div className="features-inner-items d-flex  ">
                                    <div className="features-icon-case">
                                        <i className="fa-solid fa-solar-panel"></i>
                                    </div>
                                    <div className="features-text-case">
                                        <h4>Load Audit</h4>
                                        <p>Provide your appliance ratings and get the best recommended solution..</p>
                                    </div>
                                </div>
                                <div className="features-inner-items d-flex  ">
                                    <div className="features-icon-case">
                                        <i className="fa-solid fa-solar-panel"></i>
                                    </div>
                                    <div className="features-text-case">
                                        <h4>Need a loan</h4>
                                        <p>Take a low interest loan from us using your solar gadgets as a form of collateral.</p>
                                    </div>
                                </div>
                                <div className="features-inner-items d-flex  ">
                                    <div className="features-icon-case">
                                        <i className="fa-solid fa-solar-panel"></i>
                                    </div>
                                    <div className="features-text-case">
                                        <h4>Buy back</h4>
                                        <p>A new feature where you are able to resell your used solar gadgets at exciting bargains.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="features-flex-section">
                <div className="features-flex-section-inner">
                    <div className="features-flex-section-item shadow">
                        <div className="features-flex-section-item-image">
                            <img src={productimg1} alt="" />
                        </div>
                        <div className="features-flex-section-item-texts">
                            <h4>Power wall LFP Battery 5KWH to 30KW Off Grid Power System</h4>
                            <button>+</button>
                        </div>

                    </div>
                    <div className="features-flex-section-item shadow">
                        <div className="features-flex-section-item-image">
                            <img src={productimg1} alt="" />
                        </div>
                        <div className="features-flex-section-item-texts">
                            <h4>Power wall LFP Battery 5KWH to 30KW Off Grid Power System</h4>
                            <button>+</button>
                        </div>

                    </div>
                    <div className="features-flex-section-item shadow">
                        <div className="features-flex-section-item-image">
                            <img src={productimg1} alt="" />
                        </div>
                        <div className="features-flex-section-item-texts">
                            <h4>Power wall LFP Battery 5KWH to 30KW Off Grid Power System</h4>
                            <button>+</button>
                        </div>

                    </div>
                    <div className="features-flex-section-item shadow">
                        <div className="features-flex-section-item-image">
                            <img src={productimg1} alt="" />
                        </div>
                        <div className="features-flex-section-item-texts">
                            <h4>Power wall LFP Battery 5KWH to 30KW Off Grid Power System</h4>
                            <button>+</button>
                        </div>

                    </div>
                </div>
            </div>

            
        </div>
    )
}

export default Features