import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DashNav from '../../../usersDashboard/userComponents/dashNav/DashNav';
import AdminWrapper from '../../adminComponents/adminWrapper/AdminWrapper';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { Modal, Spinner } from 'react-bootstrap';
import { message } from 'antd';

import axios from 'axios';

function ViewLoan() {
	const [show, setShow] = useState(false);
	const [reason, setReason] = useState('');
	const [loader, setLoader] = useState(false);
	const [loanDetails, setLoanDetails] = useState({});
	const location = useLocation();
	const user = location?.state;
	// const userData = JSON.parse(localStorage.getItem('userData'))
	const app_token =
		'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';
	const navigate = useNavigate();
	// Function To Approve Loan Requests
	const approveLoans = () => {
		setLoader(true);
		axios
			.post(
				'https://test.solarcredit.io/v0.1/api/admin/approveLoan',
				{
					apptoken: app_token,
					usertoken: user?.usertoken,
					loantoken: loanDetails?.loantoken,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			)
			.then((res) => {
				setLoader(false);
				if (res.data.success) {
					message.success(res.data.message);
					setTimeout(() => {
						navigate(-1);
					}, 1000);
				} else {
					message.info(res.data.message);
				}
			})
			.catch((err) => {
				setLoader(false);
				message.error(err.message);
			});
	};
	// Function To Decline Loan Requests
	const declineLoans = (e) => {
		e.preventDefault();
		setLoader(true);
		axios
			.post(
				'https://test.solarcredit.io/v0.1/api/admin/declineLoan',
				{
					apptoken: app_token,
					usertoken: user?.usertoken,
					loantoken: loanDetails?.loantoken,
					message: reason,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			)
			.then((res) => {
				setLoader(false);
				if (res.data.success) {
					setShow(false);
					message.success(res.data.message);
					setTimeout(() => {
						navigate(-1);
					}, 1000);
				} else {
					message.info(res.data.message);
				}
			})
			.catch((err) => {
				setLoader(false);
				message.error(err.message);
			});
	};

	useEffect(() => {
		// Fetch additional loan details when the component mounts
		if (user && user.loantoken) {
			fetchLoanDetails(user.loantoken);
		}
	}, [user]);

	// Function to fetch additional loan details
	const fetchLoanDetails = (loantoken) => {
		// Adjust the API endpoint and headers as needed
		axios
			.post(
				'https://test.solarcredit.io/v0.1/api/admin/getLoanByToken',
				{
					loantoken: loantoken,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			)
			.then((res) => {
				if (res.data.success) {
					setLoanDetails(res.data.data[0]);
				} else {
					message.info(res.data.message);
				}
			})
			.catch((err) => {
				message.error(err.message);
			});
	};

	return (
		<div>
			<AdminWrapper>
				<DashNav title='Loan Details' />

				<div className='user'>
					<div className='userTitleContainer fd-flex align-items-center justify-content-between'>
						<Link to={-1}>
							<AiOutlineArrowLeft className='edituserIcon' />
						</Link>
					</div>

					<div className='userContainer'>
						<div className='userShow'>
							<div className='userShowBottom'>
								<span className='userShowTitle'>Colleteral Details</span>
								<div className='userShowInfo  loanShowInfo'>
									<span className='userShowInfoTitle'>
										Name: {loanDetails?.getAllLoanColleterals?.catname}
									</span>
								</div>
								<div className='userShowInfo  loanShowInfo'>
									<span className='userShowInfoTitle'>
										Price bought:{' '}
										{loanDetails?.getAllLoanColleterals?.price_bought}
									</span>
								</div>
								<div className='userShowInfo  loanShowInfo'>
									<span className='userShowInfoTitle'>
										Product category:{' '}
										{user?.getAllLoanColleterals?.catname || 'Not specified'}
									</span>
								</div>
								<div className='userShowInfo  loanShowInfo'>
									<span className='userShowInfoTitle'>
										Watt: {loanDetails?.getAllLoanColleterals?.watt}
									</span>
								</div>
								<div className='userShowInfo  loanShowInfo'>
									<span className='userShowInfoTitle'>
										Years of usage:{' '}
										{loanDetails?.getAllLoanColleterals?.years_of_usage}
									</span>
								</div>
								<div className='userShowInfo  loanShowInfo'>
									<span className='userShowInfoTitle'>
										Proof of ownership: <br />
										<img
											className='proofImg'
											src={
												loanDetails?.getAllLoanColleterals?.proof_of_ownership
											}
											alt='proof_of_ownership'
										/>
									</span>
								</div>
								<span className='userShowTitle mt-3'>Account Details</span>
								<div className='userShowInfo loanShowInfo'>
									<span className='userShowInfoTitle'>
										Full name: {loanDetails?.fullname}
									</span>
								</div>
								<div className='userShowInfo loanShowInfo'>
									<span className='userShowInfoTitle'>
										Occupation: {loanDetails?.occupation}
									</span>
								</div>
								<div className='userShowInfo loanShowInfo'>
									<span className='userShowInfoTitle'>
										Means of identity: {loanDetails?.means_of_identity}
									</span>
								</div>
								<div className='userShowInfo loanShowInfo'>
									<span className='userShowInfoTitle'>
										Identity number: {loanDetails?.identity_number}
									</span>
								</div>
								<div className='userShowInfo loanShowInfo'>
									<span className='userShowInfoTitle'>
										Usertoken: {loanDetails?.usertoken}
									</span>
								</div>
								<div className='userShowInfo  loanShowInfo'>
									<span className='userShowInfoTitle'>
										Passport photo: <br />
										<img
											className='proofImg'
											src={loanDetails?.passport_photo}
											alt='passport'
										/>
									</span>
								</div>

								<div>
									<span className='userShowTitle'>Guarantor Details 1</span>
									<div className='userShowInfo loanShowInfo'>
										<span className='userShowInfoTitle'>
											Full name:{' '}
											{loanDetails.getAllLoanGuarantors?.[0]?.guarantorName}
										</span>
									</div>
									<div className='userShowInfo loanShowInfo'>
										<span className='userShowInfoTitle'>
											Email:{' '}
											{loanDetails.getAllLoanGuarantors?.[0]?.guarantorEmail}
										</span>
									</div>
									<div className='userShowInfo loanShowInfo'>
										<span className='userShowInfoTitle'>
											Phone:{' '}
											{loanDetails.getAllLoanGuarantors?.[0]?.guarantorPhone}
										</span>
									</div>
									<div className='userShowInfo loanShowInfo'>
										<span className='userShowInfoTitle'>
											Relationship:{' '}
											{
												loanDetails.getAllLoanGuarantors?.[0]
													?.guarantorRelationship
											}
										</span>
									</div>
								</div>
								<div>
									<span className='userShowTitle'>Guarantor Details 2</span>
									<div className='userShowInfo loanShowInfo'>
										<span className='userShowInfoTitle'>
											Full name:{' '}
											{loanDetails.getAllLoanGuarantors?.[1]?.guarantorName}
										</span>
									</div>
									<div className='userShowInfo loanShowInfo'>
										<span className='userShowInfoTitle'>
											Email:{' '}
											{loanDetails.getAllLoanGuarantors?.[1]?.guarantorEmail}
										</span>
									</div>
									<div className='userShowInfo loanShowInfo'>
										<span className='userShowInfoTitle'>
											Phone:{' '}
											{loanDetails.getAllLoanGuarantors?.[1]?.guarantorPhone}
										</span>
									</div>
									<div className='userShowInfo loanShowInfo'>
										<span className='userShowInfoTitle'>
											Relationship:{' '}
											{
												loanDetails.getAllLoanGuarantors?.[1]
													?.guarantorRelationship
											}
										</span>
									</div>
								</div>
								<div className='mt-3'>
									<span className='userShowTitle'>Loan Details</span>
									<div className='userShowInfo loanShowInfo'>
										<span className='userShowInfoTitle'>
											Amount to borrow: {loanDetails?.amountToBorrow}
										</span>
									</div>
									<div className='userShowInfo loanShowInfo'>
										<span className='userShowInfoTitle'>
											Amount Debited: {loanDetails?.amount_debited_so_far}
										</span>
									</div>
									<div className='userShowInfo loanShowInfo'>
										<span className='userShowInfoTitle'>
											Date Placed: {loanDetails?.date}
										</span>
									</div>
									<div className='userShowInfo loanShowInfo'>
										<span className='userShowInfoTitle'>
											Loan Status: {loanDetails?.loanStatus}
										</span>
									</div>
									<div className='userShowInfo loanShowInfo'>
										<span className='userShowInfoTitle'>
											Package plan: {loanDetails?.packagePlan}
										</span>
									</div>
									<div className='userShowInfo loanShowInfo'>
										<span className='userShowInfoTitle'>
											Purpose of loan: {loanDetails?.purpose_of_loan}
										</span>
									</div>
									<div className='userShowInfo loanShowInfo'>
										<span className='userShowInfoTitle'>
											Completed Status: {loanDetails?.isCompletedStatus}
										</span>
									</div>
								</div>
							</div>
						</div>

						<div className='userUpdate'>
							<div className='userShowBottom'>
								{loanDetails.getAllRecordsOfMonthExpectedToPay &&
									loanDetails.getAllRecordsOfMonthExpectedToPay.length > 0 &&
									loanDetails.getAllRecordsOfMonthExpectedToPay.map(
										(month, index) => {
											return (
												<div className='mt-3' key={index}>
													<span className='userShowTitle'>
														Month {index + 1} Repayment Details
													</span>
													<div className='userShowInfo loanShowInfo'>
														<span className='userShowInfoTitle'>
															Date scheduled to pay: {month.dueDate}
														</span>
													</div>
													<div className='userShowInfo loanShowInfo'>
														<span className='userShowInfoTitle'>
															Payment History: {month.historyData}
														</span>
													</div>
													<div className='userShowInfo loanShowInfo'>
														<span className='userShowInfoTitle'>
															Payment Status: {month.paymentStatus}
														</span>
													</div>
													<div className='userShowInfo loanShowInfo'>
														<span className='userShowInfoTitle'>
															Penalty Fee: {month.penaltyFee}
														</span>
													</div>
													<div className='userShowInfo loanShowInfo'>
														<span className='userShowInfoTitle'>
															Price Expected To Pay:{' '}
															{month.priceExpectedToPay_thousand}
														</span>
													</div>
													<span className='userShowTitle'>
														Month {index + 1} Notification Status
													</span>
													<div className='userShowInfo loanShowInfo'>
														<span className='userShowInfoTitle'>
															A day before status:{' '}
															{month.remind_a_day_before_status}
														</span>
													</div>
													<div className='userShowInfo loanShowInfo'>
														<span className='userShowInfoTitle'>
															A week before status:{' '}
															{month.remind_a_week_before_status}
														</span>
													</div>
													<div className='userShowInfo loanShowInfo'>
														<span className='userShowInfoTitle'>
															2 days before status:{' '}
															{month?.remind_2days_before_status}
														</span>
													</div>
												</div>
											);
										}
									)}
							</div>

							<div className='userUpdateForm w-100 '>
								<div className='userUpdateRight d-flex align-items-center'>
									<button
										className='userUpdateButton'
										onClick={() => approveLoans()}>
										{loader ? (
											<Spinner
												animation='border'
												role='status'
												size='sm'
												className='store-spinner editspinner'
												style={{ color: '#FEC52A' }}>
												<span className='visually-hidden'>Loading...</span>
											</Spinner>
										) : (
											<>Approve Loan</>
										)}
									</button>
									<button
										onClick={() => setShow(true)}
										className='userUpdateButton'
										style={{ backgroundColor: '#ff3e3e', marginLeft: '10px' }}>
										Decline Loan
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Modal
					className='categoryModal'
					show={show}
					onHide={() => setShow(false)}
					centered
					keyboard={false}>
					<Modal.Body>
						<div className='servicemodalcase'>
							<>
								<form
									className='declineForm d-flex flex-column align-items-center justify-content-center'
									onSubmit={declineLoans}>
									<textarea
										onChange={(e) => setReason(e.target.value)}
										placeholder='Reason For Loan Decline'
										id=''
										cols='30'
										rows='10'
										required></textarea>
									<button>
										{loader ? (
											<Spinner animation='border' size='sm' />
										) : (
											'Decline Loan'
										)}
									</button>
								</form>
							</>
						</div>
					</Modal.Body>
				</Modal>
			</AdminWrapper>
		</div>
	);
}

export default ViewLoan;
