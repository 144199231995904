import React, { useEffect, useState } from "react";
import cloud from "../../../../icons/cloud1.png";
import naira from "../../../../icons/naira.png";
import rain from "../../../../icons/rainfall.png";
import hydrometer from "../../../../icons/humidity.png";
import dry from "../../../../icons/moist.png";
import degrees from "../../../../icons/degrees.svg";
import customer from "../../../../icons/customer.png";
import settings from "../../../../icons/settings.png";
import loan from "../../../../icons/loan.png";
import notification from "../../../../icons/notification.png";
import calculaor from "../../../../icons/calculator.png";
import solarstore from "../../../../icons/solar-store.png";
import DashNav from "../../userComponents/dashNav/DashNav";
import Wrapper from "../../userComponents/userWrapper/Wrapper";
import { message, Table, Tag } from "antd";

import "./dashboard.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import TawkToChat from '../../../../util/TawkToChat'
const Dashboard = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const weatherdata = JSON.parse(localStorage.getItem("weatherData"));
  const [transactHistory, setTransactHistory] = useState([]);
  const [time, setTime] = useState({});
  const [weatherData, setWeatherData] = useState(
    weatherdata ? weatherdata : null
  );
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  var today = new Date();
  const currentDate = new Date();

  const options = { weekday: "long", day: "numeric", month: "long" };
  const formattedDate = currentDate.toLocaleDateString("en-US", options);

  const columns = [
    {
      title: "Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },

    {
      title: "Type",
      key: "paymentType",
      dataIndex: "paymentType",
      render: (tag) => (
        <Tag color={tag === "Credit" ? "green" : "volcano"} key={tag}>
          {tag.toUpperCase()}
        </Tag>
      ),
    },
  ];

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      message.info("Geolocation is not supported by this browser.");
    }
  }

  function showPosition(position) {
    getWeather(position.coords.latitude, position.coords.longitude);
  }

  const getUserData = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/getUserdata`,
        {
          apptoken:
            "vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh",
          usertoken: userData.usertoken,
        },
        {
          headers: { Authorization: `Bearer 6455ef91d108f` },
        }
      );

      if (res.data.success) {
        localStorage.setItem("userData", JSON.stringify(res.data.data));
      } else {
        message.info(res.data.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  function getlatestDate() {
    setTime({
      minutes:
        `${today.getMinutes()}`.length <= 2
          ? today.getMinutes()
          : `0${today.getMinutes()}`,
      hour: today.getHours(),
      period: today.getHours() < 12 ? "AM" : "PM",
    });
  }

  const getWeather = async (lat, lon) => {
    try {
      const res = await axios.get(
        `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=aa78deded5e872b307bc35daadcd29bf`
      );
      setWeatherData(res.data);
      localStorage.setItem("weatherData", JSON.stringify(res.data));
    } catch (error) {
      message.error(error.message);
    }
  };

  const getTransHistory = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/fetchWalletHistory`,
        {
          apptoken:
            "vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh",
          usertoken: userData.usertoken,
        },
        {
          headers: { Authorization: `Bearer 6455ef91d108f` },
        }
      );
      if (res.data.success) {
        setTransactHistory(res.data.data);
      } else {
        message.info(res.data.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const customerService = async () => {
    var Tawk_API = Tawk_API || {};
    window.Tawk_API.toggle();
    if (window.innerWidth <= 1000) {
      document.getElementById("mySidenav").style.width = "0";
    }
  };

  useEffect(() => {
    getTransHistory();
    getUserData();
    getLocation();
  }, []);

  useEffect(() => {
    const interval = setInterval(getlatestDate, 1000);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <Wrapper>
        <DashNav
          title="Overview"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={"Search e.g solar panel"}
        />
        <div className="overview">
          <h2>Hi {userData.fname.split(" ")[1]},</h2>
          <h1>Welcome Back</h1>
          <div className="overview-flex d-flex justify-content-between align-items-center mt-5">
            <div className="overview-flex-left">
              <h4>Weather Forecast: {weatherData?.name}</h4>
              <div className="overview-weather-card  shadow d-flex justify-content-between  align-items-center mt-3">
                <div className="overview-weather-card-left h-100 d-flex flex-column justify-content-between">
                  <div className="overview-weather-card-date">
                    {formattedDate}
                  </div>
                  <div className="overview-weather-data d-flex align-items-center">
                    <img src={cloud} alt="" />
                    <div className="overview-weather-data-flex d-flex align-items-center">
                      <div className="overview-weather-data-item d-flex align-items-center flex-column justify-content-center">
                        <img src={hydrometer} alt="" />
                        <h5>Humidity</h5>
                        <span>{weatherData?.main?.humidity}%</span>
                      </div>
                      <div className="overview-weather-data-item d-flex align-items-center flex-column">
                        <img src={rain} alt="" />
                        <h5>Wind</h5>
                        <span>{weatherData?.wind?.speed}%</span>
                      </div>
                      <div className="overview-weather-data-item d-flex align-items-center flex-column">
                        <img src={dry} alt="" />
                        <h5>Pressure</h5>
                        <span>{weatherData?.main?.pressure}%</span>
                      </div>
                    </div>
                  </div>
                  <div className="overview-weather-desc">
                    {weatherData?.weather[0]?.description}
                  </div>
                </div>
                <div className="overview-weather-card-right h-100 d-flex align-items-end flex-column justify-content-between ">
                  <div className="overview-celsius">
                    <h1>
                      {Math.round(weatherData?.main.temp - 273.15)
                        ? Math.round(weatherData?.main.temp - 273.15)
                        : 0}
                    </h1>
                    <img src={degrees} alt="" />
                  </div>
                  <div className="overview-weather-card-time shadow-sm">
                    {time?.hour}:{time?.minutes} {time?.period}
                  </div>
                </div>
              </div>
            </div>

            <div className="overview-flex-right ">
              <h4>Payment</h4>
              <div className="payment-card mt-3 d-flex flex-column justify-content-between align-items-center shadow">
                <div className="payment-card-top  d-flex justify-content-between align-items-center">
                  <h4>Outstanding Wallet</h4>
                </div>
                <div className="payment-card-middle d-flex flex-column justify-content-between align-items-start">
                  <div>
                    <div className="payment-price-case d-flex justify-content-start align-items-center">
                      <img src={naira} alt="" />
                      <span>{userData?.getAllOutstandingFee}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="overview-details-flex d-flex justify-content-between">
            <div className="overview-details-flex-left ">
              <div className="overview-details-flex-inner w-100 d-flex align-items-center justify-content-between">
                <div
                  onClick={() => navigate("/user/loadaudit")}
                  className="overview-details-flex-box d-flex flex-column align-items-start justify-content-between  shadow"
                >
                  <h4>Load Audit</h4>
                  <img src={calculaor} alt="" />
                  <div className="overview-details-flex-footer align-items-center w-100 d-flex justify-content-between">
                    <p>
                      Calculate your appliances load and get the best
                      recommendation
                    </p>
                    <span>i</span>
                  </div>
                </div>

                <div
                  onClick={() => navigate("/user/solarstore")}
                  className="overview-details-flex-box d-flex flex-column align-items-start justify-content-between  shadow"
                >
                  <h4>Solar Shop</h4>
                  <img src={solarstore} alt="" />
                  <div className="overview-details-flex-footer align-items-center w-100 d-flex justify-content-between">
                    <p>Check out new products all for you...</p>
                  </div>
                </div>
              </div>
              <div className="overview-details-flex-inner w-100 d-flex align-items-center justify-content-between">
                <div
                  onClick={() => navigate("/user/loan")}
                  className="overview-details-flex-box d-flex flex-column align-items-start justify-content-between  shadow"
                >
                  <h4>Need a loan?</h4>
                  <img src={loan} alt="" />
                  <div className="overview-details-flex-footer align-items-center w-100 d-flex justify-content-between">
                    <p>
                      Get a loan from us using your solar gadgets as collateral
                    </p>
                  </div>
                </div>

                <div
                  onClick={() => customerService()}
                  className="overview-details-flex-box d-flex flex-column align-items-start justify-content-between  shadow"
                >
                  <h4>Customer Service</h4>
                  <img src={customer} alt="" />
                  <div className="overview-details-flex-footer align-items-center w-100 d-flex justify-content-between">
                    <p>Want to drop a message or complaint.</p>
                  </div>
                </div>
              </div>
              <div className="overview-details-flex-inner w-100 d-flex align-items-center justify-content-between">
                <div
                  onClick={() => navigate("/user/notifications")}
                  className="overview-details-flex-box d-flex flex-column align-items-start justify-content-between  shadow"
                >
                  <h4>Notification</h4>
                  <img src={notification} alt="" />
                  <div className="overview-details-flex-footer align-items-center w-100 d-flex justify-content-between">
                    <p>You have a notification !</p>
                  </div>
                </div>
                <div
                  onClick={() => navigate("/user/settings")}
                  className="overview-details-flex-box d-flex flex-column align-items-start justify-content-between  shadow"
                >
                  <h4>Settings</h4>
                  <img src={settings} alt="" />
                  <div className="overview-details-flex-footer align-items-center w-100 d-flex justify-content-between">
                    <p>Customise your settings !</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="overview-details-flex-right">
              Transactions
              <Table
                key={transactHistory.length}
                columns={columns}
                pagination={{
                  defaultPageSize: 8,
                  total: transactHistory.length,
                  pageSize: 8,
                }}
                dataSource={transactHistory}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default Dashboard;
