import { useState, useEffect } from 'react';
import { Modal, Result, Button, message, Input } from 'antd';
import DashNav from '../../userComponents/dashNav/DashNav';
import Wrapper from '../../userComponents/userWrapper/Wrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePaystackPayment } from 'react-paystack';
import { Spinner } from 'react-bootstrap';
import logo from '../../../../images/logo.png';
import axios from 'axios';
import './installmental.css';
function Installmental() {
	const location = useLocation().state;
	//
	const userData = JSON.parse(localStorage.getItem('userData'));
	const app_token =
		'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';
	const navigate = useNavigate();
	//state to handle loan plans
	const [plan, setPlan] = useState('');
	const [planData, setPlanData] = useState([]);
	const [check, setCheck] = useState(false);
	// KYC Modal Display State
	const [kycModal, setKycModal] = useState(
		userData?.kycStatus === false ? true : false
	);
	// Loan Modal Display State
	const [loanModal, setLoanModal] = useState(
		userData?.isInstallmentOngoing === false ? false : true
	);

	const [payLoader, setPayLoader] = useState(false);
	const [walletLoader, setWalletLoader] = useState(false);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [isConfirm, setIsConfirm] = useState(false);
	const [otp, setOtp] = useState('');
	const [agree, setAgree] = useState('');

	const handleOtpChange = (e) => {
		setOtp(e.target.value);
	};

	const showModal = () => {
		if (!plan) {
			message.info('Please select a plan');
		} else {
			setIsModalOpen(true);
		}
	};

	const handleOk = () => {
		setIsModalOpen(false);
		setIsConfirm(false);
		setIsSuccess(false);
	};

	// Function to fetch all plans
	const fetchPlan = () => {
		axios
			.post(
				`${process.env.REACT_APP_BASE_URL}/admin/fetchPlan`,
				{
					apptoken: app_token,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			)
			.then((res) => {
				if (res.data.success) {
					setPlanData(res.data.data);
				} else {
					message.info(res.data.message);
				}
			})
			.catch((err) => {
				fetchPlan();
				setPlanData(planData);
			});
	};

	//Function that gets user data
	const getUserData = async () => {
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/getUserdata`,
				{
					apptoken:
						'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh',
					usertoken: userData.usertoken,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);

			if (res.data.success) {
				localStorage.setItem('userData', JSON.stringify(res.data.data));
			} else {
				message.info(res.data.message);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	// Function to handle Taking Loan
	const handlePay = async (payload) => {
		setPayLoader(true);
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/p/payIntsallmentallyBank`,
				{
					apptoken: app_token,
					usertoken: userData.usertoken,
					productBought: getOptimalArray(location.product),
					reference: payload.reference,
					Totalprice: location.amount,
					amountPaid: location.amount / 2,
					package: plan,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
			setPayLoader(false);
			setIsModalOpen(false);
			if (res.data.success) {
				message.success(res.data.message);
				setTimeout(() => {
					navigate('/user/orders');
				}, 1000);
			} else {
				message.warning(res.data.message);
			}
		} catch (error) {
			setPayLoader(false);
			message.error(error.message);
		}
	};

	const walletPayment = async () => {
		if (+userData.availableBalance >= +location.amount / 2) {
			setWalletLoader(true);
			try {
				const res = await axios.post(
					`${process.env.REACT_APP_BASE_URL}/p/payInstallmentallyWallet`,
					{
						apptoken: app_token,
						usertoken: userData.usertoken,
						productBought: getOptimalArray(location.product),
						Totalprice: location.amount,
						amountPaid: location.amount / 2,
						package: plan,
					},
					{
						headers: { Authorization: `Bearer 6455ef91d108f` },
					}
				);
				getUserData();
				setWalletLoader(false);
				setIsModalOpen(false);
				if (res.data.success) {
					message.success(res.data.message);
					setTimeout(() => {
						navigate('/user/orders');
					}, 1000);
				} else {
					message.info(res.data.message);
				}
			} catch (error) {
				setIsModalOpen(false);
				getUserData();
				setWalletLoader(false);
				message.error(error.message);
			}
		} else {
			message.error('Insufficient balance! Please fund your wallet!');
		}
	};

	// UseEffects

	useEffect(() => {
		getUserData();
		fetchPlan();
	}, []);

	function getOptimalArray(array) {
		let resultArray = [];
		for (let i = 0; i < array.length; i++) {
			resultArray.push({
				productToken: array[i].productToken,
				productQuantity: array[i].productQuantity,
				productPrice: array[i].productPrice,
				productType: array[i].productType,
				productname: array[i].productname,
				productimage: array[i].productImage,
				ownertoken: array[i].ownertoken,
			});
		}
		return resultArray;
	}

	// console.log(location.amount);
	// console.log(amount);

	const onSuccess = (reference) => {
		// Implementation for whatever you want to do with reference and after success call.
		if (reference.status === 'success') {
			return handlePay(reference);
		} else {
			return handlePay(reference);
		}
	};

	// you can call this function anything
	const onClose = () => {
		// implementation for  whatever you want to do when the Paystack dialog closed.
		message.info('Payment Cancelled');
	};

	const initializePayment = usePaystackPayment({
		reference: new Date().getTime().toString(),
		email: userData.mail,
		amount: (location?.amount / 2) * 100,
		publicKey: process.env.REACT_APP_PAYSTACK_KEY,
	});

	// console.log(check);

	const ReniPayment = async () => {
		setWalletLoader(true);
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/payment/installment/requestFund`,
				{
					apptoken: app_token,
					usertoken: userData.usertoken,
					Totalprice: location.amount,
					halfpayment: location.amount / 2,
					package: plan,
					agreed: 1,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
			getUserData();
			setIsModalOpen(false);
			setWalletLoader(false);
			console.log('success');
			setAgree(res.data.agreementtoken);
			setIsConfirm(true);
		} catch (error) {
			setIsModalOpen(false);
			getUserData();
			setWalletLoader(false);
			message.error(error.message);
		}
	};

	// console.log(agree);

	const ConfirmPayment = async () => {
		setWalletLoader(true);
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/payment/installment/approveFunds`,
				{
					apptoken: app_token,
					usertoken: userData.usertoken,
					// renitoken: userData.renitoken,
					productBought: getOptimalArray(location.product),
					Totalprice: location.amount,
					amountPaid: location.amount / 2,
					package: plan,
					otp,
					agreementtoken: agree,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
			getUserData();
			setIsModalOpen(false);
			setWalletLoader(false);
			message.success(res.data.message);
			setIsConfirm(false);
			setIsSuccess(true);
		} catch (error) {
			setIsModalOpen(false);
			getUserData();
			setWalletLoader(false);
			message.error('Please input you OTP');
		}
	};

	return (
		<div className='loan-container-main'>
			<Wrapper>
				<DashNav title='Pay Installmentally' />

				<div className='loan-case'>
					{planData?.map((item) => {
						return (
							<div className='loan-item' key={item.plan}>
								<div className='load-item-head'>
									<span>{item.plan}</span>
									<div
										onClick={() => setPlan(item.planid)}
										className={`loan-item-tick ${
											item.planid === plan ? 'loan-item-tick-active' : ''
										}`}></div>
								</div>
								<div className='loan-item-main'>
									<h3>{item.plan}</h3>
									<p>Exclusive repayment plan</p>
								</div>
								<div className='loan-item-footer'>
									<div className='loan-item-footer-inner'>
										<div className='loan-item-footer-1'></div>
										<div className='loan-item-footer-2'></div>
									</div>
								</div>
							</div>
						);
					})}
				</div>

				<div className='loanform-master'>
					<h2>Kindly note that you'll be making a 50% deposit payment.</h2>
					<div className='loanform-case'>
						<div className='loanform-button'>
							<input
								type='checkbox'
								onChange={(e) => setCheck(e.target.value)}
							/>{' '}
							i agree to all loan terms and conditions
							<button
								onClick={
									check
										? () => showModal()
										: () =>
												message.info(
													'Please accept terms and conditions to  proceed!'
												)
								}>
								{'Pay'}
							</button>
						</div>
					</div>
				</div>

				<Modal
					open={isModalOpen}
					onOk={handleOk}
					footer={null}
					onCancel={handleOk}
					centered>
					<div className='ordersummary-container'>
						{/* <h3>Order Summary</h3> */}
						<div className='summary-imgcase d-flex justify-content-center align-items-center'>
							<img src={logo} alt='' loading='lazy' />
						</div>
						<h3>Order Summary</h3>
						<div className='summary-detailscase mt-3'>
							{/* <p style={{ justifyContent: 'flex-end', fontWeight: ' bold' }}><h3>Order Summary</h3></p> */}
							<p>
								Price:<span>₦ {location.amount} </span>
							</p>
							<p>
								Watts:<span> 1000W</span>
							</p>
							<p>
								Size:<span>NAN</span>
							</p>
							<p>
								Delivery Fee:<span>₦ 0</span>
							</p>
							<p>
								Quantity: <span>1</span>
							</p>
							<hr />
							<p>
								Cost:<span>₦ {location?.amount / 2}</span>
							</p>
							<hr />
							<p className='summarytotal'>
								Total: <span>₦ {location?.amount / 2} </span>
							</p>
						</div>
						<div className='order-btncase'>
							<button className='orderbtn1' onClick={() => ReniPayment()}>
								{walletLoader ? (
									<Spinner
										animation='border'
										role='status'
										size='sm'
										className='store-spinner walletpayment-loader'>
										<span className='visually-hidden'>Loading...</span>
									</Spinner>
								) : (
									'Pay with Reni'
								)}
							</button>
							{/* <button
								className='orderbtn2'
								onClick={() => initializePayment(onSuccess, onClose)}>
								{payLoader ? (
									<Spinner
										animation='border'
										role='status'
										size='sm'
										className='store-spinner payment-loader'>
										<span className='visually-hidden'>Loading...</span>
									</Spinner>
								) : (
									'Payment with Card'
								)}
							</button> */}
						</div>
					</div>
				</Modal>

				<Modal
					open={isSuccess}
					onOk={handleOk}
					footer={null}
					onCancel={handleOk}
					centered>
					<Result
						status='success'
						title='Successfully Purchased!'
						subTitle='Check your mail for an order recipt'
						extra={[
							<Button key='console' onClick={() => navigate('/user/orders')}>
								View Orders
							</Button>,
						]}
					/>
				</Modal>

				{/* complete payment */}
				<Modal
					open={isConfirm}
					onOk={handleOk}
					footer={null}
					onCancel={handleOk}
					centered>
					<Result
						status='success'
						title='Complete Payment!'
						subTitle='Check your mail for your OTP'
						extra={[
							<div style={{ marginBottom: '1rem' }}>
								<Input
									placeholder='OTP'
									style={{ width: '40%' }}
									value={otp}
									onChange={handleOtpChange}
								/>
							</div>,
							<Button key='console' onClick={() => ConfirmPayment()}>
								{walletLoader ? (
									<Spinner
										animation='border'
										role='status'
										size='sm'
										className='store-spinner walletpayment-loader'>
										<span className='visually-hidden'>Loading...</span>
									</Spinner>
								) : (
									'Complete Payment'
								)}
							</Button>,
						]}
					/>
				</Modal>

				<div className='kyc-notification-modal'>
					<Modal
						closable={false}
						visible={kycModal}
						onOk={() => setKycModal(false)}
						onCancel={() => setKycModal(false)}
						footer={null}
						wrapClassName='kyc-notification-modal'
						maskClosable={false}>
						<Result
							status='warning'
							title="Can't access this resource without completing KYC Verification."
							extra={[
								<Button
									key='completeKycBtn'
									onClick={() =>
										navigate('/user/kyc', { state: { kyc: true } })
									}
									type='primary'>
									Complete KYC Verification
								</Button>,
								<Button
									key='closeModalBtn'
									onClick={() => setKycModal(false)}
									type='default'
									style={{ position: 'absolute', top: '10px', left: '10px' }}>
									Close
								</Button>,
							]}
						/>
					</Modal>
					<Modal
						closable={false}
						visible={loanModal}
						onOk={() => setLoanModal(false)}
						onCancel={() => setLoanModal(false)}
						footer={null}
						wrapClassName='kyc-notification-modal'
						maskClosable={false}>
						<Result
							status='warning'
							title="Can't access this resource with outstanding installments."
							// extra={
							//     <Button onClick={() => navigate('/user/kyc', { state: { kyc: true } })} type="primary" key="console">
							//         Complete KYC Verification
							//     </Button>
							// }
						/>
					</Modal>
				</div>
			</Wrapper>
		</div>
	);
}

export default Installmental;
