import React from 'react'
import UsersSideNav from '../UsersNavbar/UsersSideNav';
import "../UsersNavbar/Nav.css";

const Wrapper = ({ children }) => {
  return (
    <div>
      <UsersSideNav />
      <div className="dashboard">
        <div className="container">{children}</div>
      </div>
    </div>
  )
}

export default Wrapper