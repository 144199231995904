import './paystack.css';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { message, Modal, Button, Result, Input, Space } from 'antd';
import { usePaystackPayment } from 'react-paystack';
import logo from '../images/logo.png';
const URL = process.env.REACT_APP_BASE_URL;
const APP_TOKEN =
	'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';
const userData = JSON.parse(localStorage.getItem('userData'));

export const PaystackHookExample = ({ text, amount, email, type, data }) => {
	const [payLoader, setPayLoader] = useState(false);
	const [walletLoader, setWalletLoader] = useState(false);

	const productData = useLocation().state;
	const navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalOpen1, setIsModalOpen1] = useState(false);
	const [isModalOpen2, setIsModalOpen2] = useState(false);
	const [otp, setOtp] = useState('');

	const handleOtpChange = (e) => {
		setOtp(e.target.value);
	};

	let currentLog;
	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
		setIsModalOpen1(false);
		setIsModalOpen2(false);
	};

	const onSuccess = (reference) => {
		// Implementation for whatever you want to do with reference and after success call.
		if (reference.status === 'success') {
			return handleSuccess(reference);
		} else {
			return handleSuccess(reference);
		}
	};

	const getUserData = async () => {
		try {
			const res = await axios.post(
				'https://test.solarcredit.io/v0.1/api/getUserdata',
				{
					apptoken:
						'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh',
					usertoken: userData.usertoken,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);

			if (res.data.success) {
				localStorage.setItem('userData', JSON.stringify(res.data.data));
			} else {
				message.info(res.data.message);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	//Function to call after sucessfull purchase
	const handleSuccess = async (payload) => {
		if (type === 'cart') {
			setPayLoader(true);
			try {
				const res = await axios.post(
					`${URL}/p/payWithBank`,
					{
						apptoken: APP_TOKEN,
						usertoken: userData.usertoken,
						reference: payload.reference,
						productBought: getOptimalArray(data),
						Totalprice: amount,
					},
					{
						headers: { Authorization: `Bearer 6455ef91d108f` },
					}
				);
				currentLog = 'Bought Goods from the cart With card Payment';
				getLocation();
				getUserData();
				setPayLoader(false);
				setIsModalOpen(false);
				if (res.data.success) {
					setIsModalOpen1(true);
				} else {
					message.info(res.data.message);
				}
			} catch (error) {
				setIsModalOpen(false);
				getUserData();
				setPayLoader(false);
				message.error(error.message);
			}
		} else {
			setPayLoader(true);
			try {
				const res = await axios.post(
					`${URL}/p/payWithBank`,
					{
						apptoken: APP_TOKEN,
						usertoken: userData.usertoken,
						reference: payload.reference,
						productBought: getOptimalArray([productData]),
						Totalprice: productData.productPrice,
					},
					{
						headers: { Authorization: `Bearer 6455ef91d108f` },
					}
				);
				currentLog = 'Bought one product from With card Payment';
				getLocation();
				getUserData();
				setPayLoader(false);
				setIsModalOpen(false);
				if (res.data.success) {
					setIsModalOpen1(true);
				} else {
					message.info(res.data.message);
				}
			} catch (error) {
				setIsModalOpen(false);
				getUserData();
				setPayLoader(false);
				message.error(error.message);
			}
		}
	};

	function getOptimalArray(array) {
		let resultArray = [];
		for (let i = 0; i < array.length; i++) {
			resultArray.push({
				productToken: array[i].productToken,
				productQuantity: array[i].productQuantity,
				productPrice: array[i].productPrice,
				productType: array[i].productType,
				productname: array[i].productname,
				productimage: array[i].productImage,
				ownertoken: array[i].ownertoken,
			});
		}
		return resultArray;
	}

	// console.log(getOptimalArray(data));

	const ReniPayment = async () => {
		setWalletLoader(true);
		try {
			const res = await axios.post(
				`${URL}/payment/ReniPayment`,
				{
					apptoken: APP_TOKEN,
					usertoken: userData.usertoken,
					Totalprice:
						type.toString() === 'cart' ? amount : productData.productPrice,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
			getLocation();
			getUserData();
			setIsModalOpen(false);
			setWalletLoader(false);
			if (res.data.success) {
				console.log('success');
				console.log(res.data);
				setIsModalOpen2(true);
			} else {
				message.info(res.data.message);
			}
		} catch (error) {
			setIsModalOpen(false);
			getUserData();
			setWalletLoader(false);
			message.error(error.message);
		}
	};

	const ConfirmPayment = async () => {
		setWalletLoader(true);
		try {
			const res = await axios.post(
				`${URL}/payment/approveFund`,
				{
					apptoken: APP_TOKEN,
					usertoken: userData.usertoken,
					renitoken: userData.renitoken,
					products: getOptimalArray(data),
					otp: otp,
					Totalprice:
						type.toString() === 'cart' ? amount : productData.productPrice,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
			getLocation();
			getUserData();
			setIsModalOpen(false);
			setWalletLoader(false);
			if (res.data.success) {
				message.success(res.data.message);
				setIsModalOpen2(false);
				setIsModalOpen1(true);
				// console.log(res.data);
			} else {
				message.info(res.data.message);
			}
		} catch (error) {
			setIsModalOpen(false);
			getUserData();
			setWalletLoader(false);
			message.error('Please input you OTP');
		}
	};

	const walletPayment = async () => {
		if (parseInt(userData.availableBalance) >= parseInt(amount)) {
			setWalletLoader(true);
			try {
				const res = await axios.post(
					`${URL}/p/buyFromWallet`,
					{
						apptoken: APP_TOKEN,
						usertoken: userData.usertoken,
						products: getOptimalArray(
							type.toString() === 'cart' ? [...data] : [productData]
						),
						Totalprice:
							type.toString() === 'cart' ? amount : productData.productPrice,
					},
					{
						headers: { Authorization: `Bearer 6455ef91d108f` },
					}
				);
				currentLog = 'Bought Goods With Wallet Payment';
				getLocation();
				getUserData();
				setIsModalOpen(false);
				setWalletLoader(false);
				if (res.data.success) {
					setIsModalOpen1(true);
				} else {
					message.info(res.data.message);
				}
			} catch (error) {
				setIsModalOpen(false);
				getUserData();
				setWalletLoader(false);
				message.error(error.message);
			}
		} else {
			message.error('Please fund your wallet!');
		}
	};

	// you can call this function anything
	const onClose = () => {
		// implementation for  whatever you want to do when the Paystack dialog closed.
		message.info('Payment Cancelled');
	};

	function getLocation() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(showPosition);
		} else {
			message.info('Geolocation is not supported by this browser.');
		}
	}

	function showPosition(position) {
		logActivity(position.coords.latitude, position.coords.longitude);
	}

	const logActivity = async (lat, lon) => {
		try {
			await axios.post(
				`https://test.solarcredit.io/v0.1/api/logUserActivity`,
				{
					usertoken: userData.usertoken,
					logs: currentLog,
					longtitude: lon,
					latitude: lat,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
		} catch (error) {
			console.log(error.message);
		}
	};

	const initializePayment = usePaystackPayment({
		reference: new Date().getTime().toString(),
		email: email,
		amount: amount * 100,
		publicKey: 'pk_test_ccd9d31670a4e4be4917412334639e338067d4be',
	});

	return (
		<>
			<Modal
				open={isModalOpen}
				onOk={handleOk}
				footer={null}
				onCancel={handleOk}
				centered>
				<div className='ordersummary-container'>
					{/* <h3>Order Summary</h3> */}
					<div className='summary-imgcase d-flex justify-content-center align-items-center'>
						<img src={logo} alt='' loading='lazy' />
					</div>
					<h3>Order Summary</h3>
					<div className='summary-detailscase mt-3'>
						<p>
							Price:<span>₦ {amount} </span>
						</p>
						<p>
							Watts:<span> 1000W</span>
						</p>
						<p>
							Size:<span>NAN</span>
						</p>
						<p>
							Delivery Fee:<span>₦ 0</span>
						</p>
						<p>
							Quantity: <span>1</span>
						</p>
						<hr />
						<p>
							Cost:<span>₦ {amount}</span>
						</p>
						<hr />
						<p className='summarytotal'>
							Total: <span>₦ {amount} </span>
						</p>
					</div>
					<div className='order-btncase'>
						{/* pay with reni */}
						<button className='orderbtn1' onClick={() => ReniPayment()}>
							{walletLoader ? (
								<Spinner
									animation='border'
									role='status'
									size='sm'
									className='store-spinner walletpayment-loader'>
									<span className='visually-hidden'>Loading...</span>
								</Spinner>
							) : (
								'Pay with Reni'
							)}
						</button>
						{/* <button className='orderbtn1' onClick={() => walletPayment()}>
							{walletLoader ? (
								<Spinner
									animation='border'
									role='status'
									size='sm'
									className='store-spinner walletpayment-loader'>
									<span className='visually-hidden'>Loading...</span>
								</Spinner>
							) : (
								'Pay with Wallet'
							)}
						</button> */}
						{/* <button
							className='orderbtn2'
							onClick={() => initializePayment(onSuccess, onClose)}>
							{payLoader ? (
								<Spinner
									animation='border'
									role='status'
									size='sm'
									className='store-spinner payment-loader'>
									<span className='visually-hidden'>Loading...</span>
								</Spinner>
							) : (
								'Payment with Card'
							)}
						</button> */}
					</div>
				</div>
			</Modal>
			<Modal
				open={isModalOpen1}
				onOk={handleOk}
				footer={null}
				onCancel={handleOk}
				centered>
				<Result
					status='success'
					title='Successfully Purchased!'
					subTitle='Check your mail for an order recipt'
					extra={[
						<Button key='console' onClick={() => navigate('/user/orders')}>
							View Orders
						</Button>,
					]}
				/>
			</Modal>

			{/* complete payment */}
			<Modal
				open={isModalOpen2}
				onOk={handleOk}
				footer={null}
				onCancel={handleOk}
				centered>
				<Result
					status='success'
					title='Complete Payment!'
					subTitle='Check your mail for your OTP'
					extra={[
						<div style={{ marginBottom: '1rem' }}>
							<Input
								placeholder='OTP'
								style={{ width: '40%' }}
								value={otp}
								onChange={handleOtpChange}
							/>
						</div>,
						<Button key='console' onClick={() => ConfirmPayment()}>
							{walletLoader ? (
								<Spinner
									animation='border'
									role='status'
									size='sm'
									className='store-spinner walletpayment-loader'>
									<span className='visually-hidden'>Loading...</span>
								</Spinner>
							) : (
								'Complete Payment'
							)}
						</Button>,
					]}
				/>
			</Modal>

			{type === 'cart' ? (
				<button
					onClick={
						amount === 0
							? undefined
							: () => {
									showModal();
							  }
					}>
					{payLoader ? (
						<Spinner
							animation='border'
							role='status'
							size='sm'
							className='store-spinner payment-loader'>
							<span className='visually-hidden'>Loading...</span>
						</Spinner>
					) : (
						<>{text}</>
					)}
				</button>
			) : (
				<button onClick={() => showModal()}>
					{payLoader ? (
						<Spinner
							animation='border'
							role='status'
							size='sm'
							className='store-spinner payment-loader'>
							<span className='visually-hidden'>Loading...</span>
						</Spinner>
					) : (
						<>{text}</>
					)}
				</button>
			)}
		</>
	);
};
