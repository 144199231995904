import React, { useState, useEffect } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Result, Button, message } from 'antd';
import axios from 'axios';
import './kyc.css';

function Kyc() {
	const location = useLocation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const userData = JSON.parse(localStorage.getItem('userData'));
	const app_token =
		'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';
	const [kycData, setKycData] = useState(null);
	const [img, setImg] = useState(null);
	const [imgSrc, setImgSrc] = useState('');
	const [imgLoader, setImgLoader] = useState(false);

	// function to change image file to blob
	const handleImageUpload = async () => {
		setImgLoader(true);

		try {
			const formData = new FormData();
			formData.append('image', img);

			const response = await axios.post(
				'https://test.solarcredit.io/v0.1/api/uploadImage',
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer 6455ef91d108f`,
					},
				}
			);
			setImgLoader(false);
			if (response.data.success) {
				setImgSrc(response.data.data.image);
			} else {
				message.info(response.data.message);
			}
		} catch (error) {
			setImgLoader(false);
			message.error(error.message);
		}
	};

	// useEffect to immediately generate blob once an image is selected
	useEffect(() => {
		if (img) {
			handleImageUpload();
		}
	}, [img]);

	// function to handle input data change
	const handleChange = (e) => {
		const value = e.target.value;
		setKycData({ ...kycData, [e.target.name]: value });
	};

	const handleKycUpdate = async (e) => {
		e.preventDefault();
		// if (!imgSrc) {
		// 	message.info('Kindly add face photo!');
		// 	return;
		// }
		setLoading(true);
		try {
			const res = await axios.post(
				'https://test.solarcredit.io/v0.1/api/createKyc',
				{
					...kycData,
					apptoken: app_token,
					usertoken: userData.usertoken,
					renitoken: userData.renitoken,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
			if (res.data.success === true) {
				getUserData();
			} else {
				setLoading(false);
				message.warning(res.data.message);
			}
		} catch (error) {
			setLoading(false);
			message.error(error.message);
		}
	};
	const getUserData = async () => {
		try {
			const res = await axios.post(
				'https://test.solarcredit.io/v0.1/api/getUserdata',
				{
					apptoken: app_token,
					usertoken: userData.usertoken,
					// renitoken: userData.renitoken,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);

			if (res.data.success) {
				message.success('Kyc Verification Successful');
				localStorage.setItem('userData', JSON.stringify(res.data.data));
				setLoading(false);
				navigate(-1);
			} else {
				message.info(res.data.message);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	if (!location.state) {
		return (
			<Result
				status='404'
				title='404'
				subTitle='Sorry, the page you visited does not exist.'
				extra={
					<Button onClick={() => navigate('/')} type='primary'>
						Back Home
					</Button>
				}
			/>
		);
	}
	if (userData?.kycStatus) {
		return <Navigate to='/user/dashboard' />;
	}

	return (
		<div className='kyc-main-case'>
			<div className='kyc-case'>
				<Link
					to={'/user/dashboard'}
					className='btns'
					style={{ position: 'absolute', top: '10px', left: '10px' }}>
					Home
				</Link>

				<form onSubmit={handleKycUpdate}>
					<h3 className='text-center mt-3 mb-3'>Complete Your KYC</h3>
					<div className='kyc-form-group'>
						<label htmlFor=''>Full Name:</label>
						<input type='text' name='fname' onChange={handleChange} required />
					</div>
					<div className='kyc-form-group'>
						<label htmlFor=''>Occupation:</label>
						<input
							type='text'
							name='occupation'
							onChange={handleChange}
							required
						/>
					</div>
					{/* <div className='kyc-form-group'>
						<label htmlFor=''>Means of Identification</label>
						<select name='identity' onChange={handleChange} required>
							<option value='NIN'>Click to select</option>
							<option value='NIN'>NIN</option>
							<option value="Driver's License">Driver's License</option>
						</select>
					</div> */}
					{/* <div className='kyc-form-group'>
						<label htmlFor=''>Identity No:</label>
						<input
							type='number'
							name='identity_num'
							onChange={handleChange}
							required
						/>
					</div> */}
					<div className='kyc-form-group'>
						<label htmlFor=''>BVN No:</label>
						<input type='number' name='bvn' onChange={handleChange} required />
					</div>
					{/* <div className='kyc-form-group'>
						<label htmlFor=''>Address:</label>
						<textarea
							name='address'
							cols='30'
							rows='10'
							onChange={handleChange}
							required></textarea>
					</div> */}
					{/* <div className='kyc-form-group'>
						<label htmlFor=''>
							{' '}
							Please input a copy of your face photograph(passport):
						</label>
						<label class='profilePhoto' htmlFor='profilePhoto'>
							{' '}
							{imgLoader ? 'Uploading...' : 'Click to add your face photo'}
						</label>
						<input
							type='file'
							style={{ display: 'none' }}
							name='profilePhoto'
							onChange={(e) => setImg(e.target.files[0])}
							id='profilePhoto'
							required
						/>
					</div> */}
					{/* {imgSrc && (
						<div className='kyc-form-group imageViewBox-main-img d-flex flex-row align-items-center justify-content-between'>
							<div className='imageViewBox'>
								<img src={`https://test.solarcredit.io/uploads/${imgSrc}`} />
							</div>
							<span>{img?.name}</span>
						</div>
					)} */}
					<button>{loading ? 'Submitting' : 'Submit'}</button>
				</form>
			</div>
		</div>
	);
}

export default Kyc;
