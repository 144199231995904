import React, { useEffect, useState } from 'react';
import AdminWrapper from '../../adminComponents/adminWrapper/AdminWrapper';
import DashNav from '../../../usersDashboard/userComponents/dashNav/DashNav';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { message } from 'antd';

const Createloan = () => {
	const [loading, setLoading] = useState(false);
	const [img, setImg] = useState(null);
	const [imgSrc, setImgSrc] = useState(null);
	const [imgLoader, setImgLoader] = useState(false);
	const [name, setName] = useState('');
	const [interest, setInterest] = useState('');

	const navigate = useNavigate();

	const handleImageUpload = async () => {
		setImgLoader(true);

		try {
			const formData = new FormData();
			formData.append('image', img);

			const response = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/uploadImage`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer 6455ef91d108f`,
					},
				}
			);
			setImgLoader(false);
			if (response.data.success) {
				message.success('Image uploaded successfully!');
				setImgSrc(response.data.data.image);
			} else {
				message.info(response.data.message);
			}
		} catch (error) {
			setImgLoader(false);
			message.error(error.message);
		}
	};

	// useEffect to immediately generate blob once an image is selected
	useEffect(() => {
		if (img) {
			handleImageUpload();
		}
	}, [img]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === 'name') {
			setName(value);
		} else if (name === 'interest') {
			setInterest(value);
		}
	};

	const handleLoanProvider = async (e) => {
		e.preventDefault();
		if (!imgSrc) {
			message.info('Kindly add face photo!');
			return;
		}
		setLoading(true);
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/admin/createLoanProvider`,
				{
					name: name,
					interest_rate: interest,
					image: imgSrc,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
			setLoading(false);
			setInterest('');
			setName('');
			message.success('Loan Provider Created');
		} catch (error) {
			setLoading(false);
			// message.error(error.message);
		}
	};

	return (
		<div>
			<AdminWrapper>
				<DashNav title='Upload File' />

				<div className='file-upload'>
					{/* name */}
					<div>
						<label className='form-label' htmlFor='name'>
							Name
						</label>
						<input
							className='form-input'
							id='name'
							type='text'
							name='name'
							value={name}
							onChange={handleChange}
						/>
					</div>
					<div>
						<label className='form-label' htmlFor='form_input'>
							Interest Rate
						</label>
						<input
							className='form-input'
							aria-describedby='form_input_help'
							placeholder='Interest Rate'
							id='interest'
							type='text'
							name='interest'
							value={interest}
							onChange={handleChange}
						/>
					</div>
					<div className='loanform-formitem'>
						<label> Please input a copy of your face passport:</label>
						<label className='loanformprofilePhoto' htmlFor='product_photo'>
							{imgLoader ? 'Uploading...' : 'Click to add face photograph'}
						</label>
						<input
							onChange={(e) => setImg(e.target.files[0])}
							type='file'
							style={{ display: 'none' }}
							id='product_photo'
							placeholder='Phone number'
							required
						/>
					</div>
					{imgSrc && (
						<div className=''>
							<div className='imageViewBox'>
								<img
									src={`https://test.solarcredit.io/uploads/${imgSrc}`}
									alt=''
								/>
							</div>
							{/* <span>{img?.name}</span> */}
						</div>
					)}
					<button onClick={handleLoanProvider}>
						{loading ? 'Submitting' : 'Submit'}
					</button>
				</div>
			</AdminWrapper>
		</div>
	);
};

export default Createloan;
