import React from "react";
import DashNav from "../../userComponents/dashNav/DashNav";
import StoreItem from "../../userComponents/storeItem/StoreItem";
import Wrapper from "../../userComponents/userWrapper/Wrapper";
import Spinner from "react-bootstrap/Spinner";
import { useState, useEffect } from "react";
import axios from "axios";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import "./solarstore.css";
function SolarStore() {
  const oldData = JSON.parse(localStorage.getItem("storeProducts"));
  const [data, setData] = useState(oldData ? oldData : []);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(oldData ? false : true);
  const navigate = useNavigate();
  const APP_TOKEN =
    "vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh";

  //Function to get all store products
  const getStoreProducts = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/fetchProducts`,
        { apptoken: APP_TOKEN },
        {
          headers: { Authorization: `Bearer 6455ef91d108f` },
        }
      );
      setLoading(false);
      if (res.data.success) {
        setData(res.data.data);
        localStorage.setItem("storeProducts", JSON.stringify(res.data.data));
      } else {
        message.info(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };
  const state = {
    search,
  };

  const handleChange = () => {
    return navigate("/user/solarstore/search", { state });
  };

  useEffect(() => {
    getStoreProducts();
  }, []);

  useEffect(() => {
    if (search) {
      handleChange();
    }
  }, [search]);

  return (
    <div>
      <Wrapper>
        <DashNav
          title="Store"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={"Search e.g solar panel"}
        />

        <form className="store-search-page regular-store">
          <input
            type="text"
            placeholder="Search Product.."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </form>

        {loading ? (
          <div className="store-container d-flex align-items-center justify-content-center">
            <span className=" d-flex align-items-center justify-content-center">
              <Spinner
                animation="border"
                role="status"
                size="lg"
                className="store-spinner"
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <span style={{ marginLeft: "10px" }}>Loading..</span>
            </span>
          </div>
        ) : (
          <div className="store-container d-flex align-items-start flex-wrap">
            {data?.map((item) => {
              return <StoreItem data={item} key={item.productToken} />;
            })}
          </div>
        )}
      </Wrapper>
    </div>
  );
}

export default SolarStore;
