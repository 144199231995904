import React from 'react';
import { useState, useEffect } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { BsSearch } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import cart from '../../../../icons/cart.svg';
import profile from '../../../../icons/profile.svg';
import './dashNav.css';
import axios from 'axios';

const DashNav = ({ title, value, onChange, placeholder }) => {
	const navigate = useNavigate();
	const userData = JSON.parse(localStorage.getItem('userData'));
	const localCartProduct = JSON.parse(localStorage.getItem('cartProducts'));
	const [cartLength, setCartLength] = useState(localCartProduct?.length);
	const app_token =
		'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';
	// Open Navbar
	const openNav = (e) => {
		document.getElementById('mySidenav').style.width = '250px';
	};

	const state = {
		search: value,
	};

	const fetchCart = async () => {
		try {
			const res = await axios.post(
				'https://test.solarcredit.io/v0.1/api/fetchCart',
				{
					apptoken: app_token,
					usertoken: userData.usertoken,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
			if (res.data.success) {
				localStorage.setItem(
					'cartProducts',
					JSON.stringify(res.data.data.Products)
				);
				setCartLength(res.data.data.Products?.length);
			}
		} catch (error) {
			console.log(error.message);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		return navigate('/user/solarstore/search', { state });
	};

	// useEffect(() => {
	//     const interval = setInterval(fetchCart, 5000);

	//     // Clean up the interval on component unmount
	//     return () => {
	//         clearInterval(interval);
	//     };
	// }, []);

	return (
		<div>
			<div className='dash-nav d-flex justify-content-between align-items-center'>
				<div className='dasn-nav-left d-flex align-items-center'>
					<GiHamburgerMenu className='hamburger' onClick={() => openNav()} />
					<h5>{title}</h5>
				</div>
				<div className='dash-nav-right d-flex justify-content-end align-items-center'>
					{placeholder && (
						<form
							onSubmit={handleSubmit}
							className='d-flex justify-content-center align-items-center'>
							<input
								type='text'
								value={value}
								onChange={onChange}
								placeholder={placeholder ? placeholder : 'Search'}
							/>
							<button
								type='submit'
								className='d-flex justify-content-center align-items-center'>
								<BsSearch />
							</button>
						</form>
					)}
					<div
						style={{ cursor: 'pointer' }}
						onClick={() => navigate('/user/cart')}
						className='dash-profile-cart d-flex justify-content-center align-items-center'>
						<img src={cart} alt='' />
						<span>{cartLength}</span>
					</div>
					<div className='dash-profile-div d-flex justify-content-center align-items-center'>
						<Link
							to='/user/profile'
							className='d-flex justify-content-center align-items-center'>
							<img src={profile} alt='' />
							<span>{userData.fname.split(' ')[1]}</span>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DashNav;
