import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../../../images/logo.png';
import icon1 from '../../../../icons/overview.png';
import icon2 from '../../../../icons/payment.png';
import icon3 from '../../../../icons/calc.png';
import icon4 from '../../../../icons/buyback.png';
import icon5 from '../../../../icons/loanicon.png';
import icon6 from '../../../../icons/agent.png';
import icon7 from '../../../../icons/sett.png';
import icon8 from '../../../../icons/noti.png';
import icon9 from '../../../../icons/store.png';

const UsersNavLinks = ({ adminPages }) => {
	const userData = JSON.parse(localStorage.getItem('userData'));

	const Menus = adminPages
		? [
				{
					title: 'Overview',
					src: icon1,

					link: '/admin/dashboard',
				},
				{ title: 'Users', src: icon6, link: '/admin/users' },
				{ title: 'Loans', src: icon5, link: '/admin/loans' },
				{ title: 'Products', src: icon9, link: '/admin/products' },
				{ title: 'Plans', src: icon9, link: '/admin/plans' },
				{ title: 'Newsletter', src: icon9, link: '/admin/newsletter' },
				{ title: 'Categories', src: icon3, link: '/admin/category' },
				{ title: 'Upload Load', src: icon3, link: '/admin/upload' },
				{
					title: 'Create Loan Provider',
					src: icon3,
					link: '/admin/createloan',
				},

				// { title: "Transactions", src: icon2, link: "/admin/transactions" },
				{
					title: 'Service Requests',
					src: icon4,
					link: '/admin/servicerequests',
				},
				// {
				// 	title: 'Withdrawal Requests',
				// 	src: icon4,
				// 	link: '/admin/withdrawalrequests',
				// },
				{ title: 'Order Requests', src: icon4, link: '/admin/ordersrequests' },
				{
					title: 'User Portal',
					src: icon6,
					link: '/user/dashboard',
				},
		  ]
		: [
				{
					title: 'Overview',
					src: icon1,

					link: '/user/dashboard',
				},
				{ title: 'Payment', src: icon2, link: '/user/payment' },
				{ title: 'Load Audit', src: icon3, link: '/user/loadaudit' },
				{ title: 'Solar Store', src: icon9, link: '/user/solarstore' },
				{ title: 'Buy Back', src: icon4, link: '/user/buyback' },
				{ title: 'Take Loan', src: icon5, link: '/user/loan' },
				{ title: 'Orders', src: icon9, link: '/user/orders' },
				{
					title:
						userData.userType === 'Users' ? 'Customer Service' : 'Admin Portal',
					src: icon6,
					link:
						userData.userType === 'Users'
							? '/user/customerservice'
							: '/admin/dashboard',
				},
		  ];

	const closeNav = (e) => {
		document.getElementById('mySidenav').style.width = '0';
	};

	const customerService = async () => {
		var Tawk_API = Tawk_API || {};
		window.Tawk_API.toggle();
		if (window.innerWidth <= 1000) {
			closeNav();
		}
	};
	return (
		<>
			{' '}
			<div className='sidebar-inner-main'>
				<div
					className='sidenav-nav d-flex align-items-center justify-content-center'
					style={{ marginTop: '30px' }}>
					<Link
						to='/'
						className='navbar-brand d-flex align-items-center justify-content-center'>
						<img src={logo} alt='' />
					</Link>
				</div>
				<ul className='navbar-nav ml-auto mr-auto mt-3'>
					{Menus.map((menu, index) => (
						<li className='nav-item' key={index}>
							<NavLink
								tag={Link}
								activeClassName='active1 '
								to={menu.link === '/user/customerservice' ? null : menu.link}
								onClick={
									menu.link === '/user/customerservice'
										? () => customerService()
										: null
								}>
								<img src={menu.src} alt='' />

								<span> {menu.title}</span>
							</NavLink>
						</li>
					))}
				</ul>
				<div className='border-div'></div>
				{!adminPages && (
					<>
						<ul className='navbar-nav ml-auto mr-auto'>
							<li className='nav-item'>
								<NavLink
									tag={Link}
									activeClassName='active1 '
									to='/user/notifications'>
									<img src={icon8} alt='' />

									<span className='notification-case'>
										<span> Notifications</span>
									</span>
								</NavLink>
							</li>

							<li className='nav-item'>
								<NavLink
									tag={Link}
									activeClassName='active1 '
									to='/user/settings'>
									<img src={icon7} alt='' />

									<span className='notification-case'>
										<span>Settings</span>
									</span>
								</NavLink>
							</li>
						</ul>
					</>
				)}
			</div>
		</>
	);
};

export default UsersNavLinks;
