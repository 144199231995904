import React from 'react'
import { Navigate } from 'react-router-dom'
import UsersSideNav from '../../../usersDashboard/userComponents/UsersNavbar/UsersSideNav';
// import "../AdminNavbar/Nav.css";

const AdminWrapper = ({ children }) => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  if (!userData === 'users') {
    return <Navigate to='/user/dashboard' />
  }
  return (
    <div>
      <UsersSideNav adminPages={true} />
      <div className="dashboard">
        <div className="container">{children}</div>
      </div>
    </div>
  )
}

export default AdminWrapper