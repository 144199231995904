import React, { useEffect, useState } from "react";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import DashNav from "../../userComponents/dashNav/DashNav";
import Wrapper from "../../userComponents/userWrapper/Wrapper";
import { useNavigate } from "react-router-dom";
import { TbCurrencyNaira } from "react-icons/tb";
import { Spinner } from "react-bootstrap";
import { message } from "antd";
import axios from "axios";
// import './cart.css'
function Order() {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const app_token =
    "vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh";
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [orderProduct, setOrderProduct] = useState([]);
  const [filter, setFilter] = useState("once");

  //Function to fetch cart data
  const fetchOrders = async () => {
    setLoader(true);
    setOrderProduct([]);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/${
          filter == "once"
            ? "getUsersPaidOnceGoods"
            : "getUserLoanPurchasedGoods"
        }`,
        {
          apptoken: app_token,
          usertoken: userData.usertoken,
        },
        {
          headers: { Authorization: `Bearer 6455ef91d108f` },
        }
      );
      setLoader(false);

      if (res.data.success) {
        setOrderProduct(res.data.data);
      } else {
        message.info(res.data.message);
      }
    } catch (error) {
      message.error(error.message);
      setLoader(false);
    }
  };

  // UseEffect to fetch cart when page loads
  useEffect(() => {
    fetchOrders();
  }, [filter]);

  return (
    <div>
      <Wrapper>
        <DashNav title={"Orders"} />
        <div className="cartdetailsmain">
          <div className="back-case d-flex align-items-center justify-content-start">
            <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
              {" "}
              <BsFillArrowLeftCircleFill />
            </div>
          </div>
          <div className="cartdetailscase d-flex align-items-start justify-content-between">
            <div className="cartdetailsleft d-flex flex-column  justify-content-start shadow">
              <div className="cart-header d-flex align-items-center justify-content-between">
                <h6>
                  Your orders {`(${orderProduct ? orderProduct?.length : 0})`}
                </h6>
                <div className="d-flex align-items-center order-tab-case">
                  <h6
                    className={filter === "once" ? "active" : ""}
                    onClick={() => setFilter("once")}
                  >
                    Paid Once
                  </h6>
                  <h6
                    className={filter === "installment" ? "active" : ""}
                    onClick={() => setFilter("installment")}
                  >
                    Installmentally
                  </h6>

                  {loader && (
                    <Spinner
                      animation="border"
                      role="status"
                      size="sm"
                      className="store-spinner"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  )}
                </div>
              </div>

              {orderProduct?.map((cartBox) => {
                return (
                  <div
                    key={cartBox?.orderid}
                    onClick={() =>
                      navigate(`/user/orders/${cartBox.orderid}`, {
                        state: cartBox,
                      })
                    }
                    className="cart-box-item cart-box-orders d-flex  justify-content-between"
                  >
                    <div className="cart-box-item-left d-flex flex-column align-items-start justify-content-between">
                      <div className="cart-box-left-top d-flex align-items-center justify-content-start">
                        <div className="cartdetailsleftimg d-flex align-items-center justify-content-center">
                          <img src={cartBox?.productImage} alt="" />
                        </div>
                        <div className="cartdetailslefttext">
                          <h1>{cartBox?.productname}</h1>
                          <span className="cartdetailsprice">
                            QTY: {cartBox?.productQuantity}
                          </span>
                          <br />
                          <span className="cartdetailsprice">
                            Order ID: {cartBox?.orderid}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="cart-box-item-right d-flex flex-column align-items-end justify-content-between">
                      <div className="cart-box-right-top d-flex align-items-center">
                        <TbCurrencyNaira /> {cartBox?.price}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
}

export default Order;
