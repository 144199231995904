import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DashNav from '../../../usersDashboard/userComponents/dashNav/DashNav';
import AdminWrapper from '../../adminComponents/adminWrapper/AdminWrapper';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { Spinner } from 'react-bootstrap';
import { message } from 'antd';

import axios from 'axios';

function ProductView() {
	const [loader, setLoader] = useState(false);
	const location = useLocation();
	const product = location?.state;
	const app_token =
		'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';
	const navigate = useNavigate();

	// Function To Delete product
	const deleteProduct = () => {
		setLoader(true);
		axios
			.post(
				'https://test.solarcredit.io/v0.1/api/admin/deleteProduct',
				{
					apptoken: app_token,
					productToken: product?.productToken,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			)
			.then((res) => {
				setLoader(false);
				if (res.data.success) {
					message.success(res.data.message);
					setTimeout(() => {
						navigate(-1);
					}, 1000);
				} else {
					message.info(res.data.message);
				}
			})
			.catch((err) => {
				setLoader(false);
				message.error(err.message);
			});
	};

	return (
		<div>
			<AdminWrapper>
				<DashNav title='Loan Details' />

				<div className='user'>
					<div className='userTitleContainer fd-flex align-items-center justify-content-between'>
						<Link to={-1}>
							<AiOutlineArrowLeft className='edituserIcon' />
						</Link>
					</div>

					<div className='userContainer'>
						<div className='userShow'>
							<div className='userShowBottom'>
								<span className='userShowTitle mt-3'>Product Details</span>
								<div className='userShowInfo loanShowInfo'>
									<span className='userShowInfoTitle'>
										Product name: {product?.productname}
									</span>
								</div>
								<div className='userShowInfo loanShowInfo'>
									<span className='userShowInfoTitle'>
										Product Description: {product?.productDesc}
									</span>
								</div>
								<div className='userShowInfo loanShowInfo'>
									<span className='userShowInfoTitle'>
										Product Price: {product?.productPrice}
									</span>
								</div>
								<div className='userShowInfo loanShowInfo'>
									<span className='userShowInfoTitle'>
										Product Status: {product?.productStatus}
									</span>
								</div>

								<div className='userShowInfo  loanShowInfo'>
									<span className='userShowInfoTitle'>
										Product Image: <br />
										<img
											className='proofImg'
											src={product?.productImage}
											alt='passport photo'
										/>
									</span>
								</div>

								<div className='userShowInfo loanShowInfo'>
									<span className='userShowInfoTitle'>
										Product Type: {product?.productType}
									</span>
								</div>
								<div className='userShowInfo loanShowInfo'>
									<span className='userShowInfoTitle'>
										Product Category Name: {product?.catname}
									</span>
								</div>
								<div className='userShowInfo loanShowInfo'>
									<span className='userShowInfoTitle'>
										Product Size: {product?.size} {product?.unit}
									</span>
								</div>
								<div className='userShowInfo loanShowInfo'>
									<span className='userShowInfoTitle'>
										Product Voltage: {product?.volt}
									</span>
								</div>
							</div>
						</div>

						<div className='userUpdate'>
							<div className='userShowBottom'>
								<div className='mt-3'>
									<span className='userShowTitle'>
										Product Uploader Details
									</span>
									<div className='userShowInfo loanShowInfo'>
										<span className='userShowInfoTitle'>
											Full Name: {product?.getUserFullDetail?.fname}
										</span>
									</div>
									<div className='userShowInfo loanShowInfo'>
										<span className='userShowInfoTitle'>
											Email: {product?.getUserFullDetail?.mail}
										</span>
									</div>
									<div className='userShowInfo loanShowInfo'>
										<span className='userShowInfoTitle'>
											User type: {product?.getUserFullDetail?.userType}
										</span>
									</div>
									<div className='userShowInfo loanShowInfo'>
										<span className='userShowInfoTitle'>
											Price Expected To Pay:
										</span>
									</div>
								</div>
							</div>

							<div className='userUpdateForm w-100 '>
								<div className='userUpdateRight d-flex align-items-center'>
									<button
										className='userUpdateButton'
										onClick={() => deleteProduct()}
										style={{ backgroundColor: '#ff3e3e', marginLeft: '10px' }}>
										{loader ? (
											<Spinner animation='border' size='sm' />
										) : (
											' Delete Product'
										)}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</AdminWrapper>
		</div>
	);
}

export default ProductView;
