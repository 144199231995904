import React from 'react'
import testiimg from '../../images/testi-img.jpg'
import './testimonial.css'
const Testimonial = () => {
    return (
        <div>
            <div className="testimonial">
                <div className="testimonial-inner">
                    <div className="testimonial-left">
                        <img src={testiimg} alt="" />
                    </div>
                    <div className="testimonial-right">
                        <p>“I’m so happy to use one of the My Solar
                            product. It has helped me to boost my
                            business as i have no need to worry about
                            electricity and has also helped me in planning
                            out my saving plan through the instalment
                            payment feature, I am so thankful.”</p>

                        <div className="testi-name-flex d-flex align-items-center justify-content-between mt-5">
                            <p>Joy Usave</p>
                            <button>{'>'}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonial