import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { message } from 'antd';
import { DataGrid } from '@mui/x-data-grid';
import AdminWrapper from '../../adminComponents/adminWrapper/AdminWrapper';
import DashNav from '../../../usersDashboard/userComponents/dashNav/DashNav';
import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import './users.css';
function Users() {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState('');
	const userData = JSON.parse(localStorage.getItem('userData'));
	const app_token =
		'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';

	// Function to fetch all users
	// const fetchUsers = () => {
	// 	setLoading(true);
	// 	axios
	// 		.post(
	// 			'https://test.solarcredit.io/v0.1/api/admin/getAllUsers',
	// 			{
	// 				apptoken: app_token,
	// 			},
	// 			{
	// 				headers: { Authorization: `Bearer 6455ef91d108f` },
	// 			}
	// 		)
	// 		.then((res) => {
	// 			setLoading(false);
	// 			if (res.data.success) {
	// 				setData(res.data.data);
	// 			} else {
	// 				message.info(res.data.message);
	// 			}
	// 		})
	// 		.catch((err) => {
	// 			setLoading(false);
	// 			message.error(err.message);
	// 		});
	// };

	const fetchUsers = () => {
		setLoading(true);
		axios
			.post(
				'https://test.solarcredit.io/v0.1/api/admin/getAllUsers',
				{
					apptoken: app_token,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			)
			.then((res) => {
				setLoading(false);
				if (res.data.success) {
					const updatedData = res.data.data.map((user) => ({
						...user,
						profession: user.kycData?.profession || 'N/A',
						kycFname: user.kycData?.fname || 'N/A',
					}));
					setData(updatedData);
				} else {
					message.info(res.data.message);
				}
			})
			.catch((err) => {
				setLoading(false);
				message.error(err.message);
			});
	};

	// Function that handles the disable of user account
	const handleDelete = (usertoken) => {
		setData(data.filter((item) => item.usertoken !== usertoken));
		axios
			.delete(`https://test.solarcredit.io/v0.1/api/admin/disableUser`, {
				apptoken: app_token,
				usertoken: userData.usertoken,
				userId: usertoken,
			})
			.then((res) => {
				if (res.data.success) {
					message.success('User Disabled');
				} else {
					message.info(res.data.message);
				}
			})
			.catch((err) => {
				message.error(err.message);
			});
	};

	useEffect(() => {
		fetchUsers();
	}, []);
	const columns = [
		{ field: 'fname', headerName: 'ID', width: 50 },
		{
			field: 'User',
			headerName: 'User',
			width: 200,
			renderCell: (params) => {
				return (
					<div className='userListUser'>
						<img className='userListImg' src={params.row?.profilePic} alt='' />
						{params.row.fname}
					</div>
				);
			},
		},
		{ field: 'mail', headerName: 'Email', width: 200 },
		{ field: 'profession', headerName: 'Profession', width: 250 },
		{ field: 'kycFname', headerName: 'Full Name', width: 200 },
		{
			field: 'action',
			headerName: 'Action',
			width: 250,
			renderCell: (params) => {
				return (
					<>
						<Link
							to={'/admin/users/' + params.row.usertoken}
							state={params.row}>
							<button className='adminTableBtn'>View</button>
						</Link>

						<button
							className='adminTableBtn disable'
							onClick={() => handleDelete(params.row.usertoken)}>
							Disable
						</button>
					</>
				);
			},
		},
	];

	return (
		<div>
			<AdminWrapper>
				<DashNav
					title={'Users'}
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					placeholder='Search name or email'
				/>
				<div className='adminuser-case'>
					{loading ? (
						<Spinner
							animation='border'
							role='status'
							size='lg'
							className='store-spinner'>
							<span className='visually-hidden'>Loading...</span>
						</Spinner>
					) : (
						<DataGrid
							rows={data.filter(
								(item) =>
									item.fname.toLowerCase().includes(search.toLowerCase()) ||
									item.mail.toLowerCase().includes(search.toLowerCase())
							)}
							columns={columns}
							disableSelectionOnClick
							pageSize={8}
							rowsPerPageOptions={[5]}
							checkboxSelection
							getRowId={(r) => r.fname}
						/>
					)}
				</div>
			</AdminWrapper>
		</div>
	);
}

export default Users;
