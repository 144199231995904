import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import AdminWrapper from '../../adminComponents/adminWrapper/AdminWrapper';
import DashNav from '../../../usersDashboard/userComponents/dashNav/DashNav';
import { Modal, Spinner } from 'react-bootstrap';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import axios from 'axios';
import { message } from 'antd';

function WithdrawalRequest() {
	const [data, setData] = useState([]);
	const [show, setShow] = useState(false);
	const [modalData, setModalData] = useState({});
	const [approvedState, setApprovedState] = useState(true);
	const [reason, setReason] = useState('');
	const [loader, setLoader] = useState(false);
	const [loading, setLoading] = useState(false);
	const userData = JSON.parse(localStorage.getItem('userData'));
	const app_token =
		'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	// Function To Fetch Service Requests
	const fetchRequests = () => {
		setLoading(true);
		axios
			.post(
				'https://test.solarcredit.io/v0.1/api/admin/getAllWithdrawalRequests',
				{
					apptoken: app_token,
					usertoken: userData.usertoken,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			)
			.then((res) => {
				console.log(res.data);
				setLoading(false);
				if (res.data.success) {
					setData(res.data.data);
				} else {
					message.info(res.data.message);
				}
			})
			.catch((err) => {
				setLoading(false);
				message.error(err.message);
			});
	};
	// Function To Approve Service Requests
	const approveRequest = () => {
		setLoader(true);
		axios
			.post(
				'https://test.solarcredit.io/v0.1/api/admin/approveWithdrawalRequest',
				{
					apptoken: app_token,
					usertoken: userData.usertoken,
					withrawaltoken: modalData.withrawaltoken,
					amount: modalData.amountRequested,
					accountNumber: modalData.accountNumber,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			)
			.then((res) => {
				setLoader(false);
				if (res.data.success) {
					setShow(false);
					message.success(res.data.message);
					fetchRequests();
				} else {
					message.info(res.data.message);
				}
			})
			.catch((err) => {
				setLoader(false);
				message.error(err.message);
			});
	};
	// Function To Decline Service Requests
	const declineRequest = (e) => {
		e.preventDefault();
		setLoader(true);
		axios
			.post(
				'https://test.solarcredit.io/v0.1/api/admin/unapproveWithdrawal',
				{
					apptoken: app_token,
					usertoken: userData.usertoken,
					message: reason,
					withrawaltoken: modalData.withrawaltoken,
					amount: modalData.amountRequested,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			)
			.then((res) => {
				setLoader(false);
				if (res.data.success) {
					setApprovedState(true);
					setShow(false);
					message.success(res.data.message);
					fetchRequests();
				} else {
					message.info(res.data.message);
				}
			})
			.catch((err) => {
				setLoader(false);
				message.error(err.message);
			});
	};

	// useEffect to fetch data by default
	useEffect(() => {
		fetchRequests();
	}, []);

	// Function To Open Modal
	const openModal = (modaldata) => {
		setModalData(modaldata);
		setShow(true);
	};

	// Column For The Table
	const columns = [
		{
			field: 'withrawaltoken',
			headerName: 'Withrawal token',
			width: 70,
		},
		{
			field: 'RequestedOn',
			headerName: 'Requested date',
			width: 200,
			renderCell: (params) => {
				return <div className='userListUser'>{params.row.RequestedOn}</div>;
			},
		},
		{
			field: 'amountRequested_Thousand',
			headerName: 'Requested amount',
			width: 150,
			renderCell: (params) => {
				return (
					<div className='userListUser'>
						{params.row.amountRequested_Thousand}
					</div>
				);
			},
		},
		{
			field: 'withdrawalStatus',
			headerName: 'Withdrawal Status',
			width: 100,
			renderCell: (params) => {
				return (
					<div className='userListUser'>{params.row.withdrawalStatus}</div>
				);
			},
		},

		{
			field: 'action',
			headerName: 'Action',
			width: 250,
			renderCell: (params) => {
				return (
					<>
						<button
							className='adminTableBtn'
							onClick={() => openModal(params.row)}>
							View
						</button>
					</>
				);
			},
		},
	];

	return (
		<div>
			<AdminWrapper>
				<DashNav title={'Withdrawal Requests'} />
				<div className='adminuser-case'>
					{loading ? (
						<Spinner
							animation='border'
							role='status'
							size='lg'
							className='store-spinner'>
							<span className='visually-hidden'>Loading...</span>
						</Spinner>
					) : (
						<DataGrid
							rows={data}
							columns={columns}
							disableSelectionOnClick
							pageSize={8}
							rowsPerPageOptions={[5]}
							checkboxSelection
							getRowId={(r) => r.withrawaltoken}
						/>
					)}
				</div>

				<Modal
					className='categoryModal'
					show={show}
					onHide={handleClose}
					centered
					keyboard={false}>
					<Modal.Header closeButton>
						<Modal.Title>{'Request Details'}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className='servicemodalcase'>
							{approvedState ? (
								<>
									<div className='d-flex flex-column align-items-start'>
										<span className='serviceinfotitle'>
											Name: {modalData?.accountName}
										</span>
										<br />
										<span className='serviceinfotitle'>
											Date Requested: {modalData?.RequestedOn}
										</span>
										<br />
										<span className='serviceinfotitle'>
											Amount: {modalData?.amountRequested_Thousand}
										</span>
										<br />
										<span className='serviceinfotitle'>
											Bank: {modalData?.bankName}
										</span>
										<br />
										<span className='serviceinfotitle'>
											Account Number: {modalData?.accountNumber}
										</span>
										<br />
										<span className='serviceinfotitle'>
											Account Name: {modalData?.accountName}
										</span>
									</div>

									<div className='servicemodalbuttoncase d-flex align-items-center justify-content-end'>
										<button onClick={() => approveRequest()}>
											{loader ? (
												<Spinner animation='border' size='sm' />
											) : (
												'Approve'
											)}
										</button>
										<button onClick={() => setApprovedState(false)}>
											Decline
										</button>
									</div>
								</>
							) : (
								<>
									<AiOutlineArrowLeft
										className='declineFormIcon'
										onClick={() => setApprovedState(true)}
									/>
									<form
										className='declineForm d-flex flex-column align-items-center justify-content-center'
										onSubmit={declineRequest}>
										<textarea
											onChange={(e) => setReason(e.target.value)}
											placeholder='Reason For Request Decline'
											id=''
											cols='30'
											rows='10'
											required></textarea>
										<button>
											{loader ? (
												<Spinner animation='border' size='sm' />
											) : (
												'Decline'
											)}
										</button>
									</form>
								</>
							)}
						</div>
					</Modal.Body>
				</Modal>
			</AdminWrapper>
		</div>
	);
}

export default WithdrawalRequest;
