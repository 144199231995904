import {
	Autocomplete,
	Button,
	Paper,
	Stack,
	TextField,
	Typography,
} from '@mui/material';

import { useEffect, useState } from 'react';
import axios from 'axios';
import { useBreakpoints } from '../../../../theme/breakpoints';
import DashNav from '../../../usersDashboard/userComponents/dashNav/DashNav';
import AdminWrapper from '../../adminComponents/adminWrapper/AdminWrapper';
import { Link } from 'react-router-dom';

const textFieldStyle = {
	'.MuiOutlinedInput-notchedOutline': {
		border: 'unset',
	},
	'& .Mui-focused .MuiOutlinedInput-notchedOutline': {
		border: 'unset',
	},
	'& input': {
		bgcolor: 'white',
		color: '#000',
		p: '5px 15px',
	},
	'& input::placeholder': {
		fontSize: '1rem',
		color: 'black',
	},
	'& .MuiButtonBase-root.MuiChip-root': {
		bgcolor: 'primary.main',
		color: 'neutral.50',
	},
	'& .MuiChip-root .MuiSvgIcon-root': {
		color: 'neutral.50',
	},
	'& .MuiChip-root .MuiSvgIcon-root:hover': {
		color: 'neutral.50',
	},
	'& .MuiFormLabel-root': {
		// color: '#000'
	},
};

const ContactList = () => {
	const { sm, md } = useBreakpoints();
	const [lists, setLists] = useState([]);
	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [email, setEmail] = useState('');
	const [selectedListToken, setSelectedListToken] = useState('');
	const [selectedListName, setSelectedListName] = useState('');
	const app_token =
		'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === 'firstname') {
			setFirstname(value);
		} else if (name === 'lastname') {
			setLastname(value);
		} else if (name === 'email') {
			setEmail(value);
		}
	};

	useEffect(() => {
		const fetchlists = async () => {
			try {
				const res = await axios.get(
					'https://test.solarcredit.io/v0.1/api/admin/getAllLists',
					{
						headers: { Authorization: `Bearer 6455ef91d108f` },
					}
				);
				setLists(res.data.data);
				console.log(res.data.data);
			} catch (error) {
				console.log('Error Loading', error);
			}
		};
		// Call the function to fetch the data
		fetchlists();
	}, []);

	const handleListSelect = (event, value) => {
		// Update the selected list token when an item is selected
		const selectedList = lists.find((list) => list.name === value);
		if (selectedList) {
			setSelectedListToken(selectedList.token);
			setSelectedListName(selectedList.name);
		}
	};

	const handleAddContactToList = async () => {
		try {
			const postData = {
				fname: firstname,
				lname: lastname,
				mail: email,
				listoken: selectedListToken, // Use the selected list's token
				apptoken: app_token,
			};
			const res = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/admin/addContactToList`,

				postData,

				{
					headers: { Authorization: 'Bearer 6455ef91d108f' },
				}
			);

			setEmail('');
			setFirstname('');
			setLastname('');
			console.log('Contact added to list', res.data);
		} catch (error) {
			console.error('Error adding contact to list', error);
		}
	};

	// console.log(lists.find((list) => list.name));

	return (
		<AdminWrapper>
			<DashNav title={'NewsLetter'} />
			<div style={{ marginBottom: '3rem' }}>
				<Link
					style={{ color: '#fcbc0a', fontSize: '1rem' }}
					to={'/admin/newsletter'}>
					Go back
				</Link>
			</div>
			<Stack
				sx={{
					boxShadow: '0 0 20px rgb(0,0,0,.125)',
					borderTopLeftRadius: '25px',
					borderTopRightRadius: '25px',
					overflow: 'hidden',
				}}>
				<Stack
					sx={{
						p: '30px 20px',
						bgcolor: '#f7f8ff',
					}}>
					<Typography variant='h5' sx={{ fontWeight: 500 }}>
						Contact List
					</Typography>
				</Stack>
				<Stack>
					<Autocomplete
						// disablePortal
						id='combo-box-demo'
						options={lists.map((list) => list.name)}
						sx={textFieldStyle}
						value={selectedListName} // Set the selected value
						onInputChange={handleListSelect}
						renderInput={(params) => <TextField {...params} label='Lists' />}
						PaperComponent={(props) => (
							<Paper
								sx={{
									background: '#f1f1f1',
									color: '#1f1f1f',
									fontSize: '25px',
									'&:hover': {
										// border: '1px solid #00FF00',
										color: 'gray',
										backgroundColor: 'white',
									},
								}}
								{...props}
							/>
						)}
					/>

					<TextField
						placeholder='FirstName'
						id='firstname'
						name='firstname'
						value={firstname}
						onChange={handleChange}
						sx={textFieldStyle}
					/>
					<TextField
						placeholder='LastName'
						id='lastname'
						name='lastname'
						value={lastname}
						onChange={handleChange}
						sx={textFieldStyle}
					/>
					<TextField
						placeholder='Email'
						id='email'
						name='email'
						value={email}
						onChange={handleChange}
						sx={textFieldStyle}
					/>
					<Stack
						sx={{
							p: !md ? '20px 50px' : '20px 10px',
						}}>
						<Button onClick={handleAddContactToList}>Send</Button>
					</Stack>
				</Stack>
			</Stack>
		</AdminWrapper>
	);
};

export default ContactList;
