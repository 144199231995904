import React, { useState, useEffect } from 'react';
import naira from '../../../../icons/naira.png';
import DashNav from '../../userComponents/dashNav/DashNav';
import Wrapper from '../../userComponents/userWrapper/Wrapper';
import { message, Modal, Table, Tag, Button, Result } from 'antd';
import { PaystackHookExample } from '../../../../util/PaystackWallet';
import './payment.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
function Payment() {
	const userData = JSON.parse(localStorage.getItem('userData'));
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModal2Open, setIsModal2Open] = useState(false);
	const [amount, setAmount] = useState('');
	const [accountNo, setAccountNo] = useState('');
	const [accountName, setAccountName] = useState('');
	const [bankName, setBankName] = useState('');
	const [bankList, setBankList] = useState([]);
	const [loader, setLoader] = useState(false);
	const [balance, setBalance] = useState(userData?.availableBalance_thousand);
	const [transactHistory, setTransactHistory] = useState([]);
	const [kycModal, setKycModal] = useState(
		userData?.kycStatus === false ? true : false
	);
	const navigate = useNavigate();

	const columns = [
		{
			title: 'Date',
			dataIndex: 'paymentDate',
			key: 'paymentDate',
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
		},

		{
			title: 'Type',
			key: 'paymentType',
			dataIndex: 'paymentType',
			render: (tag) => (
				<Tag color={tag === 'Credit' ? 'green' : 'volcano'} key={tag}>
					{tag.toUpperCase()}
				</Tag>
			),
		},
	];

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const getTransHistory = async () => {
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/fetchWalletHistory`,
				{
					apptoken:
						'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh',
					usertoken: userData.usertoken,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
			if (res.data.success) {
				setTransactHistory(res.data.data);
			} else {
				message.info(res.data.message);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const getUserData = async () => {
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/getUserdata`,
				{
					apptoken:
						'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh',
					usertoken: userData.usertoken,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);

			if (res.data.success) {
				localStorage.setItem('userData', JSON.stringify(res.data.data));
				setBalance(res.data.data.availableBalance_thousand);
			} else {
				message.info(res.data.message);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const processWithdrawal = async (e) => {
		e.preventDefault();
		setLoader(true);
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/requestWithdrawal`,
				{
					apptoken:
						'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh',
					usertoken: userData.usertoken,
					accountName: accountName,
					accountNumber: accountNo,
					bankName,
					amount,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
			setLoader(false);
			getLocation();

			if (res.data.success) {
				setIsModal2Open(false);
				message.success(res.data.message);
				getTransHistory();
				getUserData();
			} else {
				message.info(res.data.message);
			}
		} catch (error) {
			setLoader(false);
			message.error(error.message);
		}
	};

	const getBankList = () => {
		axios
			.get('https://nigerianbanks.xyz')
			.then((response) => {
				setBankList(response.data);
			})
			.catch((error) => {
				message.error(error.message);
			});
	};

	useEffect(() => {
		getTransHistory();
		getUserData();
		getBankList();
	}, []);

	function getLocation() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(showPosition);
		} else {
			message.info('Geolocation is not supported by this browser.');
		}
	}

	function showPosition(position) {
		logActivity(position.coords.latitude, position.coords.longitude);
	}

	const logActivity = async (lat, lon) => {
		try {
			await axios.post(
				`https://test.solarcredit.io/v0.1/api/logUserActivity`,
				{
					usertoken: userData.usertoken,
					logs: 'Processed Withdrawal',
					longtitude: lon,
					latitude: lat,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
		} catch (error) {
			console.log(error.message);
		}
	};

	return (
		<div>
			<Wrapper>
				<DashNav title='payment' />
				<div className='payment-container d-flex align-items-start justify-content-between'>
					<div className='payment d-flex flex-column  align-items-center '>
						<div className='payment-card d-flex flex-column justify-content-between align-items-center shadow'>
							<div className='payment-card-top  d-flex justify-content-between align-items-center'>
								<h4>Wallet Balance</h4>
							</div>
							<div className='payment-card-middle d-flex flex-column justify-content-between align-items-start'>
								<div>
									<div className='payment-price-case d-flex justify-content-start align-items-center'>
										<img src={naira} alt='' />
										<span>{balance}</span>
									</div>
								</div>
								<div className='payment-card-footer d-flex align-items-center justify-content-end'>
									<button
										className='btn payment-btn'
										onClick={() => showModal()}>
										Fund Wallet
									</button>
									<button
										style={{ marginLeft: '10px' }}
										className='btn payment-btn'
										onClick={() => setIsModal2Open(true)}>
										Withdraw
									</button>
								</div>
							</div>
						</div>
					</div>

					<div className='payment d-flex flex-column  align-items-center '>
						<div className='payment-card d-flex flex-column justify-content-between align-items-center shadow'>
							<div className='payment-card-top  d-flex justify-content-between align-items-center'>
								<h4>Outstanding Wallet</h4>
							</div>
							<div className='payment-card-middle d-flex flex-column justify-content-between align-items-start'>
								<div>
									<div className='payment-price-case d-flex justify-content-start align-items-center'>
										<img src={naira} alt='' />
										<span>{userData?.getAllOutstandingFee}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='payment-history' style={{ marginTop: '50px' }}>
					All Transactions
					<Table
						columns={columns}
						pagination={{
							defaultPageSize: 8,
							total: transactHistory.length,
							pageSize: 8,
						}}
						dataSource={transactHistory}
					/>
				</div>

				<Modal
					open={isModalOpen}
					onOk={handleOk}
					footer={null}
					onCancel={handleOk}>
					<div className='fund-wallet'>
						{/* <input
							placeholder='Amount'
							value={amount}
							onChange={(e) => setAmount(e.target.value)}
						/> */}
						<div>{userData.accountNumber}</div>

						<div>Enrich Microfinance Bank</div>
						{/* <PaystackHookExample
							amount={amount}
							text={`Pay Now`}
							email={userData.mail}
							setIsModalOpen={setIsModalOpen}
							getUserData={getUserData}
							getTransHistory={getTransHistory}
						/> */}
					</div>
				</Modal>

				<Modal
					open={isModal2Open}
					onOk={() => setIsModal2Open(false)}
					footer={null}
					onCancel={() => setIsModal2Open(false)}>
					<div className='fund-wallet'>
						<form onSubmit={processWithdrawal}>
							<input
								placeholder='Amount'
								value={amount}
								onChange={(e) => setAmount(e.target.value)}
								required
							/>
							<input
								placeholder='Account Number'
								value={accountNo}
								onChange={(e) => setAccountNo(e.target.value)}
								required
							/>
							<input
								placeholder='Account Name'
								value={accountName}
								onChange={(e) => setAccountName(e.target.value)}
								required
							/>
							<select onChange={(e) => setBankName(e.target.value)} required>
								<option>Select Bank</option>
								{bankList?.map((bank) => {
									return (
										<option key={bank.name} value={bank.name}>
											{bank.name}
										</option>
									);
								})}
							</select>
							<button>{loader ? 'Processing' : 'Withdraw'}</button>
						</form>
					</div>
				</Modal>

				<div className='kyc-notification-modal'>
					<Modal
						closable={false}
						open={kycModal}
						onOk={() => setKycModal(false)}
						onCancel={() => setKycModal(false)}
						footer={null}
						wrapClassName='kyc-notification-modal'
						maskClosable={false}>
						<Result
							status='warning'
							title="Can't access this resource without completing KYC Verification."
							extra={[
								<Button
									key='completeKycBtn'
									onClick={() =>
										navigate('/user/kyc', { state: { kyc: true } })
									}
									type='primary'>
									Complete KYC Verification
								</Button>,
								<Button
									key='closeModalBtn'
									onClick={() => setKycModal(false)}
									type='default'
									style={{ position: 'absolute', top: '10px', left: '10px' }}>
									Close
								</Button>,
							]}
						/>
					</Modal>
				</div>
			</Wrapper>
		</div>
	);
}

export default Payment;
