import React from 'react'
import './footer.css'
const Footer = () => {
    return (
        <div>
            <div className="footer">
                <div className="footer-top d-flex align-items-center justify-content-center">
                    <div className="footer-top-inner d-flex align-items-start justify-content-between">
                        <ul className="footer-ul">
                            <span className='footer-ul-title'>PRODUCT</span>
                            <li className='footer-ul-title'>Hybrid Inverter</li>
                            <li className='footer-ul-title'> Battery Inverter</li>
                            <li className='footer-ul-title'>Solar Charger</li>
                        </ul>
                        <ul className="footer-ul">
                            <span className='footer-ul-title'>FEATURES</span>
                            <li className='footer-ul-title'>Solar Store</li>
                            <li className='footer-ul-title'>Load Calculator</li>
                            <li className='footer-ul-title'>Pay Installmentally</li>
                            <li className='footer-ul-title'>Take Loan</li>
                        </ul>
                        <ul className="footer-ul">
                            <span className='footer-ul-title'>COMPANY</span>
                            <li className='footer-ul-title'>About Us</li>
                            <li className='footer-ul-title'>Tech Team</li>
                            <li className='footer-ul-title'>Contact Us</li>
                            <li className='footer-ul-title'>Become a distributor</li>
                        </ul>
                        <ul className="footer-ul">
                            <span className='footer-ul-title'>CONNECT</span>
                            <li className='footer-ul-title'>Facebook</li>
                            <li className='footer-ul-title'>Twitter</li>
                            <li className='footer-ul-title'>Instagram</li>
                        </ul>

                        <div className="footer-mail-box">
                            <h4>Newsletter</h4>
                            <h5>Join Our Monthly Mailing List</h5>
                            <div className="footer-input-flex shadow-sm">
                                <input type="email" placeholder='Email' />
                                <button className='btn'>Subscribe</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom d-flex align-items-center justify-content-center">
                    <div className="footer-bottom-inner">
                        <hr />
                        <div className="footer-bottom-inner-flex d-flex align-items-center justify-content-between">
                            <h4>Privacy policy</h4>
                            <h4>2023 MYSOLAR/All Rights Reserved</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer