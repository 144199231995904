import React from "react";
import DashNav from "../../userComponents/dashNav/DashNav";
import StoreItem from "../../userComponents/storeItem/StoreItem";
import Wrapper from "../../userComponents/userWrapper/Wrapper";
import Spinner from "react-bootstrap/Spinner";
import { useState, useEffect } from "react";
import { message } from "antd";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useRef } from "react";
import "./solarstore.css";

function StoreSearch() {
  const [data, setData] = useState([]);

  const location = useLocation();
  const state = location.state?.search;

  const [search, setSearch] = useState(state ? state : "");
  const [available, setAvailable] = useState(false);
  const [loading, setLoading] = useState(false);

  const inputRef = useRef(null);

  //Function to get all store products
  const handleSearch = async () => {
    setAvailable(false);
    setLoading(true);
    setData([]);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/searchProduct`,
        { search },
        {
          headers: { Authorization: `Bearer 6455ef91d108f` },
        }
      );
      setLoading(false);
      if (res.data.success) {
        setData(res.data.data);
        setAvailable(false);
      } else {
        setAvailable(true);
      }
    } catch (error) {
      setLoading(false);
      setAvailable(false);
      message.error(error.message);
    }
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    setAvailable(false);
    setLoading(true);
    setData([]);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/searchProduct`,
        { search },
        {
          headers: { Authorization: `Bearer 6455ef91d108f` },
        }
      );
      setLoading(false);
      if (res.data.success) {
        setData(res.data.data);
        setAvailable(false);
      } else {
        setAvailable(true);
      }
    } catch (error) {
      setLoading(false);
      setAvailable(false);
      message.error(error.message);
    }
  };
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (search) {
      handleSearch();
    }
  }, [search]);

  return (
    <div>
      <Wrapper>
        <DashNav title="Search Store" />

        <form onSubmit={handleSearchSubmit} className="store-search-page">
          <input
            type="text"
            ref={inputRef}
            placeholder="Search Product.."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </form>

        {available ? (
          <div className="store-container d-flex align-items-center justify-content-center">
            <span className=" d-flex align-items-center justify-content-center">
              <span>Product Not Found..</span>
            </span>
          </div>
        ) : (
          <>
            {loading ? (
              <div className="store-container d-flex align-items-center justify-content-center">
                <span className=" d-flex align-items-center justify-content-center">
                  <Spinner
                    animation="border"
                    role="status"
                    size="lg"
                    className="store-spinner"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                  <span style={{ marginLeft: "10px" }}>Loading..</span>
                </span>
              </div>
            ) : (
              <div className="store-container d-flex align-items-start flex-wrap">
                {data?.map((item) => {
                  return <StoreItem data={item} key={item.productToken} />;
                })}
              </div>
            )}
          </>
        )}
      </Wrapper>
    </div>
  );
}

export default StoreSearch;
