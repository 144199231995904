import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Result, Button, message } from 'antd';
import DashNav from '../../userComponents/dashNav/DashNav';
import Wrapper from '../../userComponents/userWrapper/Wrapper';
import './buyback.css';

function BuyBack() {
	const [img, setImg] = useState(null);
	const [imgSrc, setImgSrc] = useState('');
	const [imgLoader, setImgLoader] = useState(false);
	const [productData, setProductData] = useState(null);
	const [search, setSearch] = useState('');
	const [loading, setLoading] = useState(false);
	const [categories, setCategories] = useState([]);
	const userData = JSON.parse(localStorage.getItem('userData'));
	const app_token =
		'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';
	const [kycModal, setKycModal] = useState(
		userData?.kycStatus === false ? true : false
	);
	const navigate = useNavigate();

	// Function to fetch all categories
	const fetchCategories = () => {
		axios
			.post(
				`${process.env.REACT_APP_BASE_URL}/admin/fetchCategory`,
				{
					apptoken: app_token,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			)
			.then((res) => {
				if (res.data.success) {
					setCategories(res.data.data);
				} else {
					message.info(res.data.message);
				}
			})
			.catch((err) => {
				message.error(err.message);
				setCategories(categories);
			});
	};

	const handleImageUpload = async () => {
		setImgLoader(true);

		try {
			const formData = new FormData();
			formData.append('image', img);

			const response = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/uploadImage`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer 6455ef91d108f`,
					},
				}
			);
			setImgLoader(false);
			if (response.data.success) {
				message.success('Image uploaded successfully!');
				setImgSrc(response.data.data.image);
			} else {
				message.info(response.data.message);
			}
		} catch (error) {
			setImgLoader(false);
			message.error(error.message);
		}
	};

	// useEffect to immediately generate blob once an image is selected
	useEffect(() => {
		if (img) {
			handleImageUpload();
		}
	}, [img]);
	// useEffect to immediately generate blob once an image is selected
	useEffect(() => {
		fetchCategories();
	}, []);

	// function to handle input data change
	const handleChange = (e) => {
		const value = e.target.value;
		setProductData({ ...productData, [e.target.name]: value });
	};

	// Function to handle uploading of product data to database
	const handleProductUpload = async (e) => {
		e.preventDefault();
		if (!imgSrc) {
			message.info('Kindly select an image!');
			return;
		}
		setLoading(true);

		try {
			const res = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/postAds`,
				{
					...productData,
					pimage: imgSrc,
					apptoken: app_token,
					usertoken: userData.usertoken,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
			setLoading(false);
			getLocation();
			if (res.data.success) {
				message.success(res.data.message);
			} else {
				message.warning(res.data.message);
			}
		} catch (error) {
			setLoading(false);
			message.error(error.message);
		}
	};

	function getLocation() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(showPosition);
		} else {
			message.info('Geolocation is not supported by this browser.');
		}
	}

	function showPosition(position) {
		logActivity(position.coords.latitude, position.coords.longitude);
	}

	const logActivity = async (lat, lon) => {
		try {
			await axios.post(
				`https://test.solarcredit.io/v0.1/api/logUserActivity`,
				{
					usertoken: userData.usertoken,
					logs: 'Uploaded Buyback',
					longtitude: lon,
					latitude: lat,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
		} catch (error) {
			console.log(error.message);
		}
	};

	return (
		<div>
			<Wrapper>
				<DashNav
					title='Post your ad.'
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					placeholder={'Search e.g solar panel'}
				/>
				<div className='buyback-case'>
					<form onSubmit={handleProductUpload}>
						<div className='buyback-formitem'>
							<label>Category</label>
							<select name='catid' onChange={handleChange} required>
								<option>Select Category</option>
								{categories.map((cat) => {
									return (
										<option key={cat.catid} value={cat.catid}>
											{cat.catname}
										</option>
									);
								})}
							</select>
						</div>
						<div className='buyback-formitem'>
							<label>Location</label>
							<textarea
								name='location'
								placeholder='Location'
								onChange={handleChange}
								required
								cols='30'
								rows='10'></textarea>
						</div>
						<div className='buyback-formitem'>
							<label>Title</label>
							<input
								type='text'
								maxlength='25'
								name='pname'
								required
								onChange={handleChange}
								placeholder='Title'
							/>
						</div>
						<div className='buyback-formitem'>
							<label>Description</label>
							<textarea
								name='pdesc'
								placeholder='Description'
								onChange={handleChange}
								required
								cols='30'
								rows='10'></textarea>
						</div>
						<div className='buyback-formitem'>
							<label>Brand</label>
							<input
								type='text'
								name='brand'
								onChange={handleChange}
								placeholder='Brand'
								required
							/>
						</div>
						<div className='buyback-formitem'>
							<label>Condition</label>
							<select name='condition' onChange={handleChange} required>
								<option>Click to select</option>
								{/* <option value='new'>New</option> */}
								<option value='used'>Used</option>
							</select>
						</div>

						<div className='buyback-formitem'>
							<label>Volt</label>
							<input
								type='text'
								required
								onChange={handleChange}
								name='volt'
								placeholder='volt of the prduct.'
							/>
						</div>
						<div className='buyback-formitem'>
							<label>Unit</label>
							<select name='unit' onChange={handleChange} required>
								<option>Click to select</option>
								<option value='AMPS'>AMPS</option>
								<option value='WATTS'>WATTS</option>
								{/* <option value='KILOWATTS'>KILOWATTS</option> */}
							</select>
						</div>
						<div className='buyback-formitem'>
							<label>Unit size</label>
							<input
								type='number'
								required
								onChange={handleChange}
								name='size'
								placeholder='size of the solar gadget e.g 1000W, 20A etc.'
							/>
						</div>
						<div className='buyback-formitem'>
							<label>Product Price (incl delivery)</label>
							<input
								type='number'
								required
								onChange={handleChange}
								name='price'
								placeholder='Product Price incl delivery  (#)'
							/>
						</div>

						<div className='buyback-formitem'>
							<label>Quantity</label>
							<input
								type='number'
								required
								onChange={handleChange}
								name='pquantity'
								placeholder='Available Quantity'
							/>
						</div>
						{userData.userType === 'Users' && (
							<div className='buyback-formitem'>
								<label>Phone number</label>
								<input
									type='number'
									onChange={handleChange}
									name='phone'
									placeholder='Phone number'
									required
								/>
							</div>
						)}

						<div className='buyback-formitem'>
							<label>Add photo:</label>
							<label
								style={{ backgroundImage: imgSrc }}
								className='buybackprofilePhoto'
								htmlFor='product_photo'>
								{imgLoader
									? 'Uploading...'
									: 'Click to add picture of solar gadget'}
							</label>
							<input
								required
								onChange={(e) => setImg(e.target.files[0])}
								type='file'
								style={{ display: 'none' }}
								id='product_photo'
								placeholder='face photograph'
								name='product_photo'
							/>
						</div>

						{imgSrc && (
							<div className='buyback-formitem imageViewBox-main-img d-flex flex-row align-items-center justify-content-between'>
								<div className='imageViewBox'>
									<img src={`https://test.solarcredit.io/uploads/${imgSrc}`} />
								</div>
							</div>
						)}
						<div className='' style={{ marginBottom: '20px' }}>
							<label>
								<input type='checkbox' name='terms' required /> I agree to the
								terms and conditions
							</label>
						</div>
						<button>{loading ? 'Submitting' : 'Submit'}</button>
					</form>
				</div>

				<div className='kyc-notification-modal'>
					<Modal
						closable={false}
						visible={kycModal}
						onOk={() => setKycModal(false)}
						onCancel={() => setKycModal(false)}
						footer={null}
						wrapClassName='kyc-notification-modal'
						maskClosable={false}>
						<Result
							status='warning'
							title="Can't access this resource without completing KYC Verification."
							extra={[
								<Button
									key='completeKycBtn'
									onClick={() =>
										navigate('/user/kyc', { state: { kyc: true } })
									}
									type='primary'>
									Complete KYC Verification
								</Button>,
								<Button
									key='closeModalBtn'
									onClick={() => setKycModal(false)}
									type='default'
									style={{ position: 'absolute', top: '10px', left: '10px' }}>
									Close
								</Button>,
							]}
						/>
					</Modal>
				</div>
			</Wrapper>
		</div>
	);
}

export default BuyBack;
