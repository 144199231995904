import React from 'react';
import AdminWrapper from '../../adminComponents/adminWrapper/AdminWrapper';
import DashNav from '../../../usersDashboard/userComponents/dashNav/DashNav';
import './upload.css';
import { useState } from 'react';
import axios from 'axios';

const Upload = () => {
	const [file, setFile] = useState('');
	const app_token =
		'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';

	const handleChange = (e) => {
		if (e.target.name === 'file') {
			setFile(e.target.files[0]);
		}
	};

	const handleUpload = async (e) => {
		e.preventDefault();

		if (file) {
			try {
				const formData = new FormData();
				formData.append('applianace', file, file.name);
				formData.append('apptoken', app_token);
				const response = await axios.post(
					`${process.env.REACT_APP_BASE_URL}/admin/uploadLoadItems`,
					formData,
					{
						headers: {
							Authorization: `Bearer 6455ef91d108f`,
						},
					}
				);

				console.log('File uploaded successfully:');
			} catch (error) {
				console.error('Error uploading file:', error);
			}
		} else {
			console.error('No file selected for upload.');
		}
	};

	return (
		<div>
			<AdminWrapper>
				<DashNav title='Upload File' />

				<div className='file-upload'>
					<label className='file-label' htmlFor='file_input'>
						Upload file
					</label>
					<input
						className='file-input'
						aria-describedby='file_input_help'
						id='file_input'
						type='file'
						name='file'
						onChange={handleChange}
					/>
					<p className='file-help' id='file_input_help'>
						Excel files .xlsx.
					</p>
					<button onClick={handleUpload}>Upload</button>
				</div>
			</AdminWrapper>
		</div>
	);
};

export default Upload;
