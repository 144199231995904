import React from 'react'
import { Navigate } from 'react-router-dom'

function AdminProtect({ children }) {
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (!userData) {
        return <Navigate to='/login' />
    }
    if (userData.userType === 'Admin') {
        return (
            <div>{children}</div>
        )
    } else {
        return <Navigate to='/user/dashboard' />
    }
}

export default AdminProtect