import React from 'react'
import './hero.css'
const Hero = () => {
    return (
        <div>
            <div className="hero">
                <div className="heroinner">
                    <h1 className='text-white'>Book an Appointment today</h1>
                    <p>Sign up today and connect with us to
                        review your better solar option,either
                        on the phone or at your home.</p>
                    <button>Reach us now</button>
                </div>
            </div>
        </div>
    )
}

export default Hero