import React, { useEffect, useState } from 'react';
import { Tabs, message } from 'antd';
import './settings.css';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
const Verifications = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const userData = JSON.parse(localStorage.getItem('userData'));
	const app_token =
		'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';
	const [kycData, setKycData] = useState(null);
	const [idData, setIdData] = useState(null);
	const [addressData, setAddressData] = useState(null);
	const [img, setImg] = useState(null);
	const [imgSrc, setImgSrc] = useState('');
	const [imgLoader, setImgLoader] = useState(false);

	// function to change image file to blob
	const handleImageUpload = async () => {
		setImgLoader(true);

		try {
			const formData = new FormData();
			formData.append('image', img);

			const response = await axios.post(
				'https://test.solarcredit.io/v0.1/api/uploadImage',
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer 6455ef91d108f`,
					},
				}
			);
			setImgLoader(false);
			if (response.data.success) {
				setImgSrc(response.data.data.image);
			} else {
				message.info(response.data.message);
			}
		} catch (error) {
			setImgLoader(false);
			message.error(error.message);
		}
	};

	// useEffect to immediately generate blob once an image is selected
	useEffect(() => {
		if (img) {
			handleImageUpload();
		}
	}, [img]);

	// function to handle input data change
	const handleChange = (e) => {
		const value = e.target.value;
		setKycData({ ...kycData, [e.target.name]: value });
	};

	const handleIdChange = (e) => {
		const value = e.target.value;
		setIdData({ ...idData, [e.target.name]: value });
	};

	const handleAddressChange = (e) => {
		const value = e.target.value;
		setAddressData({ ...addressData, [e.target.name]: value });
	};

	const handleKycUpdate = async (e) => {
		e.preventDefault();
		// if (!imgSrc) {
		// 	message.info('Kindly add face photo!');
		// 	return;
		// }
		setLoading(true);
		try {
			const res = await axios.post(
				'https://test.solarcredit.io/v0.1/api/createKyc',
				{
					...kycData,
					apptoken: app_token,
					usertoken: userData.usertoken,
					renitoken: userData.renitoken,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
			if (res.data.success === true) {
				getUserData();
			} else {
				setLoading(false);
				message.warning(res.data.message);
			}
		} catch (error) {
			setLoading(false);
			message.error(error.message);
		}
	};

	const handleIdUpdate = async (e) => {
		e.preventDefault();
		if (!imgSrc) {
			message.info('Kindly add face photo!');
			return;
		}
		setLoading(true);
		try {
			const res = await axios.post(
				'https://test.solarcredit.io/v0.1/api/kyc/updateUserId',
				{
					...idData,
					photo: imgSrc,
					usertoken: userData.usertoken,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
			if (res.data.success === true) {
				getUserData();
			} else {
				setLoading(false);
				message.warning(res.data.message);
			}
		} catch (error) {
			setLoading(false);
			message.error(error.message);
		}
	};

	const handleAddressUpdate = async (e) => {
		e.preventDefault();
		if (!imgSrc) {
			message.info('Kindly add face photo!');
			return;
		}
		setLoading(true);
		try {
			const res = await axios.post(
				'https://test.solarcredit.io/v0.1/api/kyc/updateUserAddress',
				{
					...addressData,
					photo: imgSrc,
					usertoken: userData.usertoken,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
			if (res.data.success === true) {
				getUserData();
			} else {
				setLoading(false);
				message.warning(res.data.message);
			}
		} catch (error) {
			setLoading(false);
			message.error(error.message);
		}
	};

	const getUserData = async () => {
		try {
			const res = await axios.post(
				'https://test.solarcredit.io/v0.1/api/getUserdata',
				{
					apptoken: app_token,
					usertoken: userData.usertoken,
					// renitoken: userData.renitoken,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);

			if (res.data.success) {
				message.success('Kyc Verification Successful');
				localStorage.setItem('userData', JSON.stringify(res.data.data));
				setLoading(false);
				navigate(-1);
			} else {
				message.info(res.data.message);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const { TabPane } = Tabs;

	const [activeTab, setActiveTab] = useState('kyc');

	const handleTabChange = (key) => {
		setActiveTab(key);
	};

	return (
		<div className=''>
			<Tabs activeKey={activeTab} onChange={handleTabChange}>
				<TabPane tab='KYC' key='kyc'>
					<div className='securitysettings'>
						<form
							onSubmit={handleKycUpdate}
							className='d-flex flex-column align-items-center justify-content-center'>
							<div className=''>
								<label htmlFor=''>Full Name:</label>
								<input
									type='text'
									name='fname'
									onChange={handleChange}
									required
								/>
							</div>
							<div className=''>
								<label htmlFor=''>Occupation:</label>
								<input
									type='text'
									name='occupation'
									onChange={handleChange}
									required
								/>
							</div>
							<div className=''>
								<label htmlFor=''>BVN No:</label>
								<input
									type='number'
									name='bvn'
									onChange={handleChange}
									required
								/>
							</div>
							<button>{loading ? 'Updating' : 'Update'}</button>
						</form>
					</div>
				</TabPane>
				<TabPane tab='Id Card' key='id'>
					<div className='securitysettings'>
						<form
							onSubmit={handleIdUpdate}
							className='d-flex flex-column align-items-center justify-content-center'>
							<div className='kyc-form-group'>
								<label htmlFor=''>Means of Identification</label>
								<select name='type' onChange={handleIdChange} required>
									<option value='NIN'>Click to select</option>
									<option value='NIN'>NIN</option>
									<option value="Driver's License">Driver's License</option>
								</select>
							</div>
							<div className=''>
								<label htmlFor=''>Identity No:</label>
								<input
									type='number'
									name='number'
									onChange={handleIdChange}
									required
								/>
							</div>
							<div className='kyc-form-group'>
								<label htmlFor=''> Please input a copy of your Id Card:</label>
								<label class='profilePhoto' htmlFor='profilePhoto'>
									{' '}
									{imgLoader
										? 'Uploading...'
										: 'Click to add your Id Card Image'}
								</label>
								<input
									type='file'
									style={{ display: 'none' }}
									name='photo'
									onChange={(e) => setImg(e.target.files[0])}
									id='profilePhoto'
									required
								/>
							</div>
							{imgSrc && (
								<div className='kyc-form-group imageViewBox-main-img d-flex flex-row align-items-center justify-content-between'>
									<div className='imageViewBox'>
										<img
											src={`https://test.solarcredit.io/uploads/${imgSrc}`}
										/>
									</div>
									<span>{img?.name}</span>
								</div>
							)}
							<button>{loading ? 'Updating' : 'Update'}</button>
						</form>
					</div>
				</TabPane>

				<TabPane tab='Address' key='address'>
					<div className='securitysettings'>
						<form
							className='d-flex flex-column align-items-center justify-content-center'
							onSubmit={handleAddressUpdate}>
							<div className=''>
								<label htmlFor=''>Address:</label>
								<textarea
									name='address'
									cols='40'
									rows='5'
									onChange={handleAddressChange}
									required></textarea>
							</div>
							<div className='kyc-form-group'>
								<label htmlFor=''> Please input a copy of your Id Card:</label>
								<label class='profilePhoto' htmlFor='profilePhoto'>
									{' '}
									{imgLoader
										? 'Uploading...'
										: 'Click to add your Id Card Image'}
								</label>
								<input
									type='file'
									style={{ display: 'none' }}
									name='photo'
									onChange={(e) => setImg(e.target.files[0])}
									id='profilePhoto'
									required
								/>
							</div>
							{imgSrc && (
								<div className='kyc-form-group imageViewBox-main-img d-flex flex-row align-items-center justify-content-between'>
									<div className='imageViewBox'>
										<img
											src={`https://test.solarcredit.io/uploads/${imgSrc}`}
										/>
									</div>
									<span>{img?.name}</span>
								</div>
							)}
							<button>{loading ? 'Updating' : 'Update'}</button>
						</form>
					</div>
				</TabPane>
			</Tabs>
		</div>
	);
};

export default Verifications;
