import { message } from 'antd';
import React, { useState } from 'react';
import DashNav from '../../userComponents/dashNav/DashNav';
import Wrapper from '../../userComponents/userWrapper/Wrapper';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './settings.css';
import Verifications from './Verifications';
function Settings() {
	const [position, setPosition] = useState('security');
	const [loading, setLoading] = useState(false);
	const [password, setPassword] = useState(null);
	const [email, setEmail] = useState('');
	const userData = JSON.parse(localStorage.getItem('userData'));
	const app_token =
		'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';
	const navigate = useNavigate();
	// function to handle input data change
	const handleChange = (e) => {
		const value = e.target.value;
		setPassword({ ...password, [e.target.name]: value });
	};

	//Function to logout
	const logout = () => {
		localStorage.removeItem('userData');
		navigate('/login');
	};

	// Function to handle password change
	const handlePasswordChange = async (e) => {
		e.preventDefault();
		if (password.npword === email) {
			setLoading(true);
			try {
				const res = await axios.post(
					'https://test.solarcredit.io/v0.1/api/updatePassword',
					{
						apptoken: app_token,
						usertoken: userData.usertoken,
						...password,
					},
					{
						headers: { Authorization: `Bearer 6455ef91d108f` },
					}
				);
				setLoading(false);
				if (res.data.success) {
					message.success(res.data.message);
				} else {
					message.warning(res.data.message);
				}
			} catch (error) {
				setLoading(false);
				message.error(error.message);
			}
		} else {
			message.info('Fields do not match');
		}
	};

	//Function to handle newsletter Subscription
	const handleNewsletter = (e) => {
		e.preventDefault();
	};

	const handleVerificationClick = () => {
		setPosition('verification');
	};

	return (
		<div>
			<Wrapper>
				<DashNav title='Settings' />
				<div className='settings-main d-flex align-items-start justify-content-between'>
					<div className='settings-left'>
						<ul className='d-flex flex-column align-items-start justify content-start'>
							{/* <li onClick={() => setPosition('news')} className={position === 'news' ? 'active' : ''}>News/Article</li> */}
							<li
								onClick={() => setPosition('security')}
								className={position === 'security' ? 'active' : ''}>
								Security
							</li>
							<li
								onClick={() => setPosition('verification')}
								className={position === 'verification' ? 'active' : ''}>
								Verification Update
							</li>
							<li onClick={() => navigate('/user/profile')}>Profile</li>
							<li onClick={() => logout()}>Logout</li>
						</ul>
					</div>

					{position !== 'verification' && (
						<div className='settings-right'>
							<div className='settings-header'>
								<h1>
									{position === 'news'
										? 'News/article settings'
										: 'Security settings'}
								</h1>
								<p>
									{position === 'news'
										? 'Interested in our newsletters and articles?'
										: 'Update your security settings.'}
								</p>
							</div>
							<div className='settings-body'>
								{position === 'news' ? (
									<div className='newssettings'>
										<h3>NEWSLETTER</h3>
										<span>Subscribe to our mailing list</span>
										<form
											onSubmit={handleNewsletter}
											className='d-flex align-items-center'>
											<input
												type='text'
												onChange={(e) => setEmail(e.target.value)}
												placeholder='Email'
												required
											/>
											<button>
												{loading ? (
													<Spinner
														animation='border'
														role='status'
														size='sm'
														className='store-spinner'>
														<span className='visually-hidden'>Loading...</span>
													</Spinner>
												) : (
													'Subscribe'
												)}
											</button>
										</form>
									</div>
								) : (
									<div className='securitysettings'>
										<h3>Change Password</h3>
										<form
											onSubmit={handlePasswordChange}
											className='d-flex flex-column align-items-center justify-content-center'>
											<label>
												Old password
												<input
													type='password'
													onChange={handleChange}
													name='fpword'
													required
												/>
											</label>
											<label>
												New password
												<input
													type='password'
													onChange={handleChange}
													name='npword'
													required
												/>
											</label>
											<label>
												Confirm new password
												<input
													type='password'
													onChange={(e) => setEmail(e.target.value)}
													required
												/>
											</label>
											<button>{loading ? 'CHANGING..' : 'CHANGE'}</button>
										</form>
									</div>
								)}
							</div>
						</div>
					)}

					{position === 'verification' && (
						// Render content for verification
						<div className='settings-right'>
							<Verifications />
						</div>
					)}
				</div>
			</Wrapper>
		</div>
	);
}

export default Settings;
