import React from 'react';
import UsersNavLinks from './UsersNavLinks';
import { CloseOutlined } from '@ant-design/icons';
const UsersSideNav = ({ adminPages }) => {
	const closeNav = (e) => {
		document.getElementById('mySidenav').style.width = '0';
	};

	return (
		<>
			<nav>
				<div id='mySidenav' className='sidenav shadow'>
					<a
						href='javascript:void(0)'
						className='closebtn'
						onClick={(e) => closeNav(e)}>
						<CloseOutlined />
					</a>

					<UsersNavLinks adminPages={adminPages} />
				</div>
			</nav>
		</>
	);
};

export default UsersSideNav;
