import React, { useEffect, useState } from 'react';
import AdminWrapper from '../../adminComponents/adminWrapper/AdminWrapper';
import FeaturedInfo from '../../adminComponents/featuredinfo/FeaturedInfo';
import DashNav from '../../../usersDashboard/userComponents/dashNav/DashNav';
import axios from 'axios';
import { Tag, message, Table } from 'antd';
import './admindashboard.css';

function AdminDashboard() {
	const userData = JSON.parse(localStorage.getItem('userData'));
	const app_token =
		'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';
	const [transactHistory, setTransactHistory] = useState([]);

	const columns = [
		{
			title: 'Date',
			dataIndex: 'time',
			key: 'time',
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
		},

		{
			title: 'Payment Type',
			key: 'type',
			dataIndex: 'type',
			render: (tag) => (
				<Tag color={tag === 'Credit' ? 'green' : 'volcano'} key={tag}>
					{tag.toUpperCase()}
				</Tag>
			),
		},
	];

	const getTransHistory = async () => {
		try {
			const res = await axios.post(
				`https://test.solarcredit.io/v0.1/api/admin/adminFinancialHistory`,
				{
					apptoken: app_token,
					usertoken: userData.usertoken,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
			if (res.data.success) {
				setTransactHistory(res.data.data);
			} else {
				message.info(res.data.message);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	useEffect(() => {
		getTransHistory();
	}, []);

	return (
		<div>
			<AdminWrapper>
				<DashNav title='Dashboard' />
				<div className='home'>
					<FeaturedInfo />
					<div className='payment-history' style={{ marginTop: '50px' }}>
						All Transactions
						<Table
							columns={columns}
							pagination={{
								defaultPageSize: 8,
								total: transactHistory.length,
								pageSize: 8,
							}}
							dataSource={transactHistory}
						/>
					</div>
				</div>
			</AdminWrapper>
		</div>
	);
}

export default AdminDashboard;
