import React, { useRef } from "react";
import logo from "../../images/logo.png"
import { Link } from "react-router-dom";
import "./navbar.css";

const Navbar = () => {
    const navRef = useRef(null);
    const userData = JSON.parse(localStorage.getItem('userData'))
    const openNav = () => {
        navRef.current.style.width = `${240}px`;
    };
    const closeNav = () => {
        navRef.current.style.width = `${0}px`;
    };

    return (
        <div>
            <nav className="d-flex justify-content-center align-items-center navbar shadow-sm navbar-expand-lg navbar-light fixed-top">
                <div className="nav-container">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} alt="" />
                    </Link>
                    <button className="navbar-toggler" type="button" onClick={openNav}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    {/* <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 ml-auto w-100">
                            <li className="nav-item">
                                <Link className="nav-link" to="/about">
                                    Solar home systems
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/login">
                                    Solar Roofs
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/signup">
                                    Pay installmentally
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/signup">
                                    About us
                                </Link>
                            </li>
                        </ul>
                    </div> */}
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        {userData ?
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ml-auto w-100 nav-right d-flex align-items-center justify-content-end">

                                <li className="nav-item">
                                    <Link className="nav-link" to="/user/dashboard">
                                        Dashboard
                                    </Link>
                                </li>
                            </ul>
                            : <ul className="navbar-nav me-auto mb-2 mb-lg-0 ml-auto w-100 nav-right d-flex align-items-center justify-content-end">

                                <li className="nav-item">
                                    <Link className="nav-link" to="/login">
                                        Login
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link nav-btn text-white d-flex justify-content-center align-items-center" to="/register">
                                        Register
                                    </Link>
                                </li>
                            </ul>}
                    </div>
                </div>
            </nav>

            <div id="mySidebar" className="sidebar" ref={navRef}>
                <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>
                    &times;
                </a>


                {userData ?
                    <Link to="/user/dashboard" onClick={closeNav}>
                        Dashboard
                    </Link>
                    :
                    <>
                        <Link to="/login" onClick={closeNav}>
                            Login
                        </Link>{" "}
                        <br />
                        <Link to="/register" onClick={closeNav}>
                            Sign Up
                        </Link>
                        <br />
                    </>
                }
            </div>
        </div>
    );
};

export default Navbar;
