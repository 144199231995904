import React, { useEffect, useState } from 'react';
import './featuredinfo.css';
import { message } from 'antd';
import axios from 'axios';
const FeaturedInfo = () => {
	const [data, setData] = useState({});

	//Function to get app info
	const getAppInfo = () => {
		axios
			.post(
				`https://test.solarcredit.io/v0.1/api/admin/personalisedDashboard`,
				{},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			)
			.then((res) => {
				if (res.data.success === true) {
					setData(res.data.data);
				}
			})
			.catch((err) => {
				message.error(err.message);
			});
	};

	useEffect(() => {
		getAppInfo();
	}, []);

	return (
		<div className='featured'>
			<div className='featuredItem'>
				<span className='featuredTitle'>Total User Balance</span>
				<div className='featuredMoneyContainer'>
					<span className='featuredMoney'>
						{data.getAllUsersAccountWallet ? data?.getAllUsersAccountWallet : 0}
					</span>
					<span className='featuredMoneyRate featuredIcon'>Naira</span>
				</div>
				<span className='featuredSub'>Total users balance</span>
			</div>
			<div className='featuredItem'>
				<span className='featuredTitle'>Solarcredit Balance</span>
				<div className='featuredMoneyContainer'>
					<span className='featuredMoney'>
						{data.getPlatformWalletBalance ? data?.getPlatformWalletBalance : 0}
					</span>
					<span className='featuredMoneyRate featuredIcon'>Naira</span>
				</div>
				<span className='featuredSub'>Total solarcredit balance</span>
			</div>
			<div className='featuredItem'>
				<span className='featuredTitle'>Total Users</span>
				<div className='featuredMoneyContainer'>
					<span className='featuredMoney'>
						{data.getAllUsersCount ? data?.getAllUsersCount : 0}
					</span>
					<span className='featuredMoneyRate featuredIcon'>users</span>
				</div>
				<span className='featuredSub'>Total users count</span>
			</div>
			<div className='featuredItem'>
				<span className='featuredTitle'>Total Products</span>
				<div className='featuredMoneyContainer'>
					<span className='featuredMoney'>
						{data.getAllProductCount ? data?.getAllProductCount : 0}
					</span>
					<span className='featuredMoneyRate featuredIcon'>products</span>
				</div>
				<span className='featuredSub'>Total products count</span>
			</div>
			<div className='featuredItem'>
				<span className='featuredTitle'>Total Orders</span>
				<div className='featuredMoneyContainer'>
					<span className='featuredMoney'>
						{data.getAllProductPurchasedOnce
							? data?.getAllProductPurchasedOnce
							: 0}
					</span>
					<span className='featuredMoneyRate featuredIcon'>orders</span>
				</div>
				<span className='featuredSub'>Total orders</span>
			</div>

			<div className='featuredItem'>
				<span className='featuredTitle'>Pending Loans</span>
				<div className='featuredMoneyContainer'>
					<span className='featuredMoney'>
						{data.getAllUnapprovedLoan ? data?.getAllUnapprovedLoan : 0}
					</span>
					<span className='featuredMoneyRate featuredIcon'>loans</span>
				</div>
				<span className='featuredSub'>Total pending loans</span>
			</div>
			<div className='featuredItem'>
				<span className='featuredTitle'>Pending Products</span>
				<div className='featuredMoneyContainer'>
					<span className='featuredMoney'>
						{data.getAllUnapprovedProductCount
							? data?.getAllUnapprovedProductCount
							: 0}
					</span>
					<span className='featuredMoneyRate featuredIcon'>products</span>
				</div>
				<span className='featuredSub'>Total pending service requests</span>
			</div>
			<div className='featuredItem'>
				<span className='featuredTitle'>Paid Once Products</span>
				<div className='featuredMoneyContainer'>
					<span className='featuredMoney'>
						{data.getAllProductPaidOnce ? data?.getAllProductPaidOnce : 0}
					</span>
					<span className='featuredMoneyRate featuredIcon'>products</span>
				</div>
				<span className='featuredSub'>Total paid once products</span>
			</div>
			<div className='featuredItem'>
				<span className='featuredTitle'>Paid Installmental </span>
				<div className='featuredMoneyContainer'>
					<span className='featuredMoney'>
						{data.getAllPendingInsallmentallProduct
							? data?.getAllPendingInsallmentallProduct
							: 0}
					</span>
					<span className='featuredMoneyRate featuredIcon'>products</span>
				</div>
				<span className='featuredSub'>Total installmental paid products</span>
			</div>
		</div>
	);
};

export default FeaturedInfo;
