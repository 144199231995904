import React, { useState, useEffect } from 'react';
import {
	MdPermIdentity,
	MdCalendarToday,
	MdLocationPin,
	MdMailOutline,
	MdPhone,
} from 'react-icons/md';
import { Link, useLocation, useParams } from 'react-router-dom';
import DashNav from '../../../usersDashboard/userComponents/dashNav/DashNav';
import AdminWrapper from '../../adminComponents/adminWrapper/AdminWrapper';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { Spinner } from 'react-bootstrap';
import { message } from 'antd';
import logo from '../../../../images/logo.png';
import axios from 'axios';
import './editusers.css';

function EditUsers() {
	const location = useLocation();
	const userDataMain = location?.state;
	const { usertoken } = useParams();
	const [user, setUser] = useState(userDataMain);
	const [userExtra, setUserExtra] = useState({});
	const [loading, setLoading] = useState(false);

	const app_token =
		'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';

	//Function to get user
	const getUser = () => {
		axios
			.post(
				`https://test.solarcredit.io/v0.1/api/admin/getAllUserData`,
				{ apptoken: app_token, usertoken },
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			)
			.then((res) => {
				if (res.data.success === true) {
					setUserExtra(res.data?.kycData);
					setUser(res.data.userData);
				}
			})
			.catch((err) => {
				message.error(err.message);
			});
	};

	//Function to make user admin
	const makeUserAdmin = (e) => {
		e.preventDefault();
		setLoading(true);
		axios
			.post(
				`https://test.solarcredit.io/v0.1/api/admin/changeStatus`,
				{
					// apptoken: app_token,
					mail: user.mail,
					userType: user.userType === 'Admin' ? 'Users' : 'Admin',
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			)
			.then((res) => {
				setLoading(false);
				if (res.data.success === true) {
					getUser();
					message.success(res.data.message);
				}
			})
			.catch((err) => {
				setLoading(false);
				message.error(err.message);
			});
	};

	useEffect(() => {
		getUser();
	}, [usertoken]);

	return (
		<AdminWrapper>
			<DashNav title='Edit User' />
			<div className='user'>
				<div className='userTitleContainer d-flex align-items-center justify-content-between'>
					<Link to={-1}>
						<AiOutlineArrowLeft className='edituserIcon' />
					</Link>
				</div>

				<div className='userContainer'>
					<div className='userShow'>
						<div className='userShowTop'>
							<img
								src={userExtra?.photo ? userExtra.photo : logo}
								alt=''
								className='userShowImg'
							/>

							<div className='userShowTopTitle'>
								<span className='userShowUsername'>{user.fname}</span>
								<span className='userShowUserTitle'>
									{userExtra?.profession
										? userExtra.profession
										: 'unknown profession'}
								</span>
							</div>
						</div>
						<div className='userShowBottom'>
							<span className='userShowTitle'>Account Details</span>
							<div className='userShowInfo'>
								<MdPermIdentity className='userShowIcon' />
								<span className='userShowInfoTitle'>{user.fname}</span>
							</div>
							<div className='userShowInfo'>
								<MdCalendarToday className='userShowIcon' />
								<span className='userShowInfoTitle'>
									{userDataMain?.created}
								</span>
							</div>
							<span className='userShowTitle'>Contact Details</span>
							<div className='userShowInfo'>
								<MdPhone className='userShowIcon' />
								<span className='userShowInfoTitle'>{user.phone}</span>
							</div>
							<div className='userShowInfo'>
								<MdMailOutline className='userShowIcon' />
								<span className='userShowInfoTitle'>{user.mail}</span>
							</div>
							<div className='userShowInfo'>
								<MdLocationPin className='userShowIcon' />
								<span className='userShowInfoTitle'>Nigeria</span>
							</div>
						</div>
					</div>

					<div className='userUpdate'>
						<span className='userUpdateTitle'>KYC Details</span>

						<div className='userShowBottom'>
							<span className='userShowTitle'>Identifications</span>
							<div className='userShowInfo'>
								<MdPermIdentity className='userShowIcon' />
								<span className='userShowInfoTitle'>{userExtra?.fname}</span>
							</div>
							<div className='userShowInfo'>
								<MdCalendarToday className='userShowIcon' />
								<span className='userShowInfoTitle'>
									ID NO: {userExtra?.identity_number}
								</span>
							</div>
							<span className='userShowTitle'>Address Details</span>
							<div className='userShowInfo'>
								<MdLocationPin className='userShowIcon' />
								<span className='userShowInfoTitle'>
									{userExtra?.kycAddress}
								</span>
							</div>
						</div>

						<form className='userUpdateForm w-100 ' onSubmit={makeUserAdmin}>
							<div className='userUpdateRight'>
								<button className='userUpdateButton'>
									{loading ? (
										<Spinner
											animation='border'
											role='status'
											size='sm'
											className='store-spinner editspinner'
											style={{ color: '#FEC52A' }}>
											<span className='visually-hidden'>Loading...</span>
										</Spinner>
									) : (
										<>
											{user.userType === 'Admin'
												? 'Dismiss Admin'
												: 'Make Admin'}
										</>
									)}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</AdminWrapper>
	);
}

export default EditUsers;
