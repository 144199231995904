import { message, Rate } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import { TbCurrencyNaira } from 'react-icons/tb';
import Wrapper from '../userWrapper/Wrapper';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import './storedetails.css';
import DashNav from '../dashNav/DashNav';
import { PaystackHookExample } from '../../../../util/Paystack';
function StoreDetails() {
	const { product_id } = useParams();
	const location = useLocation();
	const detailsStateData = location?.state;
	const [detailsData, setdetailsData] = useState(detailsStateData || {});
	const [pageLoader, setPageLoader] = useState(false);
	const [loading, setLoading] = useState(false);
	const [notFound, setNotFound] = useState(false);
	const userData = JSON.parse(localStorage.getItem('userData'));
	const app_token =
		'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';
	const navigate = useNavigate();

	const getProductDetails = async () => {
		setPageLoader(true);
		try {
			const res = await axios.post(
				'https://test.solarcredit.io/v0.1/api/fetchProductsByToken',
				{
					apptoken: app_token,
					productToken: product_id,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
			setPageLoader(false);
			if (res.data.success) {
				setdetailsData(res.data.data);
			} else {
				setNotFound(true);
				message.error(res.data.message);
			}
		} catch (error) {
			setPageLoader(false);
			message.error(error.message);
		}
	};
	const addProductToCart = async () => {
		setLoading(true);
		try {
			const res = await axios.post(
				'https://test.solarcredit.io/v0.1/api/addTocart',
				{
					apptoken: app_token,
					usertoken: userData.usertoken,
					productToken: detailsStateData.productToken,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
			setLoading(false);
			if (res.data.success) {
				message.success(res.data.message);
			} else {
				message.info(res.data.message);
			}
		} catch (error) {
			setLoading(false);
			message.error(error.message);
		}
	};

	useEffect(() => {
		if (!detailsStateData) {
			getProductDetails();
		}
	}, [product_id]);

	if (pageLoader) {
		return (
			<>
				<span className=' d-flex align-items-center justify-content-center'>
					<Spinner
						animation='border'
						role='status'
						size='lg'
						className='store-spinner'>
						<span className='visually-hidden'>Loading...</span>
					</Spinner>
					<span style={{ marginLeft: '10px' }}>Loading..</span>
				</span>
			</>
		);
	}
	if (notFound) {
		return (
			<>
				<span className=' d-flex align-items-center justify-content-center'>
					<Spinner
						animation='border'
						role='status'
						size='lg'
						className='store-spinner'>
						<span className='visually-hidden'>Loading...</span>
					</Spinner>
					<span style={{ marginLeft: '10px' }}>Page Not Found..</span>
				</span>
			</>
		);
	}

	return (
		<div>
			<Wrapper>
				<DashNav title={'Hybrid Inverter'} />

				<div className='storedetailsmain'>
					<div className='back-case d-flex align-items-center justify-content-start'>
						<div style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
							{' '}
							<BsFillArrowLeftCircleFill />
						</div>
					</div>
					<div className='storedetailscase d-flex align-items-start justify-content-between'>
						<div className='storedetailsleft d-flex align-items-start justify-content-between shadow'>
							<div className='storedetailsleftimg d-flex align-items-center justify-content-center'>
								<img src={detailsData.productImage} alt='' />
							</div>
							<div className='storedetailslefttext'>
								<h1>{detailsData.productname}</h1>
								<p>{detailsData?.productDesc}</p>
								<Rate value={2} />
								<div className='detailsprice'>
									<span className='detailsprice'>
										<TbCurrencyNaira />
										{detailsData.productPrice_thousand}
									</span>
								</div>
								<button onClick={() => addProductToCart()}>
									{loading ? (
										<span className=' d-flex align-items-center justify-content-center'>
											<Spinner
												animation='border'
												role='status'
												size='sm'
												className='store-spinner'>
												<span className='visually-hidden'>Loading...</span>
											</Spinner>
											<span style={{ marginLeft: '10px' }}>Adding..</span>
										</span>
									) : (
										'ADD TO CART'
									)}
								</button>
							</div>
						</div>
						<div className='storedetailsright shadow'>
							<span className='storedetailsrightspan'>ITEM SUMMARY</span>
							<div className='detailsamount'>
								<span>AMOUNT:</span> {detailsData.productPrice_thousand}
							</div>
							<div className='storedetailsbuttondiv'>
								<PaystackHookExample
									text={`PAY ONCE (#${detailsData.productPrice_thousand})`}
									amount={detailsData.productPrice}
									email={userData.mail}
									type='detail'
								/>
								<h5>OR</h5>
								<button
									onClick={() =>
										navigate('/user/payinstallmentally', {
											state: {
												product: [detailsData],
												amount: detailsData.productPrice,
											},
										})
									}>
									PAY INSTALLMENTALLY
								</button>
							</div>
						</div>
					</div>
				</div>
			</Wrapper>
		</div>
	);
}

export default StoreDetails;
