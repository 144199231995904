import {
	Autocomplete,
	Button,
	Chip,
	Stack,
	TextField,
	Typography,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useBreakpoints } from '../../theme/breakpoints';
import TextEditor from '../TextEditor';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const textFieldStyle = {
	'.MuiOutlinedInput-notchedOutline': {
		border: 'unset',
	},
	'& .Mui-focused .MuiOutlinedInput-notchedOutline': {
		border: 'unset',
	},
	'& input': {
		bgcolor: 'white',
		color: '#000',
		p: '5px 15px',
	},
	'& input::placeholder': {
		fontSize: '1rem',
		color: 'black',
	},
	'& .MuiButtonBase-root.MuiChip-root': {
		bgcolor: 'primary.main',
		color: 'neutral.50',
	},
	'& .MuiChip-root .MuiSvgIcon-root': {
		color: 'neutral.50',
	},
	'& .MuiChip-root .MuiSvgIcon-root:hover': {
		color: 'neutral.50',
	},
	'& .MuiFormLabel-root': {
		// color: '#000'
	},
};

const ComposeCard = () => {
	const { sm, md } = useBreakpoints();
	const [lists, setLists] = useState([]);
	const [subject, setSubject] = useState(''); // State for the subject field
	const [message, setMessage] = useState('');
	const [selectedListToken, setSelectedListToken] = useState([]);
	const [selectedListName, setSelectedListName] = useState([]);
	const app_token =
		'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === 'subject') {
			setSubject(value);
		}
	};

	useEffect(() => {
		const fetchlists = async () => {
			try {
				const res = await axios.get(
					`${process.env.REACT_APP_BASE_URL}/admin/getAllLists`,
					{
						headers: { Authorization: `Bearer 6455ef91d108f` },
					}
				);
				setLists(res.data.data);
				console.log(res.data.data);
			} catch (error) {
				console.log('Error Loading', error);
			}
		};
		// Call the function to fetch the data
		fetchlists();
	}, []);

	const handleListSelect = (event) => {
		// Get the selected list tokens
		const selectedTokens = event.target.value;
		setSelectedListToken(selectedTokens);
	};

	const handleSubmit = async () => {
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/admin/sendNewsLetterMail`,

				{
					subject,
					message,
					listoken: selectedListToken, // Use the selected list's token
					apptoken: app_token,
				},

				{
					headers: { Authorization: 'Bearer 6455ef91d108f' },
				}
			);

			setMessage('');
			setSubject('');
			console.log('Email sent to list', res);
		} catch (error) {
			console.error('Error adding contact to list', error);
		}
	};

	return (
		<Stack
			sx={{
				boxShadow: '0 0 20px rgb(0,0,0,.125)',
				borderTopLeftRadius: '25px',
				borderTopRightRadius: '25px',
				overflow: 'hidden',
			}}>
			<Stack
				sx={{
					p: '30px 20px',
					bgcolor: '#f7f8ff',
				}}>
				<Typography variant='h5' sx={{ fontWeight: 500 }}>
					Compose
				</Typography>
			</Stack>
			<Stack>
				<FormControl sx={textFieldStyle}>
					<InputLabel id='list-checkbox-label'>Lists</InputLabel>
					<Select
						labelId='list-checkbox-label'
						id='list-checkbox'
						multiple
						sx={textFieldStyle}
						value={selectedListToken}
						onChange={handleListSelect}
						renderValue={(selected) => (
							<div>
								{selected.map((value) => (
									<Chip
										key={value}
										label={lists.find((list) => list.token === value).name}
										sx={{ marginRight: '8px' }}
									/>
								))}
							</div>
						)}>
						{lists.map((list) => (
							<MenuItem
								key={list.token}
								value={list.token}
								sx={{
									background: '#f1f1f1',
									color: '#1f1f1f',
									fontSize: '25px',
									'&:hover': {
										// border: '1px solid #00FF00',
										color: 'gray',
										backgroundColor: 'white',
									},
								}}>
								<Checkbox checked={selectedListToken.includes(list.token)} />
								<ListItemText primary={list.name} />
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<TextField
					placeholder='Subject'
					name='subject'
					id='subject'
					value={subject}
					onChange={handleChange}
					sx={textFieldStyle}
				/>
				<TextEditor message={message} setMessage={setMessage} />
				<Stack
					sx={{
						p: !md ? '20px 50px' : '20px 10px',
					}}>
					<Button onClick={handleSubmit}>Send</Button>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default ComposeCard;
