import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import { MdDelete } from 'react-icons/md';
import { DataGrid } from '@mui/x-data-grid';
import AdminWrapper from '../../adminComponents/adminWrapper/AdminWrapper';
import DashNav from '../../../usersDashboard/userComponents/dashNav/DashNav';
import Modal from 'react-bootstrap/Modal';
import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import './category.css';

function Category() {
	const [data, setData] = useState([]);
	const [show, setShow] = useState(false);
	const [catname, setCatname] = useState('');
	const [catid, setCatid] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loader, setLoader] = useState(false);
	const [search, setSearch] = useState('');

	const handleClose = () => setShow(false);
	const handleShow = () => {
		setCatid(null);
		setShow(true);
		setCatname('');
	};
	// const userData = JSON.parse(localStorage.getItem('userData'))
	const app_token =
		'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';

	// Function to fetch all categories
	const fetchCategories = () => {
		setLoading(true);
		axios
			.post(
				'https://test.solarcredit.io/v0.1/api/admin/fetchCategory',
				{
					apptoken: app_token,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			)
			.then((res) => {
				setLoading(false);
				if (res.data.success) {
					setData(res.data.data);
				} else {
					message.info(res.data.message);
				}
			})
			.catch((err) => {
				setLoading(false);
				message.error(err.message);
				setData(data);
			});
	};

	//Function to add category
	const addCategory = (e) => {
		e.preventDefault();
		setLoader(true);
		axios
			.post(
				'https://test.solarcredit.io/v0.1/api/admin/createCategory',
				{
					apptoken: app_token,
					catname,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			)
			.then((res) => {
				setLoader(false);
				if (res.data.success) {
					fetchCategories();
					handleClose();
				} else {
					message.info(res.data.message);
				}
			})
			.catch((err) => {
				setLoader(false);
				handleClose();
				message.error(err.message);
				setData(data);
			});
	};

	// Function to handle category update
	const handleUpdate = (e) => {
		e.preventDefault();
		setLoader(true);
		// setData(data.filter((item) => item._id !== id))
		axios
			.post(
				`https://test.solarcredit.io/v0.1/api/admin/updateCategory`,
				{
					apptoken: app_token,
					catid,
					catname,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			)
			.then((res) => {
				setLoader(false);
				if (res.data.success) {
					setCatid(null);
					fetchCategories();
					message.success('Updated Sucessfully');
				} else {
					message.error(res.data.error);
				}
			})
			.catch((err) => {
				setLoader(false);
				message.error(err.message);
				setData(data);
			});
	};

	// funcction to handle category delete
	const handleDelete = (id) => {
		setData(data.filter((item) => item._id !== id));
		axios
			.post(
				`https://test.solarcredit.io/v0.1/api/admin/deleteCategory`,
				{
					apptoken: app_token,
					catid: id,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			)
			.then((res) => {
				if (res.data.success) {
					fetchCategories();
					message.success('Deleted Sucessfully');
				} else {
					fetchCategories();
					message.info(res.data.message);
				}
			})
			.catch((err) => {
				message.error(err.message);
				setData(data);
			});
	};

	//Function to handle modal pop up wih default data
	function handleCatEdit(obj) {
		setCatname(obj.catname);
		setCatid(obj.catid);
		setShow(true);
	}

	useEffect(() => {
		fetchCategories();
	}, []);

	const columns = [
		{ field: 'catid', headerName: 'ID', width: 50 },
		{
			field: 'catname',
			headerName: 'Category Name',
			width: 200,
			renderCell: (params) => {
				return <div className='userListUser'>{params.row.catname}</div>;
			},
		},

		{
			field: 'action',
			headerName: 'Action',
			width: 150,
			renderCell: (params) => {
				return (
					<>
						<button
							onClick={() => handleCatEdit(params.row)}
							className='adminTableBtn'>
							Edit
						</button>

						<MdDelete
							className='adminTableDeleteBtn'
							onClick={() => handleDelete(params.row.catid)}
						/>
					</>
				);
			},
		},
	];

	return (
		<div className='adminCategory'>
			<AdminWrapper>
				<DashNav
					title={'Product category'}
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					placeholder='Search category name or id'
				/>
				<div
					className={`adminuser-case admincategory-case d-flex flex-column ${
						loading ? 'align-items-start' : 'align-items-end'
					}`}>
					{loading ? (
						<Spinner
							animation='border'
							role='status'
							size='lg'
							className='store-spinner'>
							<span className='visually-hidden'>Loading...</span>
						</Spinner>
					) : (
						<>
							<DataGrid
								className='categoryDataGrid'
								rows={data.filter(
									(item) =>
										item.catname.toLowerCase().includes(search.toLowerCase()) ||
										item.catid.toString().includes(search)
								)}
								columns={columns}
								disableSelectionOnClick
								pageSize={8}
								rowsPerPageOptions={[5]}
								checkboxSelection
								getRowId={(r) => r.catid}
							/>
							<button className='adminTableBtn' onClick={() => handleShow()}>
								Add New Category
							</button>
						</>
					)}
				</div>
				<Modal
					className='categoryModal'
					show={show}
					onHide={handleClose}
					centered
					keyboard={false}>
					<Modal.Header closeButton>
						<Modal.Title>
							{catid ? 'Update category' : 'Create new category'}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form onSubmit={catid ? handleUpdate : addCategory}>
							<input
								type='text'
								placeholder='Enter the category name'
								value={catname}
								onChange={(e) => setCatname(e.target.value)}
								required
							/>
							<button>
								{loader ? (
									<Spinner animation='border' size='sm' />
								) : (
									<>{catid ? 'Update category' : 'Create Category'}</>
								)}
							</button>
						</form>
					</Modal.Body>
				</Modal>
			</AdminWrapper>
		</div>
	);
}

export default Category;
