import React from 'react'
import { Link } from 'react-router-dom'
import showcaseLower from "../../images/showcaselower.jpg"
import './showcase.css'
const Showcase = () => {
    return (
        <div>
            <div className="showcase">
                <div className="showcase-inner shadow-sm">
                    <div className="showcaseLeft">
                        <h1>We Invest Energy For Brighter Future</h1>
                        <p>A new innovation where we give you a very better experience which makes you even more reliable with your solar. Why not get started with us today by signing up and getting to know more about us.</p>
                        <Link to='/register'> <button className='btn'>Get started</button></Link>
                    </div>
                    <div className="showcaseRight">

                    </div>
                </div>
            </div>

            <div className="showcaseLower">
                <div className="showcaseLowerInner">

                    <h1>Your Best Solar Power Choice</h1>
                    <p>My Solar gadgets accomodates varieties of your solar power needs, And we give you top notch expertise.Why not explore our products and services and find the best option for your home or commercial use</p>
                    <img src={showcaseLower} alt="" />

                </div>
            </div>
        </div>
    )
}

export default Showcase