import React from 'react';
import AdminWrapper from '../../adminComponents/adminWrapper/AdminWrapper';
import DashNav from '../../../usersDashboard/userComponents/dashNav/DashNav';
import ComposeCard from '../../../../components/Compose/ComposeCard';
import { Link } from 'react-router-dom';
import './newsletter.css';

function NewsLetter() {
	return (
		<div>
			<AdminWrapper>
				<DashNav title={'NewsLetter'} />
				<div className='link-div'>
					<Link to={'/admin/createlist'} className='custom-button'>
						Add List
					</Link>
					<Link to={'/admin/contactlist'} className='custom-button'>
						Add Contact
					</Link>
				</div>
				<ComposeCard />
			</AdminWrapper>
		</div>
	);
}

export default NewsLetter;
