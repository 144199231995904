import React, { useState } from 'react';
import { Link, useNavigate, Navigate } from 'react-router-dom';
import logo from '../../../images/logo.png';
import { message } from 'antd';
import axios from 'axios';
import './login.css';
import VerifyOtp from '../verifyOtp/VerifyOtp';
const Login = () => {
	const [data, setData] = useState(null);
	const [button, setButton] = useState('LOG IN');
	const [verifyStatus, setVerifyStatus] = useState(false);
	const APP_TOKEN =
		'vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh';
	const navigate = useNavigate();
	const userData = JSON.parse(localStorage.getItem('userData'));

	// Function to handle input change data
	const handleChange = (e) => {
		const value = e.target.value;
		setData({ ...data, [e.target.name]: value });
	};

	// function to login the user
	const loginFuncion = async (e) => {
		e.preventDefault();
		setButton('Loading');
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/login`,
				{ ...data, apptoken: APP_TOKEN },
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
			setButton('LOG IN');
			if (res.data.success) {
				getLocation();
				localStorage.setItem('userData', JSON.stringify(res.data.data));
				navigate('/user/dashboard');
			} else {
				if (res.data.data?.status === 0) {
					setVerifyStatus(true);
				} else {
					message.warning(res.data.message);
				}
			}
		} catch (error) {
			message.error(error.message);
			setButton('LOG IN');
		}
	};

	function getLocation() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(showPosition);
		} else {
			message.info('Geolocation not supported by this browser.');
		}
	}

	function showPosition(position) {
		logActivity(position.coords.latitude, position.coords.longitude);
	}

	const logActivity = async (lat, lon) => {
		try {
			await axios.post(
				`https://test.solarcredit.io/v0.1/api/logUserActivity`,
				{
					usertoken: userData.usertoken,
					logs: 'Logged In',
					longtitude: lon,
					latitude: lat,
				},
				{
					headers: { Authorization: `Bearer 6455ef91d108f` },
				}
			);
		} catch (error) {
			console.log(error.message);
		}
	};

	// condition to check if user is already logged in
	if (userData) {
		return <Navigate to='/user/dashboard' />;
	}

	if (verifyStatus) {
		return (
			<VerifyOtp
				mail={data && data.mail}
				setVerifyStatus={setVerifyStatus}
				login={true}
			/>
		);
	}

	return (
		<>
			<div className='login'>
				<div className='login-inner'>
					<Link to='/'>
						<img src={logo} alt='' />
					</Link>
					<div className='login-form'>
						<h4>Login to your account</h4>
						<form onSubmit={loginFuncion}>
							<div className='login-form-group'>
								<label htmlFor='email'>Email or Phone Number</label>
								<input
									type='text'
									onChange={handleChange}
									name='mail'
									placeholder='Email or phone number'
								/>
							</div>

							<div className='login-form-group'>
								<label htmlFor='password'>Password</label>
								<input
									type='password'
									onChange={handleChange}
									name='pword'
									placeholder='Password'
								/>
							</div>
							<div className='login-form-group'>
								<span className='w-100 d-flex align-items-center justify-content-end'>
									{' '}
									<Link to='/forgotpassword' style={{ color: 'black' }}>
										Forogt Password?
									</Link>
								</span>
							</div>

							<button type='submit'>{button}</button>
						</form>
					</div>

					<span className='m-3'>
						Don't have an account? <Link to='/register'>Register</Link>
					</span>
				</div>
			</div>
		</>
	);
};

export default Login;
