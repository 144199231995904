import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OTPInput from "otp-input-react";
import { message } from "antd";
import logo from "../../../images/logo.png";
import axios from "axios";
function VerifyOtp({ mail, setVerifyStatus, login }) {
  const [OTP, setOTP] = useState("");
  const [button, setButton] = useState(false);
  const APP_TOKEN =
    "vjh35vj3hv5jhv56jh5v6jhv56jh3v6j3hv6jhvj3hvuu3yg5uygu3y5guyg5uyuhb5uh";
  const navigate = useNavigate();

  // Function to send OTP
  const sendOtp = async (e) => {
    e.preventDefault();
    setButton(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/verifyOtp`,
        { usertoken: OTP, mail, apptoken: APP_TOKEN },
        {
          headers: { Authorization: `Bearer 6455ef91d108f` },
        }
      );
      setButton(false);
      if (res.data.success) {
        message.success(res.data.message);
        localStorage.setItem("userData", JSON.stringify(res.data.data));
        navigate("/user/dashboard");
      } else {
        message.warning(res.data.message);
      }
    } catch (error) {
      message.error(error.message);
      setButton(false);
    }
  };
  return (
    <div>
      <>
        <div className="login">
          <div className="login-inner">
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
            <div className="login-form">
              <h4>Verify your OTP!</h4>
              <p className="text-white text-center m-0">
                check your email for an otp sent to you after registration
              </p>
              <form onSubmit={!button && sendOtp}>
                <div className="login-form-group align-items-center justify-content-center">
                  <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    autoFocus
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                  />
                </div>

                <button type="submit">
                  {button ? "Loading" : "Verify OTP"}
                </button>
                <div className="login-form-group">
                  <span
                    onClick={() => setVerifyStatus(false)}
                    className="w-100 text-white d-flex align-items-center justify-content-end"
                  >
                    {login ? "Back to Login" : "Back to register"}
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default VerifyOtp;
